import React, { useState, useEffect, useRef } from "react";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import CountUp from "react-countup";


import FeatherIcon from "feather-icons-react";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// import "react-table/react-table.css";
import {
    Badge,
    CardBody,
    Col,
    Modal,
    Row,
    Card,
    Input,
    Button,
    Form, Label, CardHeader,
    Spinner,
    CardTitle,
    CardSubtitle,
} from "reactstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import Select from "react-select"
import { AvForm, AvField } from "availity-reactstrap-validation";

import MetaTags from "react-meta-tags";
import swal from 'sweetalert';
import axios from "axios"

//import images
import Breadcrumbs from "../../../components/Common/Breadcrumb";


import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
const CheckListTemplate = (props) => {

    // const location = useLocation();
    // const history = useHistory()
    // console.log("location", history.location);
    const [checklistTemplatepreloader, setchecklistTemplatepreloader] = useState(true)
    const [productData, setProductData] = useState([]);
    const [modal_standard4, setmodal_standard4] = useState(false); // View More button Modal of ExposedCredentials


    const [modal_add_auditee, setmodal_add_auditee] = useState(false); // View More button Modal of ExposedCredentials

    const [orgNum, setOrgNum] = useState(0);
    const [activeOrg, setActiveOrg] = useState(0);
    const [InActiveOrg, setInActiveOrg] = useState(0);


    // values stored of form
    const [OrgName, setOrgName] = useState("");
    const [status, setstatus] = useState("");

    // validation state error 
    const [emailError, setemailError] = useState("");
    const [OrgNameError, setOrgNameError] = useState("");
    const [OrganizaionID, setOrganizaionID] = useState("");
    const [UserCount, setUserCount] = useState("");
    const [formOrganizationID, setformOrganizationID] = useState(""); // used to send userID of row to backend
    const [detailBtn, setdetailBtn] = useState(true); // to disable the button on inital


    // function to get uniques ID
    function uniqueIdGenerator() {
        var seq = (Math.floor(Math.random() * 100) + 100).toString().substring(1);
        const id = Date.now() + seq;
        return id;
    }
    const userCheck = localStorage.getItem("AuditorID");
    const checkListID = props.match.params.organizationId // to store userId from path 

    const [reportID, setReportID] = useState(localStorage.getItem("reportId"));
    const [findvulnerability, setfindvulnerability] = useState();
    const [rating, setrating] = useState()
    const [vulnerabilityDescription, setvulnerabilityDescription] = useState();
    const [vulnerabilityImpact, setvulnerabilityImpact] = useState();
    const [vulnerabilityRemediation, setvulnerabilityRemediation] = useState();
    const [vulnerabilityAffectedURL, setvulnerabilityAffectedURL] = useState();
    // const [vulnerabilityAffectedURL , setvulnerabilityAffectedURL] =  useState();
    const [vulnerabilityImg1, setvulnerabilityImg1] = useState();
    const [vulnerabilityList, setVulnerabilityList] = useState();

    const [CVSS_vector, setCVSS_vector] = useState("")



    const [Title, setTitle] = useState();
    const [Severity, setSeverity] = useState("");
    // const [empSize, setempSize] = useState();
    const [CheckListTag, setCheckListTag] = useState();

    const [description, setdescription] = useState();
    const [impact, setimpact] = useState();
    const [remediation, setremediation] = useState();
    const [reference, setreference] = useState();
    const [checkListTemplateStatus, setcheckListTemplateStatus] = useState();
    const [pincode, setpincode] = useState();
    const [otherDetails, setotherDetails] = useState();
    const [CheckListTemplateID, setCheckListTemplateID] = useState();

    const [status_empSize, setstatus_empSize] = useState("");
    const [view_more_modal, setview_more_modal] = useState(false);
    const [statusCountry, setstatusCountry] = useState("");
    const [companyStatus, setcompanyStatus] = useState();
    const [checklistType, setchecklistType] = useState();

    const [status_vulnerability_status, setstatus_vulnerability_status] = useState("");
    const [selectedOptions_vulnerability_status, setselectedOptions_vulnerability_status] = useState(null);


    const optionGroup_severity = [
        { label: "Critical", value: "Critical" },
        { label: "High", value: "High" },
        { label: "Medium", value: "Medium" },
        { label: "Low", value: "Low" },
        { label: "Informational", value: "Informational" }
    ];

    const optionGroup_country = [
        { label: "India", value: "India" },
        { label: "USA", value: "USA" },
        { label: "Medium", value: "Medium" },
        { label: "Low", value: "Low" },
        { label: "Informational", value: "Informational" }
    ];

    const optionGroup_checklIstType = [
        { label: "Technical Audit ", value: "Technical Audit " },
        { label: "Casual", value: "Inactive" },
    ];
    const optionGroup_status = [
        { label: "Enabled", value: "Enabled" },
        { label: "Disabled", value: "Disabled" },
    ];
    const optionGroup_CompanyStatus = [
        { label: "Medium", value: "Medium" },
        { label: "Low", value: "Low" },
        { label: "Informational", value: "Informational" }
    ];

    // form validatioon checkuo end ------------->>>>>>>>>>>>>>>>>>>>>>>>>>

    // function to handle change in status dropdown
    //  function handleSelectOptions_empSize(selectedOptions_empSize) {
    //     setselectedOptions_empSize(selectedOptions_empSize);
    // }

    // function to handle change in status dropdown
    function handleSelectOptions_severity(selectedOptions_severity) {
        // setselectedOptions_severity(selectedOptions_severity);
    }
    // function to handle change in status dropdown
    function handleSelectOptions_vulnerability_status(selectedOptions_vulnerability_status) {
        setselectedOptions_vulnerability_status(selectedOptions_vulnerability_status);
    }

    // const selectInputRef = useRef(null);
    // const resetFun = () => {
    //     selectInputRef.select.clearValue();
    // }

    let selectRef_severity = useRef(null); // ref for severity 
    let selectRef_status = useRef(null); // ref for severity 

    async function clearValue() {
        // console.log("selectRef_severity", selectRef_severity)
        // console.log("selectRef_severity", selectRef_severity.current.state.value.value)
        // let value = selectRef_severity.current.state.value.value
        // console.log("value ", value)
        // setSeverity(value)   
        // selectRef_severity.select.clearValue();
        selectRef_severity.current.select.clearValue()
        selectRef_status.current.select.clearValue()
    };


    async function addVulnerabilities() {
        tog_standard_add_auditee()
        var formData = new FormData();
        formData.append("CVSS_vector", CVSS_vector);
        formData.append("Title", Title);
        // formData.append("status_severity", status_severity);
        formData.append("Severity", selectRef_severity.current.state.value.value); // severity using ref 
        formData.append("CheckListTag", CheckListTag.toUpperCase());
        formData.append("description", description);
        formData.append("impact", impact);
        formData.append("remediation", remediation);
        formData.append("reference", reference);
        formData.append("checkListID", checkListID);
        formData.append("checkListTemplateStatus", selectRef_status.current.state.value.value);
        formData.append("checklistType", checklistType);
        // formData.append("description", description);
        // formData.append("description", description);

        formData.append("auditorID", userCheck)
        formData.append("userCheck", localStorage.getItem("AuditorID"))
        formData.append("orgID", localStorage.getItem("orgID"))
        // formData.append("status_vulnerability_status", status_vulnerability_status);
        // formData.append("vulnerabilityImg1", vulnerabilityImg1[0], "vulnerability_status_img.png");
        // console.log(checkListName,checkListType , checkListYear , checkLIstStatus)

        try {
            // const response = await fetch('http://localhost:1338/add-auditee', {
            //     method : "POST",
            //     headers : {
            //         "content-Type" : "application/json"
            //     },
            //     body:formData
            // })
            await axios.post(`${process.env.REACT_APP_DEFAULTPATH}add-auditor-checklist-template`, formData, {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                }
            }).
                then(() => {
                    // tog_standard_add_auditee()
                    // setTitle(" ")
                    // setCheckListTag(" ")
                    // setdescription(" ")
                    // setimpact(" ")
                    // setremediation(" ")
                    // setreference(" ")
                    console.log("me this s")
                    console.log("modal_add_auditee", modal_add_auditee)
                    // setSeverity("null")
                    console.log("setSeverity", Severity)
                    // tog_standard_add_auditee();tog_standard_add_auditee()
                    // clearValue()
                    setmodal_add_auditee(true)
                    getCustomers()
                    setTitle("")
                    setCheckListTag(" ")
                    setdescription(" ")
                    setimpact(" ")
                    setremediation(" ")
                    setreference(" ")
                    // tog_standard_add_auditee()
                    // tog_standard_add_auditee()
                    // window.location.reload();
                });

        } catch (error) {
            console.log(error)
        }
    }







    const [selectedOptions, setselectedOptions] = useState(null)
    // const animatedComponents = makeAnimated()
    // const orgID = localStorage.getItem("orgID");
    const token = localStorage.getItem("token");

    const optionGroup = [
        { label: "Active", value: "Active" },
        { label: "InActive", value: "InActive" },
    ]
    // Columns of DataTable
    const columns = [
        {
            dataField: "ChecklistID",
            text: "ChecklistID ",
            sort: true,
            formatter: (cellContent, productData) => (
                <>
                    {(() => {
                        if (productData.ChecklistID === null) {
                            return <>-</>;
                        } else {
                            return <>
                                <Badge className="me-2 bg-primary p-1 fs-6">
                                    {productData.ChecklistID}
                                </Badge>
                            </>;
                        }
                    })()}
                </>
            ),
        },
        {
            dataField: "Title",
            text: "Title ",
            sort: true,
            formatter: (cellContent, productData) => (
                <>
                    {(() => {
                        if (productData.Title === null) {
                            return <>-</>;
                        } else {
                            return <>{productData.Title}</>;
                        }
                    })()}
                </>
            ),
        },
        {
            dataField: "Tags",
            text: "Tags ",
            sort: true,
            formatter: (cellContent, productData) => (
                <>
                    {(() => {
                        if (productData.Tags === null) {
                            return <>-</>;
                        } else {
                            return <>  <Badge className="me-2 bg-primary p-1">
                                {productData.Tags}
                            </Badge></>;
                        }
                    })()}
                </>
            ),
        },

        {
            dataField: "Severity",
            text: "Severity ",
            sort: true,
            formatter: (cellContent, productData) => (
                <>
                    {(() => {
                        if (productData.Severity === null) {
                            return <>-</>;
                        } else if (productData.Severity === "Critical") {
                            return <> <Badge pill className="me-2  ms-1  fs-6" style={{ backgroundColor: "#ff0000" }}>{productData.Severity}</Badge></>;
                        } else if (productData.Severity === "High") {
                            return <> <Badge pill className="me-2  ms-1  fs-6" style={{ backgroundColor: "#dc3545" }}>{productData.Severity}</Badge></>;
                        } else if (productData.Severity === "Low") {
                            return <> <Badge pill className="me-2  ms-1  fs-6" style={{ backgroundColor: "#198754" }}>{productData.Severity}</Badge></>;
                        } else if (productData.Severity === "Medium") {
                            return <> <Badge pill className="me-2  ms-1 fs-6 " style={{ backgroundColor: "#ffc107" }}>{productData.Severity}</Badge></>;
                        } else if (productData.Severity === "Informational") {
                            return <> <Badge pill className="me-2  ms-1 fs-6 " style={{ backgroundColor: "#0dcaf0" }}>{productData.Severity}</Badge> </>;
                        }
                    })()}
                </>
            ),
        },
        {
            dataField: "Status",
            text: "Status ",
            sort: true,
            formatter: (cellContent, productData) => (
                <>
                    {(() => {
                        if (productData.Status === null) {
                            return <>-</>;
                        } else if (productData.Status === "Enabled") {
                            return <> <Badge pill className="badge-soft-success ms-1  fs-6">{productData.Status}</Badge></>;
                        } else if (productData.Status === "Disabled") {
                            return <> <Badge pill className="badge-soft-danger ms-1  fs-6">{productData.Status}</Badge>

                            </>;
                        } else if (productData.Status === "Pending") {
                            return <> <Badge pill className="badge-soft-warning ms-1  fs-6">{productData.Status}</Badge>

                            </>;
                        }
                    })()}
                </>
            ),
        },
        // {
        //     dataField: "Value",
        //     text: "Users Count",
        //     sort: true,
        //     formatter: (cellContent, productData) => (
        //         <>
        //             {(() => {
        //                 if (productData.UserCount === null) {
        //                     return <>-</>;
        //                 } else {
        //                     return <>
        //                         <td>  <Badge pill className="me-2 bg-danger ">{productData.UserCount.length}</Badge></td></>;
        //                 }
        //             })()}
        //         </>
        //     ),
        // },
        // {
        //     dataField: "Status",
        //     text: "Status",
        //     sort: true,
        //     formatter: (cellContent, productData) => (
        //         <>
        //             {(() => {
        //                 if (productData.Status === null) {
        //                     return <>-</>;
        //                 } else if (productData.Status === "Active") {
        //                     return <> <Badge pill className="badge-soft-success ms-1  ">{productData.Status}</Badge></>;
        //                 } else if (productData.Status === "InActive") {
        //                     return <> <Badge pill className="badge-soft-danger ms-1  ">{productData.Status}</Badge></>;
        //                 }
        //             })()}
        //         </>
        //     ),
        // },
        {
            dataField: "Action",
            text: "Action",
            sort: true,
            formatter: (cellContent, productData) => (

                <>
                    {
                        (() => {
                            if (role === "Admin") {
                                return (
                                    <>
                                        <Button
                                            color="warning"
                                            className="btn-sm me-2"
                                            // disabled={role === "Admin" ? false : true}
                                            onClick={() => {
                                                console.log("cliecekd setCVSS_vector", [productData]);

                                                tog_standard4();
                                                setCheckListTemplateID(productData.ChecklistID)
                                                setTitle(productData.Title);
                                                setSeverity(productData.Severity);
                                                setCheckListTag(productData.Tags);
                                                setcheckListTemplateStatus(productData.Status);
                                                setdescription(productData.Description);
                                                setimpact(productData.Impact);
                                                setremediation(productData.Remediation);
                                                setreference(productData.Reference);
                                                setCVSS_vector(productData.CVSS_Vector);
                                                // setmodalPopupData(productData.UserCount)
                                                // setcheckListDbId(productData.ChecklistDatabaseID)
                                                // setcheckLIstStatus(productData.Status)
                                                // setcheckList_Type(productData.ChecklistDatabaseType)
                                                // setcheckListYear(productData.ChecklistDatabaseYear)
                                                // setcheckListName(productData.ChecklistDatabaseName)
                                                // setcheckListVersion(productData.ChecklistDatabaseVersion)
                                                // setOrganizaionID(productData.OrganizationID)
                                                // setUserCount(productData.UserCount.length)
                                                // setOrgName(productData.OrganizationName)
                                            }}
                                        >
                                            Edit
                                        </Button>
                                        <Button
                                            color="danger"
                                            className="btn-sm me-2"
                                            // disabled={role === "Admin" ? false : true}
                                            onClick={() => {
                                                setCheckListTemplateID(productData.ChecklistID)
                                                updatePersonalDetails_sweetAlert(productData.ChecklistID)
                                                // setcheckListDbId(productData.ChecklistDatabaseID)
                                                // updatePersonalDetails_sweetAlert(productData.ChecklistDatabaseID)
                                                // console.log("cliecekd", [productData]);
                                                // tog_standard4();
                                                // // setmodalPopupData(productData.UserCount)
                                                // setOrganizaionID(productData.OrganizationID)
                                                // setUserCount(productData.UserCount.length)
                                                // setOrgName(productData.OrganizationName)
                                            }}
                                        >
                                            Delete
                                        </Button>
                                    </>
                                )
                            }
                        })()
                    }
                    {/* {console.log("without cliecekd", productData.Domains)} */}
                    <Button
                        color="primary"
                        className="btn-sm"
                        // disabled={role === "Admin" ? false : true}
                        onClick={() => {
                            // console.log("cliecekd", [productData]);
                            toggle_view_more_modal();
                            setSeverity(productData.Severity)
                            setCheckListTag(productData.Tags)
                            setcheckListTemplateStatus(productData.Status)
                            setdescription(productData.Description)
                            setremediation(productData.Remediation)
                            setimpact(productData.Impact)
                            setreference(productData.Reference)
                            setCVSS_vector(productData.CVSS_Vector)
                            setTitle(productData.Title)
                            // setmodalPopupData(productData.UserCount)
                            // setOrganizaionID(productData.OrganizationID)
                            // setUserCount(productData.UserCount.length)
                            // setOrgName(productData.OrganizationName)
                        }}
                    >

                        View More

                    </Button>
                </>
            ),
        },
    ];
    // Sorting of Data Table
    const defaultSorted = [
        {
            dataField: "id",
            order: "asc",
        },
    ];

    // Page Options in Data Table 
    const pageOptions = {
        sizePerPage: 10,
        totalSize: productData.length, // replace later with size(customers),
        custom: true,
    };
    const { SearchBar } = Search; // Search Bar in Data TAble

    var mainData = [];
    var name_regx = /^[A-Za-z ]{3,20}$/;

    useEffect(async () => {

        getchecklistType();
        getUser();
        getCustomers();
    }, []);


    function tog_standard4() {
        setmodal_standard4(!modal_standard4);
        // toggle for view more button 
    }
    function tog_standard_add_auditee() {
        setmodal_add_auditee(!modal_add_auditee);
        // toggle for view more button 
    }
    function toggle_view_more_modal() {
        setview_more_modal(!view_more_modal);
        // toggle for view more button 
    }

    const [role, setRole] = useState()
    // to get userLSit 
    async function getUser(event) {
        // event.preventDefault();
        // console.log("token passes " , userCheck)
        const response = await fetch(`${process.env.REACT_APP_DEFAULTPATH}user-profile`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            },
            body: JSON.stringify({
                userCheck: localStorage.getItem("AuditorID"),
                orgID: localStorage.getItem("orgID")
            }),
        });
        const data = await response.json();
        if (data) {
            const newdata = data.user;
            console.log("data retrived", newdata);
            setRole(newdata.Role)

            // setphone(newdata.Phone);
            // setjobTitle(newdata.JobTitle);
            // console.log("main profile", newdata.FirstName);
        } else {
            alert("data retreival error");
        }
    }


    // to get userLSit 
    async function getchecklistType() {
        // event.preventDefault();
        // console.log("token passes " , userCheck)
        const response = await fetch(`${process.env.REACT_APP_DEFAULTPATH}get-checklist-type`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            },
            body: JSON.stringify({
                checkListID,
                userCheck: localStorage.getItem("AuditorID"),
                orgID: localStorage.getItem("orgID")
            }),
        });
        const data = await response.json();
        if (data) {
            const newdata = data;
            setchecklistType(newdata.data)

            // setphone(newdata.Phone);
            // setjobTitle(newdata.JobTitle);
            // console.log("main profile", newdata.FirstName);
        } else {
            alert("data retreival error");
        }
    }


    // ApI to hit table DATa
    async function getCustomers(event) {
        const response = await fetch(
            `${process.env.REACT_APP_DEFAULTPATH}get-auditor-checkList-template`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                },
                body: JSON.stringify({
                    checkListID,
                    userCheck: localStorage.getItem("AuditorID"),
                    orgID: localStorage.getItem("orgID")
                })
            }
        );
        const data = await response.json();
        if (data) {
            console.log("response-", data.data);
            // mainData = await data.data[0].data.entries;
            mainData = await data;
            setProductData(data.data);
            setchecklistTemplatepreloader(false)
            setOrgNum(mainData.data.length)
            // console.log(mainData)
            var ACtiveCounter = 0;
            var InactiveCounter = 0
            for (var i = 0; i < mainData.data.length; i++) {
                if (mainData.data[i].Status === "Active") {
                    ACtiveCounter += 1;
                }
                else {
                    InactiveCounter += 1;
                }
            }
            setActiveOrg(ACtiveCounter);
            setInActiveOrg(InactiveCounter)
            // console.log("tempData", TempData)
            // setProductData(TempData);
            // setIpCardPrice(TempData[1].Value.length)
            // setdomainCard(TempData[0].Value.length)
            // setSubdomainCardPrice(TempData[2].Value.length)
        } else {
            setchecklistTemplatepreloader(false)
            // alert("data retreival error");
        }
    }

    // function to send data to backend 
    async function deleteChecklistTemplate(id) {
        console.log("id", id)
        const response = await fetch(
            `${process.env.REACT_APP_DEFAULTPATH}delete-auditor-checklist-template`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                },
                body: JSON.stringify({
                    CheckListTemplateID: id,
                    checkListID,
                    userCheck: localStorage.getItem("AuditorID"),
                    orgID: localStorage.getItem("orgID")
                }),
            }
        );
        const data = await response.json();
        if (data.status) {
        }
        else {
            // setemailError("Email already exist")
        }

    }


    // Swwet alert or modal to confirm detials  should modify for perosnal Details 
    function updatePersonalDetails_sweetAlert(id) {
        // console.log("selected GRoup", status)
        swal({
            title: "Are you sure?",
            text: "Once done, you will update your personal details!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    if (deleteChecklistTemplate(id)) {
                        swal("Poof! Your perosnal details has been updated!", {
                            icon: "success",
                            buttons: true
                        }).then((reload) => {
                            if (reload) {
                                // window.location.reload(false);
                                getCustomers()
                            }
                        })
                    }
                    else {
                        swal("Reload operation has been aborted!");
                    }
                } else {
                    swal("Operation has been aborted!");
                }
            });
    }

    async function editChecklistTemplate(id) {
        var formData = new FormData();
        formData.append("Title", Title);
        // formData.append("status_severity", status_severity);
        formData.append("Severity", Severity);
        formData.append("CVSS_vector", CVSS_vector);
        formData.append("CheckListTag", CheckListTag.toUpperCase());
        formData.append("description", description);
        formData.append("impact", impact);
        formData.append("remediation", remediation);
        formData.append("reference", reference);
        formData.append("checkListID", checkListID);
        formData.append("checkListTemplateStatus", checkListTemplateStatus);
        formData.append("CheckListTemplateID", CheckListTemplateID);
        formData.append("checklistType", checklistType);

        // formData.append("description", description);
        // formData.append("description", description);

        formData.append("auditorID", userCheck)
        formData.append("userCheck", localStorage.getItem("AuditorID"))
        formData.append("orgID", localStorage.getItem("orgID"))
        // formData.append("status_vulnerability_status", status_vulnerability_status);
        // formData.append("vulnerabilityImg1", vulnerabilityImg1[0], "vulnerability_status_img.png");
        // console.log(checkListName,checkListType , checkListYear , checkLIstStatus)

        try {
            // const response = await fetch('http://:1338/add-auditee', {
            //     method : "POST",
            //     headers : {
            //         "content-Type" : "application/json"
            //     },
            //     body:formData
            // })
            // debugger
            await axios.post(`${process.env.REACT_APP_DEFAULTPATH}edit-auditor-checklist-template`, formData, {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                }
            }).
                then((res) => {
                    getCustomers()
                    tog_standard4()
                    // window.location.reload();
                });

        } catch (error) {
            console.log(error)
        }
    }

    // functions to validate regex OrgName
    function checkOrgNameValidation(OrgName_val) {
        if (OrgName_val === " ") {
            setOrgNameError("enter first name")
            setdetailBtn(true)
        }
        else if (name_regx.test(OrgName_val)) {
            setOrgNameError("")
            setdetailBtn(false)
        }
        else if (OrgName_val.length < 3) {
            setOrgNameError(" must contain 3 characters")
            setdetailBtn(true)
        }
        else {
            setOrgNameError(`Organization doesn't contain "0-9  , & @ "`)
            setdetailBtn(true)
        }
    }

    // function to handle change in status dropdown
    function handleSelectOptions(selectedOptions) {
        setselectedOptions(selectedOptions)
        setdetailBtn(false)
    }

    return (
        <React.Fragment>

            {checklistTemplatepreloader ?
                <div className="transparent-loader">
                    <Spinner type="grow" className="ms-2" color="primary" /> </div> : <></>}
            <div className="userProfile credentialBreachProfile">
                <MetaTags>
                    <title>CheckList Template | AuditSense</title>
                </MetaTags>
                <Row>
                    <Breadcrumbs
                        title="Dashboard"
                        breadcrumbItem="CheckList Template"
                        breadcrumbFeature="Checklist"
                    />
                </Row>



                {(() => {
                    if (role === "Admin") {
                        return (
                            <div className="text-end">
                                <Button className="btn-success" onClick={() => {
                                    // console.log("asdas")
                                    setTitle(" ")
                                    setCheckListTag(" ")
                                    setdescription(" ")
                                    setimpact(" ")
                                    setremediation(" ")
                                    setreference(" ")
                                    tog_standard_add_auditee()
                                }}><i className="bx bx-data"></i> &nbsp; Add Checklist</Button>
                            </div>
                        )
                    }
                })()}
                {/* <Row> */}

                {/* Edit modal  */}
                <Modal
                    isOpen={modal_standard4}
                    toggle={() => {
                        tog_standard4();
                    }}
                    className="modal-xl"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myModalLabel">
                            Edit CheckList Template
                        </h5>
                        <button
                            type="button"
                            onClick={() => {
                                setmodal_standard4(false);
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>


                    {(() => {
                        // console.log("checklist type"  , checklistType)
                        if (checklistType === "Web Application Audit") {
                            return (
                                <div className="modal-body">

                                    <div className="d-flex flex-column h-100">

                                        <div className='webApplication'>
                                            <Form >


                                                <Row className='my-2'>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="companyLogo">Title  : </Label>
                                                            <Input type="text" className="form-control" id="companyLogo" value={Title}
                                                                onChange={(e) => {
                                                                    // console.log(e.target.value);
                                                                    setTitle(e.target.value);
                                                                }} />
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="employeeSize">Severity :  </Label>
                                                            <Select
                                                                // value={empSize}
                                                                // value={Severity}
                                                                placeholder={Severity}
                                                                onChange={(e) => {
                                                                    // console.log(e.value)
                                                                    setSeverity(e.value);
                                                                    // handleSelectOptions_empSize(e.value);
                                                                    // console.log(selectedOptions_empSize)
                                                                }}
                                                                options={optionGroup_severity}
                                                                classNamePrefix="select2-selection"
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className='my-2'>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="companyEmail">CVSS Vector: </Label>
                                                            <Input type="text" className="form-control" id="companyEmail" value={CVSS_vector}
                                                                onChange={(e) => {
                                                                    // console.log(e.target.value);
                                                                    setCVSS_vector(e.target.value);
                                                                }} />
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="companyEmail">Tags : </Label>
                                                            <Input type="text" className="form-control" id="companyEmail" value={CheckListTag}
                                                                onChange={(e) => {
                                                                    // console.log(e.target.value);
                                                                    setCheckListTag(e.target.value);
                                                                }} />
                                                        </div>
                                                    </Col>    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="companyEmail">Status : </Label> <Select
                                                                // value={empSize}
                                                                placeholder={checkListTemplateStatus}
                                                                onChange={(e) => {
                                                                    // console.log(e.value)
                                                                    setcheckListTemplateStatus(e.value);
                                                                    // handleSelectOptions_empSize(e.value);
                                                                    // console.log(selectedOptions_empSize)
                                                                }}
                                                                options={optionGroup_status}
                                                                classNamePrefix="select2-selection"
                                                            />

                                                        </div>
                                                    </Col>



                                                </Row>








                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Description :</Label>


                                                    <div className="mb-3" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={description}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setdescription(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>

                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Impact :</Label>


                                                    <div className="mb-3" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={impact}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setimpact(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>

                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Remediation :</Label>


                                                    <div className="mb-3" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={remediation}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setremediation(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>

                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Reference :</Label>


                                                    <div className="mb-3" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={reference}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setreference(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>

                                            </Form>
                                        </div>
                                    </div>

                                </div>
                            )
                        }
                        if (checklistType === "Mobile Application Audit") {
                            return (
                                <div className="modal-body">

                                    <div className="d-flex flex-column h-100">

                                        <div className='webApplication'>
                                            <Form >


                                                <Row className='my-2'>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="companyLogo">Title  : </Label>
                                                            <Input type="text" className="form-control" id="companyLogo" value={Title}
                                                                onChange={(e) => {
                                                                    // console.log(e.target.value);
                                                                    setTitle(e.target.value);
                                                                }} />
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="employeeSize">Severity :  </Label>
                                                            <Select
                                                                // value={empSize}
                                                                // value={Severity}
                                                                placeholder={Severity}
                                                                onChange={(e) => {
                                                                    // console.log(e.value)
                                                                    setSeverity(e.value);
                                                                    // handleSelectOptions_empSize(e.value);
                                                                    // console.log(selectedOptions_empSize)
                                                                }}
                                                                options={optionGroup_severity}
                                                                classNamePrefix="select2-selection"
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className='my-2'>

                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="companyEmail">CVSS Vector: </Label>
                                                            <Input type="text" className="form-control" id="companyEmail" value={CVSS_vector}
                                                                onChange={(e) => {
                                                                    // console.log(e.target.value);
                                                                    setCVSS_vector(e.target.value);
                                                                }} />
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="companyEmail">Tags : </Label>
                                                            <Input type="text" className="form-control" id="companyEmail" value={CheckListTag}
                                                                onChange={(e) => {
                                                                    // console.log(e.target.value);
                                                                    setCheckListTag(e.target.value);
                                                                }} />
                                                        </div>
                                                    </Col>    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="companyEmail">Status : </Label> <Select
                                                                // value={empSize}
                                                                placeholder={checkListTemplateStatus}
                                                                onChange={(e) => {
                                                                    // console.log(e.value)
                                                                    setcheckListTemplateStatus(e.value);
                                                                    // handleSelectOptions_empSize(e.value);
                                                                    // console.log(selectedOptions_empSize)
                                                                }}
                                                                options={optionGroup_status}
                                                                classNamePrefix="select2-selection"
                                                            />

                                                        </div>
                                                    </Col>



                                                </Row>
                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Description :</Label>


                                                    <div className="mb-3" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={description}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setdescription(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>

                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Impact :</Label>


                                                    <div className="mb-3" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={impact}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setimpact(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>

                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Remediation :</Label>


                                                    <div className="mb-3" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={remediation}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setremediation(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>

                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Reference :</Label>


                                                    <div className="mb-3" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={reference}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setreference(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>

                                            </Form>
                                        </div>
                                    </div>

                                </div>
                            )
                        }
                        if (checklistType === "API Audit") {
                            return (
                                <div className="modal-body">

                                    <div className="d-flex flex-column h-100">

                                        <div className='webApplication'>
                                            <Form >


                                                <Row className='my-2'>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="companyLogo">Title  : </Label>
                                                            <Input type="text" className="form-control" id="companyLogo" value={Title}
                                                                onChange={(e) => {
                                                                    // console.log(e.target.value);
                                                                    setTitle(e.target.value);
                                                                }} />
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="employeeSize">Severity :  </Label>
                                                            <Select
                                                                // value={empSize}
                                                                // value={Severity}
                                                                placeholder={Severity}
                                                                onChange={(e) => {
                                                                    // console.log(e.value)
                                                                    setSeverity(e.value);
                                                                    // handleSelectOptions_empSize(e.value);
                                                                    // console.log(selectedOptions_empSize)
                                                                }}
                                                                options={optionGroup_severity}
                                                                classNamePrefix="select2-selection"
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className='my-2'>

                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="companyEmail">CVSS Vector: </Label>
                                                            <Input type="text" className="form-control" id="companyEmail" value={CVSS_vector}
                                                                onChange={(e) => {
                                                                    // console.log(e.target.value);
                                                                    setCVSS_vector(e.target.value);
                                                                }} />
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="companyEmail">Tags : </Label>
                                                            <Input type="text" className="form-control" id="companyEmail" value={CheckListTag}
                                                                onChange={(e) => {
                                                                    // console.log(e.target.value);
                                                                    setCheckListTag(e.target.value);
                                                                }} />
                                                        </div>
                                                    </Col>    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="companyEmail">Status : </Label> <Select
                                                                // value={empSize}
                                                                placeholder={checkListTemplateStatus}
                                                                onChange={(e) => {
                                                                    // console.log(e.value)
                                                                    setcheckListTemplateStatus(e.value);
                                                                    // handleSelectOptions_empSize(e.value);
                                                                    // console.log(selectedOptions_empSize)
                                                                }}
                                                                options={optionGroup_status}
                                                                classNamePrefix="select2-selection"
                                                            />

                                                        </div>
                                                    </Col>



                                                </Row>








                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Description :</Label>


                                                    <div className="mb-3" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={description}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setdescription(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>

                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Impact :</Label>


                                                    <div className="mb-3" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={impact}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setimpact(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>

                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Remediation :</Label>


                                                    <div className="mb-3" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={remediation}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setremediation(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>

                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Reference :</Label>


                                                    <div className="mb-3" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={reference}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setreference(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>

                                            </Form>
                                        </div>
                                    </div>

                                </div>
                            )
                        }
                        if (checklistType === "Network Audit") {
                            return (
                                <div className="modal-body">

                                    <div className="d-flex flex-column h-100">

                                        <div className='webApplication'>
                                            <Form >


                                                <Row className='my-2'>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="companyLogo">Title  : </Label>
                                                            <Input type="text" className="form-control" id="companyLogo" value={Title}
                                                                onChange={(e) => {
                                                                    // console.log(e.target.value);
                                                                    setTitle(e.target.value);
                                                                }} />
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="employeeSize">Severity :  </Label>
                                                            <Select
                                                                // value={empSize}
                                                                // value={Severity}
                                                                placeholder={Severity}
                                                                onChange={(e) => {
                                                                    // console.log(e.value)
                                                                    setSeverity(e.value);
                                                                    // handleSelectOptions_empSize(e.value);
                                                                    // console.log(selectedOptions_empSize)
                                                                }}
                                                                options={optionGroup_severity}
                                                                classNamePrefix="select2-selection"
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className='my-2'>

                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="companyEmail">CVSS Vector: </Label>
                                                            <Input type="text" className="form-control" id="companyEmail" value={CVSS_vector}
                                                                onChange={(e) => {
                                                                    // console.log(e.target.value);
                                                                    setCVSS_vector(e.target.value);
                                                                }} />
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="companyEmail">Tags : </Label>
                                                            <Input type="text" className="form-control" id="companyEmail" value={CheckListTag}
                                                                onChange={(e) => {
                                                                    // console.log(e.target.value);
                                                                    setCheckListTag(e.target.value);
                                                                }} />
                                                        </div>
                                                    </Col>    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="companyEmail">Status : </Label> <Select
                                                                // value={empSize}
                                                                placeholder={checkListTemplateStatus}
                                                                onChange={(e) => {
                                                                    // console.log(e.value)
                                                                    setcheckListTemplateStatus(e.value);
                                                                    // handleSelectOptions_empSize(e.value);
                                                                    // console.log(selectedOptions_empSize)
                                                                }}
                                                                options={optionGroup_status}
                                                                classNamePrefix="select2-selection"
                                                            />

                                                        </div>
                                                    </Col>



                                                </Row>








                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Description :</Label>


                                                    <div className="mb-3" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={description}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setdescription(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>


                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Remediation :</Label>


                                                    <div className="mb-3" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={remediation}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setremediation(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>

                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Reference :</Label>


                                                    <div className="mb-3" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={reference}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setreference(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>

                                            </Form>
                                        </div>
                                    </div>

                                </div>
                            )
                        }
                    })()}

                    <div className="modal-footer">
                        <button
                            type="button"
                            onClick={() => {
                                tog_standard4();
                                setmodal_standard4(false);
                            }}
                            className="btn btn-secondary "
                            data-dismiss="modal"
                        >
                            Close
                        </button>
                        <button
                            type="button"
                            onClick={() => {
                                // updatePersonalDetails_sweetAlert()
                                editChecklistTemplate()
                            }}
                            // disabled={detailBtn}
                            className="btn btn-primary "
                            data-dismiss="modal"
                        >
                            Save
                        </button>
                    </div>
                </Modal>

                {/* TAble  */}
                <Row className="content">
                    <Col
                        lg={4}
                        md={5}
                        className="col-xxl-9 userData credentialData"
                    >
                        <div className="auth-full-page-content d-flex p-sm-5 p-4">
                            <div className="w-100">
                                <div className="d-flex flex-column">
                                    <div className="  text-center">
                                        <Link
                                            to="/dashboard"
                                            className="d-block auth-logo"
                                        >
                                        </Link>
                                    </div>
                                    <div className="auth-content my-auto">

                                        <div className="mainTable">
                                            <CardBody>
                                                <PaginationProvider
                                                    pagination={paginationFactory(
                                                        pageOptions
                                                    )}
                                                >
                                                    {({
                                                        paginationProps,
                                                        paginationTableProps,
                                                    }) => (
                                                        <ToolkitProvider
                                                            keyField="id"
                                                            columns={columns}
                                                            data={productData}
                                                            search
                                                        >
                                                            {(toolkitProps) => (
                                                                <React.Fragment>
                                                                    <Row className="mb-2">
                                                                        <Col>
                                                                            <div className="search-box me-2 mb-2 d-flex justify-content-between">
                                                                                <div className="d-inline">
                                                                                    <SizePerPageDropdownStandalone
                                                                                        {...paginationProps}
                                                                                    />
                                                                                </div>
                                                                                <div className="position-relative">
                                                                                    <SearchBar
                                                                                        {...toolkitProps.searchProps}
                                                                                    />
                                                                                    <i className="bx bx-search-alt search-icon" />
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>

                                                                    <Row>
                                                                        <Col xl="12">
                                                                            <div className="table-responsive">
                                                                                <BootstrapTable
                                                                                    keyField={"id"}
                                                                                    responsive
                                                                                    bordered={false}
                                                                                    striped={false}
                                                                                    defaultSorted={
                                                                                        defaultSorted
                                                                                    }
                                                                                    classes={
                                                                                        "table align-middle table-nowrap"
                                                                                    }
                                                                                    headerWrapperClasses={
                                                                                        "thead-light"
                                                                                    }
                                                                                    {...toolkitProps.baseProps}
                                                                                    {...paginationTableProps}
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>

                                                                    <Row className="align-items-md-center mt-30">
                                                                        <Col className="pagination pagination-rounded justify-content-end mb-2">
                                                                            <PaginationListStandalone
                                                                                {...paginationProps}
                                                                            />
                                                                        </Col>
                                                                    </Row>

                                                                </React.Fragment>
                                                            )}
                                                        </ToolkitProvider>
                                                    )}
                                                </PaginationProvider>
                                            </CardBody>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                {/* </Row> */}


                {/* MODal to view  */}
                <Modal
                    isOpen={view_more_modal}
                    toggle={() => {
                        toggle_view_more_modal();
                    }}
                    className="modal-xl"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myModalLabel">
                            Checklist template Details
                        </h5>
                        <button
                            type="button"
                            onClick={() => {
                                setview_more_modal(false);
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    {
                        (() => {
                            if (checklistType === "Web Application Audit") {
                                return (
                                    <div className="modal-body">

                                        <div className="d-flex flex-column h-100">

                                            <div className='webApplication'>
                                                <Form >

                                                    <Row className='my-2'>
                                                        <Col md={6}>
                                                            <div className="mb-3">
                                                                <Label className="form-label" htmlFor="employeeSize">Title *  </Label>
                                                                <div>{Title}</div>
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>
                                                            <div className="mb-3">
                                                                <Label className="form-label" htmlFor="companyLogo">Severity * </Label>
                                                                <div>
                                                                    {(() => {
                                                                        if (Severity === null) {
                                                                            return <>-</>;
                                                                        } else if (Severity === "Critical") {
                                                                            return <> <Badge pill className="me-2  ms-1  fs-6" style={{ backgroundColor: "#ff0000" }}>{Severity}</Badge></>;
                                                                        } else if (Severity === "High") {
                                                                            return <> <Badge pill className="me-2  ms-1  fs-6" style={{ backgroundColor: "#dc3545" }}>{Severity}</Badge></>;
                                                                        } else if (Severity === "Low") {
                                                                            return <> <Badge pill className="me-2  ms-1  fs-6" style={{ backgroundColor: "#198754" }}>{Severity}</Badge></>;
                                                                        } else if (Severity === "Medium") {
                                                                            return <> <Badge pill className="me-2  ms-1 fs-6 " style={{ backgroundColor: "#ffc107" }}>{Severity}</Badge></>;
                                                                        } else if (Severity === "Informational") {
                                                                            return <> <Badge pill className="me-2  ms-1 fs-6 " style={{ backgroundColor: "#0dcaf0" }}>{Severity}</Badge> </>;
                                                                        }
                                                                    })()}
                                                                    {/* {(() => {

                                                                        if (Severity === null) {
                                                                            return <>-</>;
                                                                        } else if (Severity === "Critical" || Severity === "High") {
                                                                            return <> <Badge pill className="me-2 bg-danger ms-1  fs-6">{Severity}</Badge></>;
                                                                        } else if (Severity === "Medium" || Severity === "Low") {
                                                                            return <> <Badge pill className="me-2 bg-warning ms-1 fs-6 ">{Severity}</Badge>

                                                                            </>;
                                                                        } else if (Severity === "Informational") {
                                                                            return <> <Badge pill className="me-2 bg-success ms-1 fs-6 ">{Severity}</Badge>

                                                                            </>;
                                                                        }

                                                                    })()} */}

                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    <Row className='my-2'>
                                                        <Col md={6}>

                                                            <div className="mb-3">
                                                                <Label className="form-label" htmlFor="employeeSize">CVSS Vector *  </Label>
                                                                <div>{CVSS_vector}</div>
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>

                                                            <div className="mb-3">
                                                                <Label className="form-label" htmlFor="employeeSize">Tags *  </Label>
                                                                <div>{CheckListTag}</div>
                                                            </div>
                                                        </Col>
                                                    </Row>


                                                    <Row className='my-2'>
                                                        <Col md={6}>
                                                            <div className="mb-3">
                                                                <Label className="form-label" htmlFor="companyLogo">Status * </Label>
                                                                <div>  {(() => {

                                                                    if (checkListTemplateStatus === null) {
                                                                        return <>-</>;
                                                                    } else if (checkListTemplateStatus === "Enable") {
                                                                        return <> <Badge pill className="badge-soft-success ms-1  fs-6">{checkListTemplateStatus}</Badge></>;
                                                                    } else if (checkListTemplateStatus === "Disable") {
                                                                        return <> <Badge pill className="badge-soft-danger ms-1  fs-6">{checkListTemplateStatus}</Badge>

                                                                        </>;
                                                                    } else if (checkListTemplateStatus === "Pending") {
                                                                        return <> <Badge pill className="badge-soft-warning ms-1  fs-6">{checkListTemplateStatus}</Badge>

                                                                        </>;
                                                                    }
                                                                })()}</div>
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    <Row className='my-3'>

                                                        <Label className="form-label" htmlFor="employeeSize">Description :  </Label>
                                                        <div className="text-secondary">{description}</div>
                                                    </Row>
                                                    <Row className='my-3'>

                                                        <Label className="form-label" htmlFor="employeeSize">Impact :  </Label>
                                                        <div className="text-secondary">{impact}</div>
                                                    </Row>
                                                    <Row className='my-3'>

                                                        <Label className="form-label" htmlFor="employeeSize">Remediation :  </Label>
                                                        <div className="text-secondary">{remediation}</div>
                                                    </Row>

                                                    <Row className='my-3'>

                                                        <Label className="form-label" htmlFor="employeeSize">Reference :  </Label>
                                                        <div className="text-secondary">{reference}</div>
                                                    </Row>



                                                </Form>
                                            </div>
                                        </div>

                                    </div>
                                )
                            }

                            if (checklistType === "Mobile Application Audit") {
                                return (
                                    <div className="modal-body">

                                        <div className="d-flex flex-column h-100">

                                            <div className='webApplication'>
                                                <Form >

                                                    <Row className='my-2'>
                                                        <Col md={6}>
                                                            <div className="mb-3">
                                                                <Label className="form-label" htmlFor="employeeSize">Title *  </Label>
                                                                <div>{Title}</div>
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>
                                                            <div className="mb-3">
                                                                <Label className="form-label" htmlFor="companyLogo">Severity * </Label>
                                                                <div>
                                                                    {(() => {
                                                                        if (Severity === null) {
                                                                            return <>-</>;
                                                                        } else if (Severity === "Critical") {
                                                                            return <> <Badge pill className="me-2  ms-1  fs-6" style={{ backgroundColor: "#ff0000" }}>{Severity}</Badge></>;
                                                                        } else if (Severity === "High") {
                                                                            return <> <Badge pill className="me-2  ms-1  fs-6" style={{ backgroundColor: "#dc3545" }}>{Severity}</Badge></>;
                                                                        } else if (Severity === "Low") {
                                                                            return <> <Badge pill className="me-2  ms-1  fs-6" style={{ backgroundColor: "#198754" }}>{Severity}</Badge></>;
                                                                        } else if (Severity === "Medium") {
                                                                            return <> <Badge pill className="me-2  ms-1 fs-6 " style={{ backgroundColor: "#ffc107" }}>{Severity}</Badge></>;
                                                                        } else if (Severity === "Informational") {
                                                                            return <> <Badge pill className="me-2  ms-1 fs-6 " style={{ backgroundColor: "#0dcaf0" }}>{Severity}</Badge> </>;
                                                                        }
                                                                    })()}
                                                                    {/* {(() => {

                                                                        if (Severity === null) {
                                                                            return <>-</>;
                                                                        } else if (Severity === "Critical" || Severity === "High") {
                                                                            return <> <Badge pill className="me-2 bg-danger ms-1  fs-6">{Severity}</Badge></>;
                                                                        } else if (Severity === "Medium" || Severity === "Low") {
                                                                            return <> <Badge pill className="me-2 bg-warning ms-1 fs-6 ">{Severity}</Badge>

                                                                            </>;
                                                                        } else if (Severity === "Informational") {
                                                                            return <> <Badge pill className="me-2 bg-success ms-1 fs-6 ">{Severity}</Badge>

                                                                            </>;
                                                                        }

                                                                    })()} */}

                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    <Row className='my-2'>
                                                        <Col md={6}>

                                                            <div className="mb-3">
                                                                <Label className="form-label" htmlFor="employeeSize">CVSS Vector *  </Label>
                                                                <div>{CVSS_vector}</div>
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>

                                                            <div className="mb-3">
                                                                <Label className="form-label" htmlFor="employeeSize">Tags *  </Label>
                                                                <div>{CheckListTag}</div>
                                                            </div>
                                                        </Col>
                                                    </Row>


                                                    <Row className='my-2'>
                                                        <Col md={6}>
                                                            <div className="mb-3">
                                                                <Label className="form-label" htmlFor="companyLogo">Status * </Label>
                                                                <div>  {(() => {

                                                                    if (checkListTemplateStatus === null) {
                                                                        return <>-</>;
                                                                    } else if (checkListTemplateStatus === "Enable") {
                                                                        return <> <Badge pill className="badge-soft-success ms-1  fs-6">{checkListTemplateStatus}</Badge></>;
                                                                    } else if (checkListTemplateStatus === "Disable") {
                                                                        return <> <Badge pill className="badge-soft-danger ms-1  fs-6">{checkListTemplateStatus}</Badge>

                                                                        </>;
                                                                    } else if (checkListTemplateStatus === "Pending") {
                                                                        return <> <Badge pill className="badge-soft-warning ms-1  fs-6">{checkListTemplateStatus}</Badge>

                                                                        </>;
                                                                    }
                                                                })()}</div>
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    <Row className='my-3'>

                                                        <Label className="form-label" htmlFor="employeeSize">Description :  </Label>
                                                        <div className="text-secondary">{description}</div>
                                                    </Row>
                                                    <Row className='my-3'>

                                                        <Label className="form-label" htmlFor="employeeSize">Impact :  </Label>
                                                        <div className="text-secondary">{impact}</div>
                                                    </Row>
                                                    <Row className='my-3'>

                                                        <Label className="form-label" htmlFor="employeeSize">Remediation :  </Label>
                                                        <div className="text-secondary">{remediation}</div>
                                                    </Row>

                                                    <Row className='my-3'>

                                                        <Label className="form-label" htmlFor="employeeSize">Reference :  </Label>
                                                        <div className="text-secondary">{reference}</div>
                                                    </Row>



                                                </Form>
                                            </div>
                                        </div>

                                    </div>
                                )
                            }

                            if (checklistType === "API Audit") {
                                return (
                                    <div className="modal-body">

                                        <div className="d-flex flex-column h-100">

                                            <div className='webApplication'>
                                                <Form >

                                                    <Row className='my-2'>
                                                        <Col md={6}>
                                                            <div className="mb-3">
                                                                <Label className="form-label" htmlFor="employeeSize">Title *  </Label>
                                                                <div>{Title}</div>
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>
                                                            <div className="mb-3">
                                                                <Label className="form-label" htmlFor="companyLogo">Severity * </Label>
                                                                <div>
                                                                    {(() => {
                                                                        if (Severity === null) {
                                                                            return <>-</>;
                                                                        } else if (Severity === "Critical") {
                                                                            return <> <Badge pill className="me-2  ms-1  fs-6" style={{ backgroundColor: "#ff0000" }}>{Severity}</Badge></>;
                                                                        } else if (Severity === "High") {
                                                                            return <> <Badge pill className="me-2  ms-1  fs-6" style={{ backgroundColor: "#dc3545" }}>{Severity}</Badge></>;
                                                                        } else if (Severity === "Low") {
                                                                            return <> <Badge pill className="me-2  ms-1  fs-6" style={{ backgroundColor: "#198754" }}>{Severity}</Badge></>;
                                                                        } else if (Severity === "Medium") {
                                                                            return <> <Badge pill className="me-2  ms-1 fs-6 " style={{ backgroundColor: "#ffc107" }}>{Severity}</Badge></>;
                                                                        } else if (Severity === "Informational") {
                                                                            return <> <Badge pill className="me-2  ms-1 fs-6 " style={{ backgroundColor: "#0dcaf0" }}>{Severity}</Badge> </>;
                                                                        }
                                                                    })()}
                                                                    {/* {(() => {

                                                                        if (Severity === null) {
                                                                            return <>-</>;
                                                                        } else if (Severity === "Critical" || Severity === "High") {
                                                                            return <> <Badge pill className="me-2 bg-danger ms-1  fs-6">{Severity}</Badge></>;
                                                                        } else if (Severity === "Medium" || Severity === "Low") {
                                                                            return <> <Badge pill className="me-2 bg-warning ms-1 fs-6 ">{Severity}</Badge>

                                                                            </>;
                                                                        } else if (Severity === "Informational") {
                                                                            return <> <Badge pill className="me-2 bg-success ms-1 fs-6 ">{Severity}</Badge>

                                                                            </>;
                                                                        }

                                                                    })()} */}

                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    <Row className='my-2'>
                                                        <Col md={6}>

                                                            <div className="mb-3">
                                                                <Label className="form-label" htmlFor="employeeSize">CVSS Vector *  </Label>
                                                                <div>{CVSS_vector}</div>
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>

                                                            <div className="mb-3">
                                                                <Label className="form-label" htmlFor="employeeSize">Tags *  </Label>
                                                                <div>{CheckListTag}</div>
                                                            </div>
                                                        </Col>
                                                    </Row>


                                                    <Row className='my-2'>
                                                        <Col md={6}>
                                                            <div className="mb-3">
                                                                <Label className="form-label" htmlFor="companyLogo">Status * </Label>
                                                                <div>  {(() => {

                                                                    if (checkListTemplateStatus === null) {
                                                                        return <>-</>;
                                                                    } else if (checkListTemplateStatus === "Enable") {
                                                                        return <> <Badge pill className="badge-soft-success ms-1  fs-6">{checkListTemplateStatus}</Badge></>;
                                                                    } else if (checkListTemplateStatus === "Disable") {
                                                                        return <> <Badge pill className="badge-soft-danger ms-1  fs-6">{checkListTemplateStatus}</Badge>

                                                                        </>;
                                                                    } else if (checkListTemplateStatus === "Pending") {
                                                                        return <> <Badge pill className="badge-soft-warning ms-1  fs-6">{checkListTemplateStatus}</Badge>

                                                                        </>;
                                                                    }
                                                                })()}</div>
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    <Row className='my-3'>

                                                        <Label className="form-label" htmlFor="employeeSize">Description :  </Label>
                                                        <div className="text-secondary">{description}</div>
                                                    </Row>
                                                    <Row className='my-3'>

                                                        <Label className="form-label" htmlFor="employeeSize">Impact :  </Label>
                                                        <div className="text-secondary">{impact}</div>
                                                    </Row>
                                                    <Row className='my-3'>

                                                        <Label className="form-label" htmlFor="employeeSize">Remediation :  </Label>
                                                        <div className="text-secondary">{remediation}</div>
                                                    </Row>

                                                    <Row className='my-3'>

                                                        <Label className="form-label" htmlFor="employeeSize">Reference :  </Label>
                                                        <div className="text-secondary">{reference}</div>
                                                    </Row>



                                                </Form>
                                            </div>
                                        </div>

                                    </div>
                                )
                            }

                            if (checklistType === "Network Audit") {
                                return (
                                    <div className="modal-body">

                                        <div className="d-flex flex-column h-100">

                                            <div className='webApplication'>
                                                <Form >

                                                    <Row className='my-2'>
                                                        <Col md={6}>
                                                            <div className="mb-3">
                                                                <Label className="form-label" htmlFor="employeeSize">Title *  </Label>
                                                                <div>{Title}</div>
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>
                                                            <div className="mb-3">
                                                                <Label className="form-label" htmlFor="companyLogo">Severity * </Label>
                                                                <div>
                                                                    {(() => {
                                                                        if (Severity === null) {
                                                                            return <>-</>;
                                                                        } else if (Severity === "Critical") {
                                                                            return <> <Badge pill className="me-2  ms-1  fs-6" style={{ backgroundColor: "#ff0000" }}>{Severity}</Badge></>;
                                                                        } else if (Severity === "High") {
                                                                            return <> <Badge pill className="me-2  ms-1  fs-6" style={{ backgroundColor: "#dc3545" }}>{Severity}</Badge></>;
                                                                        } else if (Severity === "Low") {
                                                                            return <> <Badge pill className="me-2  ms-1  fs-6" style={{ backgroundColor: "#198754" }}>{Severity}</Badge></>;
                                                                        } else if (Severity === "Medium") {
                                                                            return <> <Badge pill className="me-2  ms-1 fs-6 " style={{ backgroundColor: "#ffc107" }}>{Severity}</Badge></>;
                                                                        } else if (Severity === "Informational") {
                                                                            return <> <Badge pill className="me-2  ms-1 fs-6 " style={{ backgroundColor: "#0dcaf0" }}>{Severity}</Badge> </>;
                                                                        }
                                                                    })()}
                                                                    {/* {(() => {

                                                                        if (Severity === null) {
                                                                            return <>-</>;
                                                                        } else if (Severity === "Critical" || Severity === "High") {
                                                                            return <> <Badge pill className="me-2 bg-danger ms-1  fs-6">{Severity}</Badge></>;
                                                                        } else if (Severity === "Medium" || Severity === "Low") {
                                                                            return <> <Badge pill className="me-2 bg-warning ms-1 fs-6 ">{Severity}</Badge>

                                                                            </>;
                                                                        } else if (Severity === "Informational") {
                                                                            return <> <Badge pill className="me-2 bg-success ms-1 fs-6 ">{Severity}</Badge>

                                                                            </>;
                                                                        }

                                                                    })()} */}

                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    <Row className='my-2'>
                                                        <Col md={6}>

                                                            <div className="mb-3">
                                                                <Label className="form-label" htmlFor="employeeSize">CVSS Vector *  </Label>
                                                                <div>{CVSS_vector}</div>
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>

                                                            <div className="mb-3">
                                                                <Label className="form-label" htmlFor="employeeSize">Tags *  </Label>
                                                                <div>{CheckListTag}</div>
                                                            </div>
                                                        </Col>
                                                    </Row>


                                                    <Row className='my-2'>
                                                        <Col md={6}>
                                                            <div className="mb-3">
                                                                <Label className="form-label" htmlFor="companyLogo">Status * </Label>
                                                                <div>  {(() => {

                                                                    if (checkListTemplateStatus === null) {
                                                                        return <>-</>;
                                                                    } else if (checkListTemplateStatus === "Enable") {
                                                                        return <> <Badge pill className="badge-soft-success ms-1  fs-6">{checkListTemplateStatus}</Badge></>;
                                                                    } else if (checkListTemplateStatus === "Disable") {
                                                                        return <> <Badge pill className="badge-soft-danger ms-1  fs-6">{checkListTemplateStatus}</Badge>

                                                                        </>;
                                                                    } else if (checkListTemplateStatus === "Pending") {
                                                                        return <> <Badge pill className="badge-soft-warning ms-1  fs-6">{checkListTemplateStatus}</Badge>

                                                                        </>;
                                                                    }
                                                                })()}</div>
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    <Row className='my-3'>

                                                        <Label className="form-label" htmlFor="employeeSize">Description :  </Label>
                                                        <div className="text-secondary">{description}</div>
                                                    </Row>

                                                    <Row className='my-3'>

                                                        <Label className="form-label" htmlFor="employeeSize">Remediation :  </Label>
                                                        <div className="text-secondary">{remediation}</div>
                                                    </Row>

                                                    <Row className='my-3'>

                                                        <Label className="form-label" htmlFor="employeeSize">Reference :  </Label>
                                                        <div className="text-secondary">{reference}</div>
                                                    </Row>



                                                </Form>
                                            </div>
                                        </div>

                                    </div>
                                )
                            }
                        })()
                    }

                    <div className="modal-footer">
                        <button
                            type="button"
                            onClick={() => {
                                toggle_view_more_modal();
                                setview_more_modal(false);
                            }}
                            className="btn btn-warning "
                            data-dismiss="modal"
                        >
                            Close
                        </button>
                        {/* <button
                                type="button"
                                onClick={() => {
                                    // updatePersonalDetails_sweetAlert()
                                    editChecklistTemplate()
                                }}
                                // disabled={detailBtn}
                                className="btn btn-warning "
                                data-dismiss="modal"
                            >
                                Save
                            </button> */}
                    </div>
                </Modal>

                {/* Add Checklist  */}
                <Modal
                    isOpen={modal_add_auditee}
                    toggle={() => {
                        tog_standard_add_auditee();
                    }}
                    className="modal-xl"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myModalLabel">
                            Add CheckList Template
                        </h5>
                        <button
                            type="button"
                            onClick={() => {
                                setmodal_add_auditee(false);
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    {(() => {
                        // console.log("checklist type", checklistType)
                        if (checklistType === "Web Application Audit") {
                            return (
                                <div className="modal-body">

                                    <div className="d-flex flex-column h-100">

                                        <div className='webApplication'>

                                            <Form >


                                                <Row className='my-2'>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="companyLogo">Title  : </Label>
                                                            <Input type="text" className="form-control" id="companyLogo"
                                                                value={Title}
                                                                onChange={(e) => {
                                                                    // console.log(e.target.value);
                                                                    setTitle(e.target.value);
                                                                }} />
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="employeeSize">Severity :  </Label>
                                                            <Select
                                                                // value={Severity}
                                                                // ref={ref => {
                                                                //     selectRef_severity = ref;
                                                                // }}
                                                                // placeholder={Severity}
                                                                ref={selectRef_severity}
                                                                // defaultValue={optionGroup_severity[0] || ' '}
                                                                // onChange={(e) => {
                                                                //     console.log(e.value);
                                                                //     setSeverity(e.value);
                                                                //     // handleSelectOptions_empSize(e.value);
                                                                //     // console.log(selectedOptions_empSize)
                                                                // }}
                                                                options={optionGroup_severity}
                                                                classNamePrefix="select2-selection"
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className='my-2'>

                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="companyEmail">CVSS Vector: </Label>
                                                            <Input type="text" className="form-control" id="companyEmail" value={CVSS_vector}
                                                                onChange={(e) => {
                                                                    // console.log(e.target.value);
                                                                    setCVSS_vector(e.target.value);
                                                                }} />
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="companyEmail">Tags : </Label>
                                                            <Input type="text" className="form-control" id="companyEmail"
                                                                value={CheckListTag}
                                                                onChange={(e) => {
                                                                    // console.log(e.target.value);
                                                                    setCheckListTag(e.target.value);
                                                                }} />
                                                        </div>
                                                    </Col>    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="companyEmail">Status : </Label> <Select
                                                                // value={checkListTemplateStatus}
                                                                // defaultValue={optionGroup_status[0]}
                                                                ref={selectRef_status}
                                                                // onChange={(e) => {
                                                                //     // console.log(e.value)
                                                                //     setcheckListTemplateStatus(e.value);
                                                                //     // handleSelectOptions_empSize(e.value);
                                                                //     // console.log(selectedOptions_empSize)
                                                                // }}
                                                                options={optionGroup_status}
                                                                classNamePrefix="select2-selection"
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Description :</Label>


                                                    <div className="mb-3" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={description}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setdescription(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>

                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Impact :</Label>


                                                    <div className="mb-3" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={impact}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setimpact(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>

                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Remediation :</Label>


                                                    <div className="mb-3" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={remediation}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setremediation(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>

                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Reference :</Label>


                                                    <div className="mb-3" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={reference}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setreference(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>

                                            </Form>
                                        </div>
                                    </div>

                                </div>
                            )
                        }
                        if (checklistType === "Mobile Application Audit") {
                            return (
                                <div className="modal-body">

                                    <div className="d-flex flex-column h-100">

                                        <div className='webApplication'>

                                            <Form >

                                                <Row className='my-2'>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="companyLogo">Title  : </Label>
                                                            <Input type="text" className="form-control" id="companyLogo"
                                                                value={Title}
                                                                onChange={(e) => {
                                                                    // console.log(e.target.value);
                                                                    setTitle(e.target.value);
                                                                }} />
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="employeeSize">Severity :  </Label>
                                                            <Select
                                                                // value={Severity}
                                                                // ref={ref => {
                                                                //     selectRef_severity = ref;
                                                                // }}
                                                                // placeholder={Severity}
                                                                ref={selectRef_severity}
                                                                // defaultValue={optionGroup_severity[0] || ' '}
                                                                // onChange={(e) => {
                                                                //     console.log(e.value);
                                                                //     setSeverity(e.value);
                                                                //     // handleSelectOptions_empSize(e.value);
                                                                //     // console.log(selectedOptions_empSize)
                                                                // }}
                                                                options={optionGroup_severity}
                                                                classNamePrefix="select2-selection"
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className='my-2'>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="companyEmail">CVSS Vector: </Label>
                                                            <Input type="text" className="form-control" id="companyEmail" value={CVSS_vector}
                                                                onChange={(e) => {
                                                                    // console.log(e.target.value);
                                                                    setCVSS_vector(e.target.value);
                                                                }} />
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="companyEmail">Tags : </Label>
                                                            <Input type="text" className="form-control" id="companyEmail"
                                                                value={CheckListTag}
                                                                onChange={(e) => {
                                                                    // console.log(e.target.value);
                                                                    setCheckListTag(e.target.value);
                                                                }} />
                                                        </div>
                                                    </Col>    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="companyEmail">Status : </Label> <Select
                                                                // value={checkListTemplateStatus}
                                                                // defaultValue={optionGroup_status[0]}
                                                                ref={selectRef_status}
                                                                // onChange={(e) => {
                                                                //     // console.log(e.value)
                                                                //     setcheckListTemplateStatus(e.value);
                                                                //     // handleSelectOptions_empSize(e.value);
                                                                //     // console.log(selectedOptions_empSize)
                                                                // }}
                                                                options={optionGroup_status}
                                                                classNamePrefix="select2-selection"
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>







                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Description :</Label>


                                                    <div className="mb-3" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={description}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setdescription(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>

                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Impact :</Label>


                                                    <div className="mb-3" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={impact}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setimpact(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>

                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Remediation :</Label>


                                                    <div className="mb-3" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={remediation}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setremediation(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>

                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Reference :</Label>


                                                    <div className="mb-3" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={reference}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setreference(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>

                                            </Form>
                                        </div>
                                    </div>

                                </div>
                            )
                        }
                        if (checklistType === "API Audit") {
                            return (
                                <div className="modal-body">

                                    <div className="d-flex flex-column h-100">

                                        <div className='webApplication'>

                                            <Form >

                                                <Row className='my-2'>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="companyLogo">Title  : </Label>
                                                            <Input type="text" className="form-control" id="companyLogo"
                                                                value={Title}
                                                                onChange={(e) => {
                                                                    // console.log(e.target.value);
                                                                    setTitle(e.target.value);
                                                                }} />
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="employeeSize">Severity :  </Label>
                                                            <Select
                                                                // value={Severity}
                                                                // ref={ref => {
                                                                //     selectRef_severity = ref;
                                                                // }}
                                                                // placeholder={Severity}
                                                                ref={selectRef_severity}
                                                                // defaultValue={optionGroup_severity[0] || ' '}
                                                                // onChange={(e) => {
                                                                //     console.log(e.value);
                                                                //     setSeverity(e.value);
                                                                //     // handleSelectOptions_empSize(e.value);
                                                                //     // console.log(selectedOptions_empSize)
                                                                // }}
                                                                options={optionGroup_severity}
                                                                classNamePrefix="select2-selection"
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className='my-2'>

                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="companyEmail">CVSS Vector: </Label>
                                                            <Input type="text" className="form-control" id="companyEmail" value={CVSS_vector}
                                                                onChange={(e) => {
                                                                    // console.log(e.target.value);
                                                                    setCVSS_vector(e.target.value);
                                                                }} />
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="companyEmail">Tags : </Label>
                                                            <Input type="text" className="form-control" id="companyEmail"
                                                                value={CheckListTag}
                                                                onChange={(e) => {
                                                                    // console.log(e.target.value);
                                                                    setCheckListTag(e.target.value);
                                                                }} />
                                                        </div>
                                                    </Col>    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="companyEmail">Status : </Label> <Select
                                                                // value={checkListTemplateStatus}
                                                                // defaultValue={optionGroup_status[0]}
                                                                ref={selectRef_status}
                                                                // onChange={(e) => {
                                                                //     // console.log(e.value)
                                                                //     setcheckListTemplateStatus(e.value);
                                                                //     // handleSelectOptions_empSize(e.value);
                                                                //     // console.log(selectedOptions_empSize)
                                                                // }}
                                                                options={optionGroup_status}
                                                                classNamePrefix="select2-selection"
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>







                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Description :</Label>


                                                    <div className="mb-3" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={description}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setdescription(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>

                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Impact :</Label>


                                                    <div className="mb-3" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={impact}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setimpact(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>

                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Remediation :</Label>


                                                    <div className="mb-3" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={remediation}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setremediation(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>

                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Reference :</Label>


                                                    <div className="mb-3" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={reference}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setreference(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>

                                            </Form>
                                        </div>
                                    </div>

                                </div>
                            )
                        }
                        if (checklistType === "Network Audit") {
                            return (
                                <div className="modal-body">

                                    <div className="d-flex flex-column h-100">

                                        <div className='webApplication'>

                                            <Form >


                                                <Row className='my-2'>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="companyLogo">Title  : </Label>
                                                            <Input type="text" className="form-control" id="companyLogo"
                                                                value={Title}
                                                                onChange={(e) => {
                                                                    // console.log(e.target.value);
                                                                    setTitle(e.target.value);
                                                                }} />
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="employeeSize">Severity :  </Label>
                                                            <Select
                                                                // value={Severity}
                                                                // ref={ref => {
                                                                //     selectRef_severity = ref;
                                                                // }}
                                                                // placeholder={Severity}
                                                                ref={selectRef_severity}
                                                                // defaultValue={optionGroup_severity[0] || ' '}
                                                                // onChange={(e) => {
                                                                //     console.log(e.value);
                                                                //     setSeverity(e.value);
                                                                //     // handleSelectOptions_empSize(e.value);
                                                                //     // console.log(selectedOptions_empSize)
                                                                // }}
                                                                options={optionGroup_severity}
                                                                classNamePrefix="select2-selection"
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className='my-2'>

                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="companyEmail">CVSS Vector: </Label>
                                                            <Input type="text" className="form-control" id="companyEmail" value={CVSS_vector}
                                                                onChange={(e) => {
                                                                    // console.log(e.target.value);
                                                                    setCVSS_vector(e.target.value);
                                                                }} />
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="companyEmail">Tags : </Label>
                                                            <Input type="text" className="form-control" id="companyEmail"
                                                                value={CheckListTag}
                                                                onChange={(e) => {
                                                                    // console.log(e.target.value);
                                                                    setCheckListTag(e.target.value);
                                                                }} />
                                                        </div>
                                                    </Col>    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="companyEmail">Status : </Label> <Select
                                                                // value={checkListTemplateStatus}
                                                                // defaultValue={optionGroup_status[0]}
                                                                ref={selectRef_status}
                                                                // onChange={(e) => {
                                                                //     // console.log(e.value)
                                                                //     setcheckListTemplateStatus(e.value);
                                                                //     // handleSelectOptions_empSize(e.value);
                                                                //     // console.log(selectedOptions_empSize)
                                                                // }}
                                                                options={optionGroup_status}
                                                                classNamePrefix="select2-selection"
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Description :</Label>


                                                    <div className="mb-3" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={description}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setdescription(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>


                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Remediation :</Label>


                                                    <div className="mb-3" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={remediation}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setremediation(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>

                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Reference :</Label>


                                                    <div className="mb-3" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={reference}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setreference(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>

                                            </Form>
                                        </div>
                                    </div>

                                </div>
                            )
                        }
                    })()}
                    <div className="modal-footer">
                        <button
                            type="button"
                            onClick={() => {
                                tog_standard_add_auditee();
                                setmodal_add_auditee(false);
                            }}
                            className="btn btn-secondary "
                            data-dismiss="modal"
                        >
                            Close
                        </button>
                        <button
                            type="button"
                            onClick={async () => {
                                // updatePersonalDetails_sweetAlert()
                                clearValue()
                                addVulnerabilities()
                            }}
                            className="btn btn-success "
                        // data-dismiss="modal"
                        >
                            Save and Next
                        </button>
                    </div>
                </Modal>
            </div>
        </React.Fragment>
    );
};

export default CheckListTemplate;
