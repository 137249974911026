import React, { useState, useEffect, useMemo } from "react";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import CountUp from "react-countup";


import FeatherIcon from "feather-icons-react";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// import "react-table/react-table.css";
import {
    Badge,
    CardBody,
    Col,
    Modal,
    Row,
    Card,
    Input,
    Button,
    Form, Label, CardHeader,
    CardTitle,
    Spinner,
    CardSubtitle,
} from "reactstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import Select from "react-select"
import { AvForm, AvField } from "availity-reactstrap-validation";

import MetaTags from "react-meta-tags";
import swal from 'sweetalert';
import axios from "axios"

//import images
import Breadcrumbs from "../../../components/Common/Breadcrumb";


import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { country_code, country_state } from "./data";
const Auditee = () => {

    // const location = useLocation();
    // const history = useHistory()
    // console.log("location", history.location);
    const [auditeeListPreloader, setauditeeListPreloader] = useState(true)
    const [productData, setProductData] = useState([]);
    const [modal_standard4, setmodal_standard4] = useState(false); // View More button Modal of ExposedCredentials


    const [modal_add_auditee, setmodal_add_auditee] = useState(false); // View More button Modal of ExposedCredentials
    const [view_more_modal, setview_more_modal] = useState(false); // View More button Modal of ExposedCredentials

    const [orgNum, setOrgNum] = useState(0);
    const [activeOrg, setActiveOrg] = useState(0);
    const [InActiveOrg, setInActiveOrg] = useState(0);


    // values stored of form
    const [OrgName, setOrgName] = useState("");
    const [status, setstatus] = useState("");

    // validation state error 
    const [emailError, setemailError] = useState("");
    const [OrgNameError, setOrgNameError] = useState("");
    const [OrganizaionID, setOrganizaionID] = useState("");
    const [UserCount, setUserCount] = useState("");
    const [formOrganizationID, setformOrganizationID] = useState(""); // used to send userID of row to backend
    const [detailBtn, setdetailBtn] = useState(true); // to disable the button on inital


    // function to get uniques ID
    function uniqueIdGenerator() {
        var seq = (Math.floor(Math.random() * 100) + 100).toString().substring(1);
        const id = Date.now() + seq;
        return id;
    }
    const userCheck = localStorage.getItem("AuditorID");

    const [reportID, setReportID] = useState(localStorage.getItem("reportId"));
    const [findvulnerability, setfindvulnerability] = useState();
    const [rating, setrating] = useState()
    const [vulnerabilityDescription, setvulnerabilityDescription] = useState();
    const [vulnerabilityImpact, setvulnerabilityImpact] = useState();
    const [vulnerabilityRemediation, setvulnerabilityRemediation] = useState();
    const [vulnerabilityAffectedURL, setvulnerabilityAffectedURL] = useState();
    // const [vulnerabilityAffectedURL , setvulnerabilityAffectedURL] =  useState();
    const [vulnerabilityImg1, setvulnerabilityImg1] = useState();
    const [vulnerabilityList, setVulnerabilityList] = useState();


    const [companyName, setcompanyName] = useState();
    const [companyLogo, setcompanyLogo] = useState();
    // const [empSize, setempSize] = useState();
    const [country, setcountry] = useState();
    const [companyEmail, setcompanyEmail] = useState();
    const [companyPhone, setcompanyPhone] = useState();
    const [companyWebsite, setcompanyWebsite] = useState();
    const [city, setcity] = useState();
    const [state, setstate] = useState();
    const [pincode, setpincode] = useState();
    const [otherDetails, setotherDetails] = useState();

    const [status_empSize, setstatus_empSize] = useState("");
    const [organizationSector, setorganizationSector] = useState("");
    const [industry, setindustry] = useState("");
    const [statusCountry, setstatusCountry] = useState("");
    const [companyStatus, setcompanyStatus] = useState();
    const [street, setStreet] = useState();
    const [selectState, setselectState] = useState([]);
    const [organizationlogo, setorganizationlogo] = useState();


    const [status_vulnerability_status, setstatus_vulnerability_status] = useState("");
    const [selectedOptions_vulnerability_status, setselectedOptions_vulnerability_status] = useState(null);


    const optionGroup_empSize = [
        { label: "1-10", value: "1-10" },
        { label: "11-50", value: "11-50" },
        { label: "51-100", value: "51-100" },
        { label: "101-250", value: "101-250" },
        { label: "251-500", value: "251-500" },
        { label: "1000+", value: "1000+" }
    ];
    const optionGroup_OrganizationSector = [
        { label: "Private", value: "Private" },
        { label: "Government", value: "Government" },
    ];
    const optionGroup_industry = [
        { label: "IT", value: "IT" },
        { label: "Agriculture", value: "Agriculture" },
        { label: "Allied Industries", value: "Allied Industries" },
        { label: "Automobiles", value: "Automobiles" },
        { label: "Auto Components", value: "Auto Components" },
        { label: "Aviation", value: "Aviation" },
        { label: "Banking", value: "Banking" },
        { label: "Biotechnology", value: "Biotechnology" },
        { label: "Cement", value: "Cement" },
        { label: "Chemicals", value: "Chemicals" },
        { label: "Consumer Durables", value: "Consumer Durables" },
        { label: "Defence Manufacturing", value: "Defence Manufacturing" },
        { label: "eCommerce", value: "eCommerce" },
        { label: "Education & Training", value: "Education & Training" },
        { label: "Electronics System Design & Manufacturing", value: "Electronics System Design & Manufacturing" },
        { label: "Engineering & Capital Goods", value: "Engineering & Capital Goods" },
        { label: "Financial Services", value: "Financial Services" },
        { label: "FMCG", value: "FMCG" },
        { label: "Gems & Jewellery", value: "Gems & Jewellery" },
        { label: "Healthcare", value: "Healthcare" },
        { label: "Infrastructure", value: "Infrastructure" },
        { label: "Insurance", value: "Insurance" },
        { label: "Manufacturing", value: "Manufacturing" },
        { label: "Media & Entertainment", value: "Media & Entertainment" },
        { label: "Medical Devices", value: "Medical Devices" },
        { label: "Metals & Mining", value: "Metals & Mining" },
        { label: "Oil & Gas", value: "Oil & Gas" },
        { label: "Pharmaceuticals", value: "Pharmaceuticals" },
        { label: "Ports", value: "Ports" },
        { label: "Power", value: "Power" },
        { label: "Railways", value: "Railways" },
        { label: "Real Estate", value: "Real Estate" },
        { label: "Renewable Energy", value: "Renewable Energy" },
        { label: "Retail", value: "Retail" },
        { label: "Roads", value: "Roads" },
        { label: "Science & Technology", value: "Science & Technology" },
        { label: "Services", value: "Services" },
        { label: "Steel", value: "Steel" },
        { label: "Telecommunications", value: "Telecommunications" },
        { label: "Textiles", value: "Textiles" },
        { label: "Tourism & Hospitality", value: "Tourism & Hospitality" },
    ];

    function assignCountry() {
        // console.log("assign countssry")
        let tempArr = [];
        for (let i = 0; i < country_state.length; i++) {
            tempArr[i] = { label: country_state[i].country, value: country_state[i].country }
        }
        return tempArr;
    }

    const countryChange = useMemo(() => assignCountry(), [statusCountry])

    function assignState(country) {
        let tempArr = [];
        for (let i = 0; i < country_state.length; i++) {
            // console.log("states - 2", country_state[i])
            if (country === country_state[i].country) {
                for (let j = 0; j < country_state[i].states.length; j++) {
                    tempArr[j] = { label: country_state[i].states[j], value: country_state[i].states[j] }
                    // console.log("states - 1", country_state[i].states[j])
                }
                // console.table(tempArr)
                setselectState(tempArr)
            }
        }
    }
    const optionGroup_country = [
        { label: "India", value: "India" },
        { label: "USA", value: "USA" },
        { label: "Medium", value: "Medium" },
        { label: "Low", value: "Low" },
        { label: "Informational", value: "Informational" }
    ];
    const optionGroup_CompanyStatus = [
        { label: "Enabled", value: "Enabled" },
        { label: "Disabled", value: "Disabled" },
    ];

    // form validatioon checkuo end ------------->>>>>>>>>>>>>>>>>>>>>>>>>>

    // function to handle change in status dropdown
    //  function handleSelectOptions_empSize(selectedOptions_empSize) {
    //     setselectedOptions_empSize(selectedOptions_empSize);
    // }

    // function to handle change in status dropdown
    function handleSelectOptions_severity(selectedOptions_severity) {
        // setselectedOptions_severity(selectedOptions_severity);
    }
    // function to handle change in status dropdown
    function handleSelectOptions_vulnerability_status(selectedOptions_vulnerability_status) {
        setselectedOptions_vulnerability_status(selectedOptions_vulnerability_status);
    }



    const [selectedOptions, setselectedOptions] = useState(null)
    // const animatedComponents = makeAnimated()
    // const orgID = localStorage.getItem("orgID");
    const token = localStorage.getItem("token");

    const optionGroup = [
        { label: "Active", value: "Active" },
        { label: "InActive", value: "InActive" },
    ]
    // Columns of DataTable
    const columns = [
        {
            dataField: "OrganizationID",
            text: "Organization ID",
            sort: true,
            formatter: (cellContent, productData) => (
                <>
                    {(() => {
                        if (productData.OrganizationID === null) {
                            return <>-</>;
                        } else {
                            return <>
                                <Badge className="me-2 bg-primary p-1 fs-6">
                                    {productData.OrganizationID}
                                </Badge></>;
                        }
                    })()}
                </>
            ),
        },
        {
            dataField: "OrganizationName",
            text: "Organization Name",
            sort: true,
            formatter: (cellContent, productData) => (
                <>
                    {(() => {
                        if (productData.OrganizationName === null) {
                            return <>-</>;
                        } else {
                            return <Badge pill className="badge-soft-warning ms-1 fs-6 " style={{color : "#000000"}}>{productData.OrganizationName}</Badge>;
                        }
                    })()}
                </>
            ),
        }, {
            dataField: "ProjectCount",
            text: "Project Count",
            sort: true,
            formatter: (cellContent, productData) => (
                <>
                    {(() => {
                        if (productData.ProjectCount === null) {
                            return <>-</>;
                        } else return <Badge pill className="badge-soft-danger ms-1 fs-6 ">{productData.ProjectCount}</Badge>
                    })()}
                </>
            ),
        },
        {
            dataField: "UserCount",
            text: "User Count",
            sort: true,
            formatter: (cellContent, productData) => (
                <>
                    {(() => {
                        if (productData.UserCount === null) {
                            return <>-</>;
                        } else return <Badge pill className="badge-soft-danger ms-1 fs-6 ">{productData.UserCount}</Badge>
                    })()}
                </>
            ),
        },
        // {
        //     dataField: "Value",
        //     text: "Users Count",
        //     sort: true,
        //     formatter: (cellContent, productData) => (
        //         <>
        //             {(() => {
        //                 if (productData.UserCount === null) {
        //                     return <>-</>;
        //                 } else {
        //                     return <>
        //                         <td>  <Badge pill className="me-2 bg-danger ">{productData.UserCount.length}</Badge></td></>;
        //                 }
        //             })()}
        //         </>
        //     ),
        // },
        {
            dataField: "OrganizationSector",
            text: "Organization Sector",
            sort: true,
            formatter: (cellContent, productData) => (
                <>
                    {(() => {
                        if (productData.OrganizationSector === null) {
                            return <>-</>;
                        } else {
                            return <>{productData.OrganizationSector}</>;
                        }
                    })()}
                </>
            ),
        },
        {
            dataField: "Industry",
            text: "Industry",
            sort: true,
            formatter: (cellContent, productData) => (
                <>
                    {(() => {
                        if (productData.Industry === null) {
                            return <>-</>;
                        } else {
                            return <>{productData.Industry}</>;
                        }
                    })()}
                </>
            ),
        },
        {
            dataField: "Country",
            text: "Country",
            sort: true,
            formatter: (cellContent, productData) => (
                <>
                    {(() => {
                        if (productData.Country === null) {
                            return <>-</>;
                        } else {
                            return <>{productData.Country}</>;
                        }
                    })()}
                </>
            ),
        },
        {
            dataField: "Status",
            text: "Status",
            sort: true,
            formatter: (cellContent, productData) => (
                <>
                    {(() => {
                        if (productData.Status === null) {
                            return <>-</>;
                        } else if (productData.Status === "Enabled") {
                            return <> <Badge pill className="badge-soft-success ms-1 fs-6 ">{productData.Status}</Badge></>;
                        } else if (productData.Status === "Disabled") {
                            return <> <Badge pill className="badge-soft-danger ms-1  fs-6">{productData.Status}</Badge></>;
                        }
                    })()}
                </>
            ),
        },
        {
            dataField: "Action",
            text: "Action",
            sort: true,
            formatter: (cellContent, productData) => (

                <>

                    <Button
                        color="info"
                        className="btn-sm me-2"
                        // disabled={role === "Admin" ? false : true}
                        onClick={() => {
                            setOrgName(productData.OrganizationName)
                            setOrganizaionID(productData.OrganizationID)
                            setorganizationSector(productData.OrganizationSector)
                            setindustry(productData.Industry)
                            setstatus_empSize(productData.EmployeeSize);
                            setcompanyEmail(productData.Email)
                            setpincode(productData.Pincode)
                            setcompanyPhone(productData.Phone)
                            setstate(productData.State)
                            setcountry(productData.Country)
                            setcompanyStatus(productData.Status)
                            // setstatus(productData.Status)

                            toggle_view_more_modal();
                            // tog_standard4();
                            // // setmodalPopupData(productData.UserCount)
                            // setOrganizaionID(productData.OrganizationID)
                            // setUserCount(productData.UserCount.length)
                            // setOrgName(productData.OrganizationName)
                        }}
                    >
                        View
                    </Button>
                    {/* {console.log("without cliecekd", productData.Domains)} */}

                    {(() => {
                        if (role === "Team Leader" || role === "Project Manager" || role === "Admin") {
                            return (
                                <Button
                                    color="warning"
                                    className="btn-sm me-2"
                                    disabled={role === "Auditor" ? true : false}
                                    onClick={() => {
                                        // console.log("cliecekd", [productData]);
                                        setcompanyName(productData.OrganizationName)
                                        setOrganizaionID(productData.OrganizationID)
                                        setorganizationSector(productData.OrganizationSector)
                                        setindustry(productData.Industry)
                                        setstatus_empSize(productData.EmployeeSize);
                                        setcompanyEmail(productData.Email)
                                        setpincode(productData.Pincode)
                                        setcompanyPhone(productData.Phone)
                                        setstate(productData.State)
                                        setcity(productData.City)
                                        setstatusCountry(productData.Country)
                                        setcompanyStatus(productData.Status)
                                        setcompanyWebsite(productData.Website)
                                        setotherDetails(productData.OtherDetails)
                                        setorganizationlogo(productData.OrganizationLogo)
                                        tog_standard4();
                                        // setmodalPopupData(productData.UserCount)
                                    }}
                                >
                                    Edit
                                </Button>
                            )
                        }
                    })()}
                    {(() => {
                        if (role === "Admin") {
                            return (
                                <Button
                                    color="danger"
                                    className="btn-sm me-2"
                                    disabled={role === "Admin" ? false : true}
                                    onClick={() => {
                                        // console.log("cliecekd", [productData]);
                                        swal({
                                            title: "Are you sure?",
                                            text: "Once deleted, you will not be able to recover.",
                                            icon: "warning",
                                            buttons: true,
                                            dangerMode: true,
                                        })
                                            .then((willDelete) => {
                                                if (willDelete) {
                                                    // console.log(AuditorIDForDeleteUser)
                                                    deleteUser(productData.OrganizationID);
                                                    swal("Poof! Your  file has been deleted!", {
                                                        icon: "success",
                                                    })
                                                        .then((willDelete) => {
                                                            if (willDelete) {
                                                                getCustomers()
                                                                // window.location.reload()
                                                            }
                                                        })
                                                } else {
                                                    swal("Your  file is safe!");
                                                }
                                            });

                                        // tog_standard4();
                                        // // setmodalPopupData(productData.UserCount)
                                        // setOrganizaionID(productData.OrganizationID)
                                        // setUserCount(productData.UserCount.length)
                                        // setOrgName(productData.OrganizationName)
                                    }}
                                >
                                    Delete
                                </Button>
                            )
                        }
                    })()}

                    <Link to={`/auditee/users/${productData.OrganizationID}`} className="text-white me-2">
                        <Button
                            className="btn-sm"
                            color="primary"
                        // onClick={() => {
                        //     // console.log("cliecekd", [productData]);
                        //     tog_standard4();
                        //     setmodalPopupData(productData.UserCount)
                        // }}
                        >

                            Users

                        </Button>
                    </Link>
                </>
            ),
        },
        // {
        //     dataField: "Delete",
        //     text: "Delete",
        //     sort: true,
        //     formatter: (cellContent, productData) => (

        //         <>
        //             <AvForm>
        //                 {/* {console.log("without cliecekd", productData.Domains)} */}
        //                 <Button
        //                     color="danger"
        //                     className="btn-sm"
        //                     disabled = {role === "Admin" ? false : true}
        //                     onClick={() => {
        //                         // console.log("cliecekd", [productData]);
        //                         swal({
        //                             title: "Are you sure?",
        //                             text: "Once deleted, you will not be able to recover.",
        //                             icon: "warning",
        //                             buttons: true,
        //                             dangerMode: true,
        //                         })
        //                             .then((willDelete) => {
        //                                 if (willDelete) {
        //                                     // console.log(AuditorIDForDeleteUser)
        //                                     deleteUser(productData.OrganizationID);
        //                                     swal("Poof! Your  file has been deleted!", {
        //                                         icon: "success",
        //                                     })
        //                                     .then((willDelete)=>{
        //                                         if(willDelete){
        //                                             window.location.reload()
        //                                         }
        //                                     })
        //                                 } else {
        //                                     swal("Your  file is safe!");
        //                                 }
        //                             });

        //                         // tog_standard4();
        //                         // // setmodalPopupData(productData.UserCount)
        //                         // setOrganizaionID(productData.OrganizationID)
        //                         // setUserCount(productData.UserCount.length)
        //                         // setOrgName(productData.OrganizationName)
        //                     }}
        //                 >

        //                     Delete

        //                 </Button>
        //             </AvForm>
        //         </>
        //     ),
        // },
        // {
        //     dataField: "More",
        //     text: "View Details",
        //     sort: true,
        //     formatter: (cellContent, productData) => (

        //         <>
        //             <AvForm>
        //                 {/* {console.log("without cliecekd", productData.Domains)} */}
        //                 <Link to={`/auditor/auditee/users/${productData.OrganizationID}`} className="text-white">
        //                     <Button
        //                         className="btn-sm"
        //                         color="primary"
        //                     // onClick={() => {
        //                     //     // console.log("cliecekd", [productData]);
        //                     //     tog_standard4();
        //                     //     setmodalPopupData(productData.UserCount)
        //                     // }}
        //                     >

        //                         Users

        //                     </Button>
        //                 </Link>
        //             </AvForm>
        //         </>
        //     ),
        // },
        // {
        //     dataField: "More",
        //     text: "View Details",
        //     sort: true,
        //     formatter: (cellContent, productData) => (

        //         <>
        //             <AvForm>
        //                 {/* {console.log("without cliecekd", productData.Domains)} */}
        //                 <Link to={`/auditor/auditee/users/${productData.OrganizationID}`} className="text-white">
        //                     <Button
        //                         className="btn-sm"
        //                         color="primary"
        //                     // onClick={() => {
        //                     //     // console.log("cliecekd", [productData]);
        //                     //     tog_standard4();
        //                     //     setmodalPopupData(productData.UserCount)
        //                     // }}
        //                     >

        //                         Users

        //                     </Button>
        //                 </Link>
        //             </AvForm>
        //         </>
        //     ),
        // },
    ];
    // Sorting of Data Table
    const defaultSorted = [
        {
            dataField: "id",
            order: "asc",
        },
    ];

    // global error popup 
    const errorPopup = (title) => {
        // swal({
        //     title: title,
        //     icon: "warning",
        //     buttons: true,
        //     dangerMode: true,
        // });
        swal("Error!", title, "warning");
    }
    // error checker 
    const checkAddProjectData = () => {
        if (companyName == "" || companyName == undefined) {
            errorPopup("Please Enter Company Name")
            return false;
        }
        if (companyLogo == "" || companyLogo == undefined) {
            errorPopup("Please insert company logo")
            return false;
        }
        if (status_empSize == "" || status_empSize == undefined) {
            errorPopup("Please Enter Emp. Size ")
            return false;
        }
        if (organizationSector == "" || organizationSector == undefined) {

            errorPopup("Please select organizationSector")
            return false;
        }
        if (industry == "" || industry == undefined) {
            errorPopup("Please Enter industry")
            return false;
        }
        if (companyEmail == "" || companyEmail == undefined) {
            errorPopup("Please Enter companyEmail")
            return false;
        }
        if (companyPhone == "" || companyPhone == undefined) {
            errorPopup("Please Enter company phone")
            return false;
        }
        if (companyWebsite == "" || companyWebsite == undefined) {
            errorPopup("Please select company website")
            return false;
        }
        if (street == "" || street == undefined) {
            errorPopup("Please select street")
            return false;
        }
        if (city == "" || city == undefined) {
            errorPopup("Please select city")
            return false;
        }
        if (state == "" || state == undefined) {
            errorPopup("Please select state")
            return false;
        }
        if (pincode == "" || pincode == undefined) {
            errorPopup("Please select pincode")
            return false;
        }

        if (statusCountry == "" || statusCountry == undefined) {
            errorPopup("Please select statusCountry")
            return false;
        } if (companyStatus == "" || companyStatus == undefined) {
            errorPopup("Please select companyStatus")
            return false;
        } if (otherDetails == "" || otherDetails == undefined) {
            errorPopup("Please select otherDetails")
            return false;
        }
        // if (scopeDescription == "" || scopeDescription == undefined) {
        //     errorPopup("Please Enter Project Title")
        //     return false;
        // }
        // if (userCheck == "" || userCheck == undefined) {
        //     errorPopup("Please Enter Project Title")
        //     return false;
        // }
        // if (defaultProjectStatus == "" || defaultProjectStatus == undefined) {
        //     errorPopup("Please Enter Project Title")
        //     return false;
        // }
        // if (checklist_template_list_data == "" || checklist_template_list_data == undefined) {
        //     errorPopup("Please Enter Project Title")
        //     return false;
        // }
        else {
            addVulnerabilities()
        }
    }

    // Page Options in Data Table 
    const pageOptions = {
        sizePerPage: 10,
        totalSize: productData.length, // replace later with size(customers),
        custom: true,
    };
    const { SearchBar } = Search; // Search Bar in Data TAble

    var mainData = [];
    var name_regx = /^[A-Za-z ]{3,20}$/;

    useEffect(async () => {

        getUser();
        getCustomers();
    }, []);


    function tog_standard4() {
        setmodal_standard4(!modal_standard4);
        // toggle for view more button 
    }
    function tog_standard_add_auditee() {
        setmodal_add_auditee(!modal_add_auditee);
        // toggle for view more button 
    }
    function toggle_view_more_modal() {
        setview_more_modal(!view_more_modal);
        // toggle for view more button 
    }


    async function addVulnerabilities() {
        var formData = new FormData();
        formData.append("companyName", companyName);
        formData.append("companyLogo", companyLogo[0], companyName);
        // formData.append("status_severity", status_severity);
        formData.append("empSize", status_empSize);
        formData.append("companyEmail", companyEmail);
        formData.append("companyPhone", companyPhone);
        formData.append("companyWebsite", companyWebsite);
        formData.append("city", city);
        formData.append("state", state);
        formData.append("pincode", pincode);
        formData.append("country", statusCountry);
        formData.append("companyStatus", companyStatus);
        formData.append("otherDetails", otherDetails);
        formData.append("organizationSector", organizationSector);
        formData.append("industry", industry);
        formData.append("auditorID", userCheck);
        formData.append("street", street);
        formData.append("userCheck", localStorage.getItem("AuditorID"));
        formData.append("orgID", localStorage.getItem("orgID"));
        // formData.append("status_vulnerability_status", status_vulnerability_status);
        // formData.append("vulnerabilityImg1", vulnerabilityImg1[0], "vulnerability_status_img.png");
        // console.log(companyName, companyLogo[0], companyPhone, companyWebsite, city, state, pincode, statusCountry, companyStatus, otherDetails)


        try {
            // const response = await fetch('http://localhost:1338/add-auditee', {
            //     method : "POST",
            //     headers : {
            //         "content-Type" : "application/json"
            //     },
            //     body:formData
            // })
            await axios.post(`${process.env.REACT_APP_DEFAULTPATH}add-auditee-organization`, formData, {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                }
            }).
                then(() => {
                    getCustomers()
                    tog_standard_add_auditee()
                    // window.location.reload();
                });

        } catch (error) {
            console.log(error)
        }
    }

    async function editRow() {
        var formData = new FormData();
        formData.append("organizaionID", OrganizaionID);
        formData.append("companyName", companyName);
        if (companyLogo) {
            formData.append("companyLogo", companyLogo[0], "companyLogo.png");
        }
        // formData.append("status_severity", status_severity);
        formData.append("empSize", status_empSize);
        formData.append("companyEmail", companyEmail);
        formData.append("companyPhone", companyPhone);
        formData.append("companyWebsite", companyWebsite);
        formData.append("city", city);
        formData.append("state", state);
        formData.append("pincode", pincode);
        formData.append("country", statusCountry);
        formData.append("companyStatus", companyStatus);
        formData.append("otherDetails", otherDetails);
        formData.append("organizationSector", organizationSector);
        formData.append("industry", industry);
        formData.append("loginUser", userCheck);
        formData.append("userCheck", localStorage.getItem("AuditorID"));
        formData.append("orgID", localStorage.getItem("orgID"));
        // formData.append("status_vulnerability_status", status_vulnerability_status);
        // formData.append("vulnerabilityImg1", vulnerabilityImg1[0], "vulnerability_status_img.png");
        // console.log(companyName, companyLogo[0], companyPhone, companyWebsite, city, state, pincode, statusCountry, companyStatus, otherDetails)


        try {
            // const response = await fetch('http://localhost:1338/add-auditee', {
            //     method : "POST",
            //     headers : {
            //         "content-Type" : "application/json"
            //     },
            //     body:formData
            // })
            await axios.post(`${process.env.REACT_APP_DEFAULTPATH}edit-auditee-organization`, formData, {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                }
            }).
                then(() => {
                    getCustomers()
                    tog_standard4()
                    // window.location.reload();
                });

        } catch (error) {
            console.log(error)
        }
    }

    async function deleteUser(ID) {
        // console.log(  "ID" , ID  )
        const response = await fetch(`${process.env.REACT_APP_DEFAULTPATH}delete-auditor-auditee-org`, {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            },
            body: JSON.stringify({
                ID,
                userCheck: localStorage.getItem("AuditorID"),
                orgID: localStorage.getItem("orgID")
            })
        })
        // const data = await response.json();
        // console.log("responsed", data)
    }
    const [role, setRole] = useState()
    // to get userLSit 
    async function getUser(event) {
        // event.preventDefault();
        // console.log("token passes " , userCheck)
        const response = await fetch(`${process.env.REACT_APP_DEFAULTPATH}user-profile`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            },
            body: JSON.stringify({
                userCheck: localStorage.getItem("AuditorID"),
                orgID: localStorage.getItem("orgID")
            }),
        });
        const data = await response.json();
        if (data) {
            const newdata = data.user;
            console.log("data retrived", newdata);
            setRole(newdata.Role)

            // setphone(newdata.Phone);
            // setjobTitle(newdata.JobTitle);
            // console.log("main profile", newdata.FirstName);
        } else {
            alert("data retreival error");
        }
    }
    // ApI to hit Task Data 
    async function getCustomers(event) {
        const response = await fetch(
            `${process.env.REACT_APP_DEFAULTPATH}get-auditee-org-list`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                },
                body: JSON.stringify({
                    userCheck: localStorage.getItem("AuditorID"),
                    orgID: localStorage.getItem("orgID")
                }),
            }
        );
        const data = await response.json();
        if (data) {
            console.log("response-", data);
            // mainData = await data.data[0].data.entries;
            mainData = await data;
            setProductData(mainData.data);
            setauditeeListPreloader(false)

            setOrgNum(mainData.data.length)
            // console.log(mainData)
            var ACtiveCounter = 0;
            var InactiveCounter = 0
            for (var i = 0; i < mainData.data.length; i++) {
                if (mainData.data[i].Status === "Active") {
                    ACtiveCounter += 1;
                }
                else {
                    InactiveCounter += 1;
                }
            }
            setActiveOrg(ACtiveCounter);
            setInActiveOrg(InactiveCounter)
            // console.log("tempData", TempData)
            // setProductData(TempData);
            // setIpCardPrice(TempData[1].Value.length)
            // setdomainCard(TempData[0].Value.length)
            // setSubdomainCardPrice(TempData[2].Value.length)
        } else {
            // alert("data retreival error");
        }
    }

    // function to send data to backend 
    async function updateRow(event) {
        const response = await fetch(
            `${process.env.REACT_APP_DEFAULTPATH}/auditee-list`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    OrgID: OrganizaionID,
                    OrgName,
                    status
                }),
            }
        );
        const data = await response.json();
        if (data.status) {
        }
        else {
            setemailError("Email already exist")
        }

    }


    // Swwet alert or modal to confirm detials  should modify for perosnal Details 
    function updatePersonalDetails_sweetAlert() {
        // console.log("selected GRoup", status)
        swal({
            title: "Are you sure?",
            text: "Once done, you will update your personal details!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    if (updateRow()) {
                        swal("Poof! Your perosnal details has been updated!", {
                            icon: "success",
                            buttons: true
                        }).then((reload) => {
                            if (reload) {
                                window.location.reload(false);
                            }
                        })
                    }
                    else {
                        swal("Reload operation has been aborted!");
                    }
                } else {
                    swal("Operation has been aborted!");
                }
            });
    }

    // functions to validate regex OrgName
    function checkOrgNameValidation(OrgName_val) {
        if (OrgName_val === " ") {
            setOrgNameError("enter first name")
            setdetailBtn(true)
        }
        else if (name_regx.test(OrgName_val)) {
            setOrgNameError("")
            setdetailBtn(false)
        }
        else if (OrgName_val.length < 3) {
            setOrgNameError(" must contain 3 characters")
            setdetailBtn(true)
        }
        else {
            setOrgNameError(`Organization doesn't contain "0-9  , & @ "`)
            setdetailBtn(true)
        }
    }

    // function to handle change in status dropdown
    function handleSelectOptions(selectedOptions) {
        setselectedOptions(selectedOptions)
        setdetailBtn(false)
    }

    return (
        <React.Fragment>
            {auditeeListPreloader ?
                <div className="transparent-loader">
                    <Spinner type="grow" className="ms-2" color="primary" /> </div> : <></>}
            <div className="userProfile credentialBreachProfile">
                <MetaTags>
                    <title>Auditee | AuditSense</title>
                </MetaTags>
                <Row>
                    <Breadcrumbs
                        title="Dashboard"
                        breadcrumbItem="Auditee Organization List"
                        breadcrumbFeature="Auditees"
                    />
                </Row>


                {(() => {
                    if (role === "Team Leader" || role === "Project Manager" || role === "Admin") {
                        return (
                            <div className="text-end">
                                <Button className="btn-success" onClick={() => {
                                    // console.log("asdas")
                                    setotherDetails("")
                                    setstate("")
                                    setcompanyEmail("")
                                    setcompanyLogo("")
                                    setcompanyPhone("")
                                    setcompanyName("")
                                    setcompanyWebsite("")
                                    setcompanyStatus("")
                                    setstatusCountry("")
                                    setpincode("")
                                    setcity("")
                                    setStreet("")
                                    setindustry("")
                                    setorganizationSector("")
                                    setstatus_empSize("")
                                    setcompanyLogo()
                                    tog_standard_add_auditee()
                                }}><i className="bx bx-buildings"></i> &nbsp; Add Auditee Organizaion</Button>
                            </div>
                        )
                    }
                })()}

                <Row>

                    {/* Modal to edit row  */}
                    <Modal
                        isOpen={modal_standard4}
                        toggle={() => {
                            tog_standard4();
                        }}
                        className="modal-xl"
                    >
                        <div className="modal-header">
                            <h5 className="modal-title mt-0" id="myModalLabel">
                                Edit Auditee Organizaion Details
                            </h5>
                            <button
                                type="button"
                                onClick={() => {
                                    setmodal_standard4(false);
                                }}
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div className="modal-body">

                            <div className="d-flex flex-column h-100">

                                <div className='webApplication'>
                                    <Row>
                                        <Col lg={12}>

                                            <Row>
                                                <Col lg={12}>
                                                    <div>
                                                        {/* <h5 className="font-size-14 mb-4"><i className="mdi mdi-arrow-right text-primary me-1"></i> Form groups</h5> */}
                                                        <Form >

                                                            <Row className='my-2'>
                                                                <Col md={12}>
                                                                    <div className="mb-3">
                                                                        <Label className="form-label" htmlFor="companyName">Company Name: </Label>
                                                                        <Input type="text" className="form-control" id="companyName"
                                                                            value={companyName}
                                                                            onChange={(e) => {
                                                                                // console.log(e.target.value);
                                                                                setcompanyName(e.target.value);
                                                                            }} />
                                                                    </div>
                                                                </Col>

                                                            </Row>

                                                            <Row className='my-2'>
                                                                <Col md={6}>
                                                                    <div className="mb-3">
                                                                        <Label className="form-label" htmlFor="companyLogo">Company Logo : </Label>
                                                                        <Input type="file" className="form-control" id="companyLogo"
                                                                            // value={organizationlogo}
                                                                            placeholder={organizationlogo}
                                                                            onChange={(e) => {
                                                                                // console.log(e.target.value);
                                                                                setcompanyLogo(e.target.files);
                                                                            }} />
                                                                        <small style={{ color: 'red', padding: "4px 0 0 2px" }}>Image Size : 200x200px</small>
                                                                    </div>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <div className="mb-3">
                                                                        <Label className="form-label" htmlFor="employeeSize">Employee Size :  </Label>
                                                                        <Select
                                                                            placeholder={status_empSize}
                                                                            onChange={(e) => {
                                                                                // console.log(e.value)
                                                                                setstatus_empSize(e.value);
                                                                                // handleSelectOptions_empSize(e.value);
                                                                                // console.log(selectedOptions_empSize)
                                                                            }}
                                                                            options={optionGroup_empSize}
                                                                            classNamePrefix="select2-selection"
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row className='my-2'>
                                                                <Col md={6}>
                                                                    <div className="mb-3">
                                                                        <Label className="form-label" htmlFor="companyName">Organization Sector: </Label>
                                                                        <Select
                                                                            placeholder={organizationSector}
                                                                            // value={empSize}
                                                                            onChange={(e) => {
                                                                                // console.log(e.value)
                                                                                setorganizationSector(e.value);
                                                                                // handleSelectOptions_empSize(e.value);
                                                                                // console.log(selectedOptions_empSize)
                                                                            }}
                                                                            options={optionGroup_OrganizationSector}
                                                                            classNamePrefix="select2-selection"
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <div className="mb-3">
                                                                        <Label className="form-label" htmlFor="companyName">Industry :  </Label>
                                                                        <Select
                                                                            placeholder={industry}
                                                                            // value={empSize}
                                                                            onChange={(e) => {
                                                                                // console.log(e.value)
                                                                                setindustry(e.value);
                                                                                // handleSelectOptions_empSize(e.value);
                                                                                // console.log(selectedOptions_empSize)
                                                                            }}
                                                                            options={optionGroup_industry}
                                                                            classNamePrefix="select2-selection"
                                                                        />
                                                                    </div>
                                                                </Col>

                                                            </Row>

                                                            <Row className='my-2'>

                                                                <Col md={4}>
                                                                    <div className="mb-3">
                                                                        <Label className="form-label" htmlFor="companyEmail">Company Email : </Label>
                                                                        <Input type="text" className="form-control"
                                                                            value={companyEmail}
                                                                            id="companyEmail"
                                                                            onChange={(e) => {
                                                                                // console.log(e.target.value);
                                                                                setcompanyEmail(e.target.value);
                                                                            }} />
                                                                    </div>
                                                                </Col>

                                                                <Col md={4}>
                                                                    <div className="mb-3">
                                                                        <Label className="form-label" htmlFor="companyPhone">Company Phone : </Label>
                                                                        <Input type="phone" className="form-control" id="companyPhone"
                                                                            value={companyPhone}
                                                                            onChange={(e) => {
                                                                                // console.log(e.target.value);
                                                                                setcompanyPhone(e.target.value);
                                                                            }} />
                                                                    </div>
                                                                </Col>
                                                                <Col md={4}>
                                                                    <div className="mb-3">
                                                                        <Label className="form-label" htmlFor="companyWebsite">Company Website : </Label>
                                                                        <Input type="url" className="form-control"
                                                                            id="companyWebsite"
                                                                            value={companyWebsite}
                                                                            onChange={(e) => {
                                                                                // console.log(e.target.value);
                                                                                setcompanyWebsite(e.target.value);
                                                                            }} />
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            <Row className='my-2'>

                                                                <Col md={6}>
                                                                    <div className="mb-3">
                                                                        <Label className="form-label" htmlFor="city">City : </Label>
                                                                        <Input type="text"
                                                                            value={city}
                                                                            className="form-control" id="city"
                                                                            onChange={(e) => {
                                                                                // console.log(e.target.value);
                                                                                setcity(e.target.value);
                                                                            }} />
                                                                    </div>
                                                                </Col>

                                                                <Col md={6}>
                                                                    <div className="mb-3">
                                                                        <Label className="form-label" htmlFor="state">State : </Label>
                                                                        <Input type="text" className="form-control" id="state"
                                                                            value={state}
                                                                            onChange={(e) => {
                                                                                // console.log(e.target.value);
                                                                                setstate(e.target.value);
                                                                            }} />
                                                                    </div>
                                                                </Col>
                                                            </Row>




                                                            <Row className='my-2'>

                                                                <Col md={4}>
                                                                    <div className="mb-3">
                                                                        <Label className="form-label" htmlFor="pincode">Pincode : </Label>
                                                                        <Input type="text" className="form-control" id="pincode"
                                                                            value={pincode}
                                                                            onChange={(e) => {
                                                                                // console.log(e.target.value);
                                                                                setpincode(e.target.value);
                                                                            }} />
                                                                    </div>
                                                                </Col>

                                                                <Col md={4}>
                                                                    <div className="mb-3">
                                                                        <Label className="form-label" htmlFor="country">Country : </Label>
                                                                        <Select
                                                                            // value={statusCountry}
                                                                            placeholder={statusCountry}
                                                                            onChange={(e) => {
                                                                                // console.log(e.value)
                                                                                setstatusCountry(e.value);
                                                                                // handleSelectOptions_empSize(e.value);
                                                                                // console.log(selectedOptions_empSize)
                                                                            }}
                                                                            options={assignCountry()}
                                                                            classNamePrefix="select2-selection"
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                <Col md={4}>
                                                                    <div className="mb-3">
                                                                        <Label className="form-label" htmlFor="status">Status : </Label>
                                                                        <Select
                                                                            // value={statusCountry}
                                                                            placeholder={companyStatus}
                                                                            onChange={(e) => {
                                                                                // console.log(e.value)
                                                                                setcompanyStatus(e.value);
                                                                                // handleSelectOptions_empSize(e.value);
                                                                                // console.log(selectedOptions_empSize)
                                                                            }}
                                                                            options={optionGroup_CompanyStatus}
                                                                            classNamePrefix="select2-selection"
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>





                                                            <Row className='my-3'>
                                                                <Label className="form-label" htmlFor="otherDetails">Other Details :</Label>


                                                                <div className="mb-3" md={6}>
                                                                    <CKEditor
                                                                        editor={ClassicEditor}
                                                                        data={otherDetails}
                                                                        // onReady={editor => {
                                                                        //     // You can store the "editor" and use when it is needed.
                                                                        //     console.log('Editor is ready to use!', editor);
                                                                        // }}
                                                                        onChange={(event, editor) => {
                                                                            const data = editor.getData();
                                                                            // console.log({ event, editor, data });
                                                                            // console.log(data);
                                                                            setotherDetails(data)
                                                                            // console.log(event.target.data)
                                                                        }}
                                                                    // onBlur={(event, editor) => {
                                                                    //     console.log('Blur.', editor);
                                                                    // }}
                                                                    // onFocus={(event, editor) => {
                                                                    //     console.log('Focus.', editor);
                                                                    // }}
                                                                    />
                                                                </div>

                                                                {/* <CustomToolbar /> */}
                                                                {/* <Editor/> */}

                                                            </Row>

                                                        </Form>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                onClick={() => {
                                    tog_standard4();
                                    setmodal_standard4(false);
                                }}
                                className="btn btn-warning "
                                data-dismiss="modal"
                            >
                                Close
                            </button>
                            <button
                                type="button"
                                onClick={() => {
                                    // updatePersonalDetails_sweetAlert()
                                    editRow()
                                }}
                                // disabled={detailBtn}
                                className="btn btn-warning "
                                data-dismiss="modal"
                            >
                                Save
                            </button>
                        </div>
                    </Modal>

                    {/* Date Table  */}
                    <Row className="content">
                        <Col
                            lg={4}
                            md={5}
                            className="col-xxl-9 userData credentialData"
                        >
                            <div className="auth-full-page-content d-flex p-sm-5 p-4">
                                <div className="w-100">
                                    <div className="d-flex flex-column">
                                        <div className="  text-center">
                                            <Link
                                                to="/dashboard"
                                                className="d-block auth-logo"
                                            >
                                            </Link>
                                        </div>
                                        <div className="auth-content my-auto">

                                            <div className="mainTable">
                                                <CardBody>
                                                    <PaginationProvider
                                                        pagination={paginationFactory(
                                                            pageOptions
                                                        )}
                                                    >
                                                        {({
                                                            paginationProps,
                                                            paginationTableProps,
                                                        }) => (
                                                            <ToolkitProvider
                                                                keyField="id"
                                                                columns={columns}
                                                                data={productData}
                                                                search
                                                            >
                                                                {(toolkitProps) => (
                                                                    <React.Fragment>
                                                                        <Row className="mb-2">
                                                                            <Col>
                                                                                <div className="search-box me-2 mb-2 d-flex justify-content-between">
                                                                                    <div className="d-inline">
                                                                                        <SizePerPageDropdownStandalone
                                                                                            {...paginationProps}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="position-relative">
                                                                                        <SearchBar
                                                                                            {...toolkitProps.searchProps}
                                                                                        />
                                                                                        <i className="bx bx-search-alt search-icon" />
                                                                                    </div>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>

                                                                        <Row>
                                                                            <Col xl="12">
                                                                                <div className="table-responsive">
                                                                                    <BootstrapTable
                                                                                        keyField={"id"}
                                                                                        responsive
                                                                                        bordered={false}
                                                                                        striped={false}
                                                                                        defaultSorted={
                                                                                            defaultSorted
                                                                                        }
                                                                                        classes={
                                                                                            "table align-middle table-nowrap"
                                                                                        }
                                                                                        headerWrapperClasses={
                                                                                            "thead-light"
                                                                                        }
                                                                                        {...toolkitProps.baseProps}
                                                                                        {...paginationTableProps}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                        </Row>

                                                                        <Row className="align-items-md-center mt-30">
                                                                            <Col className="pagination pagination-rounded justify-content-end mb-2">
                                                                                <PaginationListStandalone
                                                                                    {...paginationProps}
                                                                                />
                                                                            </Col>
                                                                        </Row>

                                                                    </React.Fragment>
                                                                )}
                                                            </ToolkitProvider>
                                                        )}
                                                    </PaginationProvider>
                                                </CardBody>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Row>


                <Row>

                    {/* MODal to Add organozation details */}
                    <Modal
                        isOpen={modal_add_auditee}
                        className="modal-dialog-centered modal-xl"
                        toggle={() => {
                            tog_standard_add_auditee();
                        }}
                    // id="myExtraLargeModalLabel"
                    >
                        <div className="modal-header">
                            <h5 className="modal-title mt-0" id="myModalLabel">
                                Add Auditee Organization
                            </h5>
                            <button
                                type="button"
                                onClick={() => {
                                    setmodal_add_auditee(false);
                                }}
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">

                            <Form >

                                <Row className='my-2'>
                                    <Col md={12}>
                                        <div className="mb-3">
                                            <Label className="form-label" htmlFor="companyName">Company Name: </Label>
                                            <Input type="text" className="form-control" id="companyName"
                                                onChange={(e) => {
                                                    // console.log(e.target.value);
                                                    setcompanyName(e.target.value);
                                                }} />
                                        </div>
                                    </Col>

                                </Row>

                                <Row className='my-2'>
                                    <Col md={6}>
                                        <div className="mb-3">
                                            <Label className="form-label" htmlFor="companyLogo">Company Logo : </Label>
                                            <Input type="file" className="form-control" id="companyLogo"
                                                onChange={(e) => {
                                                    // console.log(e.target.value);
                                                    setcompanyLogo(e.target.files);
                                                }} />
                                            <small style={{ color: 'red', padding: "4px 0 0 2px" }}>Image Size : 200x200px</small>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="mb-3">
                                            <Label className="form-label" htmlFor="employeeSize">Employee Size :  </Label>
                                            <Select
                                                // value={empSize}
                                                onChange={(e) => {
                                                    // console.log(e.value)
                                                    setstatus_empSize(e.value);
                                                    // handleSelectOptions_empSize(e.value);
                                                    // console.log(selectedOptions_empSize)
                                                }}
                                                options={optionGroup_empSize}
                                                classNamePrefix="select2-selection"
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='my-2'>
                                    <Col md={6}>
                                        <div className="mb-3">
                                            <Label className="form-label" htmlFor="companyName">Organization Sector: </Label>
                                            <Select
                                                // value={empSize}
                                                onChange={(e) => {
                                                    // console.log(e.value)
                                                    setorganizationSector(e.value);
                                                    // handleSelectOptions_empSize(e.value);
                                                    // console.log(selectedOptions_empSize)
                                                }}
                                                options={optionGroup_OrganizationSector}
                                                classNamePrefix="select2-selection"
                                            />
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="mb-3">
                                            <Label className="form-label" htmlFor="companyName">Industry :  </Label>
                                            <Select
                                                // value={empSize}
                                                onChange={(e) => {
                                                    // console.log(e.value)
                                                    setindustry(e.value);
                                                    // handleSelectOptions_empSize(e.value);
                                                    // console.log(selectedOptions_empSize)
                                                }}
                                                options={optionGroup_industry}
                                                classNamePrefix="select2-selection"
                                            />
                                        </div>

                                    </Col>

                                </Row>

                                <Row className='my-2'>

                                    <Col md={4}>
                                        <div className="mb-3">
                                            <Label className="form-label" htmlFor="companyEmail">Company Email : </Label>
                                            <Input type="text" className="form-control" id="companyEmail"
                                                onChange={(e) => {
                                                    // console.log(e.target.value);
                                                    setcompanyEmail(e.target.value);
                                                }} />
                                        </div>
                                    </Col>

                                    <Col md={4}>
                                        <div className="mb-3">
                                            <Label className="form-label" htmlFor="companyPhone">Company Phone : </Label>
                                            <Input type="phone" className="form-control" id="companyPhone"
                                                onChange={(e) => {
                                                    // console.log(e.target.value);
                                                    setcompanyPhone(e.target.value);
                                                }} />
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className="mb-3">
                                            <Label className="form-label" htmlFor="companyWebsite">Company Website : </Label>
                                            <Input type="url" className="form-control" id="companyWebsite"
                                                onChange={(e) => {
                                                    // console.log(e.target.value);
                                                    setcompanyWebsite(e.target.value);
                                                }} />
                                        </div>
                                    </Col>
                                </Row>

                                <Row className='my-2'>

                                    <Col md={4}>
                                        <div className="mb-3">
                                            <Label className="form-label" htmlFor="street">Street : </Label>
                                            <Input type="text" className="form-control" id="street"
                                                onChange={(e) => {
                                                    // console.log(e.target.value);
                                                    setStreet(e.target.value);
                                                }} />
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className="mb-3">
                                            <Label className="form-label" htmlFor="city">City : </Label>
                                            <Input type="text" className="form-control" id="city"
                                                onChange={(e) => {
                                                    // console.log(e.target.value);
                                                    setcity(e.target.value);
                                                }} />
                                        </div>
                                    </Col>

                                    {/* <Col md={4}>
                                        <div className="mb-3">
                                            <Label className="form-label" htmlFor="state">State : </Label>
                                            <Input type="text" className="form-control" id="state"
                                                onChange={(e) => {
                                                    // console.log(e.target.value);
                                                    setstate(e.target.value);

                                                }} />
                                        </div>
                                    </Col> */}
                                    <Col md={4}>
                                        <div className="mb-3">
                                            <Label className="form-label" htmlFor="state">State : </Label>
                                            <Select
                                                // value={statusCountry}
                                                onChange={(e) => {
                                                    // console.log(e.value)
                                                    setstate(e.value);
                                                    // handleSelectOptions_empSize(e.value);
                                                    // console.log(selectedOptions_empSize)
                                                }}
                                                options={selectState}
                                                classNamePrefix="select2-selection"
                                            />
                                        </div>
                                    </Col>
                                </Row>




                                <Row className='my-2'>

                                    <Col md={4}>
                                        <div className="mb-3">
                                            <Label className="form-label" htmlFor="pincode">Pincode : </Label>
                                            <Input type="text" className="form-control" id="pincode"
                                                onChange={(e) => {
                                                    // console.log(e.target.value);
                                                    setpincode(e.target.value);
                                                }} />
                                        </div>
                                    </Col>

                                    <Col md={4}>
                                        <div className="mb-3">
                                            <Label className="form-label" htmlFor="country">Country : </Label>
                                            <Select
                                                // value={statusCountry}
                                                onChange={(e) => {
                                                    // console.log(e.value)
                                                    setstatusCountry(e.value);
                                                    assignState(e.value)
                                                    // handleSelectOptions_empSize(e.value);
                                                    // console.log(selectedOptions_empSize)
                                                }}
                                                options={assignCountry()}
                                                classNamePrefix="select2-selection"
                                            />
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className="mb-3">
                                            <Label className="form-label" htmlFor="status">Status : </Label>
                                            <Select
                                                // value={statusCountry}
                                                onChange={(e) => {
                                                    // console.log(e.value)
                                                    setcompanyStatus(e.value);
                                                    // handleSelectOptions_empSize(e.value);
                                                    // console.log(selectedOptions_empSize)
                                                }}
                                                options={optionGroup_CompanyStatus}
                                                classNamePrefix="select2-selection"
                                            />
                                        </div>
                                    </Col>
                                </Row>





                                <Row className='my-3'>
                                    <Label className="form-label" htmlFor="otherDetails">Other Details :</Label>


                                    <div className="mb-3" md={6}>
                                        <CKEditor
                                            editor={ClassicEditor}
                                            data={otherDetails}
                                            // onReady={editor => {
                                            //     // You can store the "editor" and use when it is needed.
                                            //     console.log('Editor is ready to use!', editor);
                                            // }}
                                            onChange={(event, editor) => {
                                                const data = editor.getData();
                                                // console.log({ event, editor, data });
                                                // console.log(data);
                                                setotherDetails(data)
                                                // console.log(event.target.data)
                                            }}
                                        // onBlur={(event, editor) => {
                                        //     console.log('Blur.', editor);
                                        // }}
                                        // onFocus={(event, editor) => {
                                        //     console.log('Focus.', editor);
                                        // }}
                                        />
                                    </div>

                                    {/* <CustomToolbar /> */}
                                    {/* <Editor/> */}

                                </Row>

                            </Form>

                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                onClick={() => {
                                    tog_standard_add_auditee();
                                    setmodal_add_auditee(false);
                                }}
                                className="btn btn-secondary "
                                data-dismiss="modal"
                            >
                                Close
                            </button>
                            <button
                                type="button"
                                onClick={() => {
                                    checkAddProjectData()
                                    // updatePersonalDetails_sweetAlert()
                                }}
                                className="btn btn-primary "
                            // data-dismiss="modal"
                            >
                                Submit
                            </button>
                        </div>
                    </Modal>


                    {/* MODal to view organozation details */}
                    <Modal
                        isOpen={view_more_modal}
                        toggle={() => {
                            toggle_view_more_modal();
                        }}
                        className="modal-xl"
                    >
                        <div className="modal-header">
                            <h5 className="modal-title mt-0" id="myModalLabel">
                                View Organizaion Details
                            </h5>
                            <button
                                type="button"
                                onClick={() => {
                                    setview_more_modal(false);
                                }}
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div className="modal-body">

                            <div className="d-flex flex-column h-100">

                                <div className='webApplication'>
                                    <Form >
                                        <Row className='my-2'>
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="employeeSize">Organization Name *  </Label>
                                                    <div className="text-secondary">{OrgName}</div>
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="employeeSize">OrganizaionID *  </Label>
                                                    <div className="text-secondary">{OrganizaionID}</div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className='my-2'>

                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="employeeSize">Organization Sector *  </Label>
                                                    <div className="text-secondary">{organizationSector}</div>
                                                </div>
                                            </Col>

                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="employeeSize"> Industry *  </Label>
                                                    <div className="text-secondary">{industry}</div>
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row className='my-2'>

                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="employeeSize">Employee Size *  </Label>
                                                    <div className="text-secondary">{status_empSize}</div>
                                                </div>
                                            </Col>

                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="employeeSize"> Email *  </Label>
                                                    <div className="text-secondary">{companyEmail}</div>
                                                </div>
                                            </Col>
                                        </Row>


                                        <Row className='my-3'>

                                            <Col md={4}>
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="employeeSize">Pincode *  </Label>
                                                    <div className="text-secondary">{pincode}</div>
                                                </div>
                                            </Col>
                                            <Col md={4}>
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="employeeSize">Phone *  </Label>
                                                    <div className="text-secondary">{companyPhone}</div>
                                                </div>
                                            </Col>
                                            <Col md={4}>
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="employeeSize">State *  </Label>
                                                    <div className="text-secondary">{state}</div>
                                                </div>
                                            </Col>
                                        </Row>


                                        <Row className='my-2'>

                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="employeeSize">Country*  </Label>
                                                    <div className="text-secondary">{country}</div>
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="companyLogo">Status * </Label>
                                                    <div>  {(() => {

                                                        if (companyStatus === null) {
                                                            return <>-</>;
                                                        } else if (companyStatus === "Enable") {
                                                            return <> <Badge pill className="badge-soft-success ms-1  fs-6">{companyStatus}</Badge></>;
                                                        } else if (companyStatus === "Disable") {
                                                            return <> <Badge pill className="badge-soft-danger ms-1  fs-6">{companyStatus}</Badge>

                                                            </>;
                                                        } else if (companyStatus === "pending") {
                                                            return <> <Badge pill className="badge-soft-warning ms-1  fs-6">{companyStatus}</Badge>

                                                            </>;
                                                        }
                                                    })()}</div>
                                                </div>
                                            </Col>
                                        </Row>

                                    </Form>
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                onClick={() => {
                                    toggle_view_more_modal();
                                    setview_more_modal(false);
                                }}
                                className="btn btn-warning "
                                data-dismiss="modal"
                            >
                                Close
                            </button>
                            {/* <button
                                type="button"
                                onClick={() => {
                                    // updatePersonalDetails_sweetAlert()
                                    editChecklistTemplate()
                                }}
                                // disabled={detailBtn}
                                className="btn btn-warning "
                                data-dismiss="modal"
                            >
                                Save
                            </button> */}
                        </div>
                    </Modal>
                </Row>
            </div>
        </React.Fragment>
    );
};

export default Auditee;
