import React, { useState, useEffect } from "react";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import CountUp from "react-countup";


import FeatherIcon from "feather-icons-react";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// import "react-table/react-table.css";
import {
    Spinner,
    Badge,
    CardBody,
    Col,
    Modal,
    Row,
    Card,
    Input,
    Button,
    Form, Label, CardHeader,
    CardTitle,
    CardSubtitle,
} from "reactstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import Select from "react-select"
import { AvForm, AvField } from "availity-reactstrap-validation";

import MetaTags from "react-meta-tags";
import swal from 'sweetalert';
import axios from "axios"

//import images
import Breadcrumbs from "../../../components/Common/Breadcrumb";


import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
const CheckList = () => {

    // const location = useLocation();
    // const history = useHistory()
    // console.log("location", history.location);
    const [checkListPreloader, setcheckListPreloader] = useState(true)
    const [productData, setProductData] = useState([]);
    const [modal_standard4, setmodal_standard4] = useState(false); // View More button Modal of ExposedCredentials


    const [modal_add_auditee, setmodal_add_auditee] = useState(false); // View More button Modal of ExposedCredentials
    const [modal_add_checklist, setmodal_add_checklist] = useState(false); // View More button Modal of ExposedCredentials

    const [orgNum, setOrgNum] = useState(0);
    const [activeOrg, setActiveOrg] = useState(0);
    const [InActiveOrg, setInActiveOrg] = useState(0);


    // values stored of form
    const [OrgName, setOrgName] = useState("");
    const [status, setstatus] = useState("");

    // validation state error 
    const [emailError, setemailError] = useState("");
    const [OrgNameError, setOrgNameError] = useState("");
    const [OrganizaionID, setOrganizaionID] = useState("");
    const [UserCount, setUserCount] = useState("");
    const [formOrganizationID, setformOrganizationID] = useState(""); // used to send userID of row to backend
    const [detailBtn, setdetailBtn] = useState(true); // to disable the button on inital


    // function to get uniques ID
    function uniqueIdGenerator() {
        var seq = (Math.floor(Math.random() * 100) + 100).toString().substring(1);
        const id = Date.now() + seq;
        return id;
    }
    const userCheck = localStorage.getItem("AuditorID");

    const [reportID, setReportID] = useState(localStorage.getItem("reportId"));
    const [findvulnerability, setfindvulnerability] = useState();
    const [rating, setrating] = useState()
    const [vulnerabilityDescription, setvulnerabilityDescription] = useState();
    const [vulnerabilityImpact, setvulnerabilityImpact] = useState();
    const [vulnerabilityRemediation, setvulnerabilityRemediation] = useState();
    const [vulnerabilityAffectedURL, setvulnerabilityAffectedURL] = useState();
    // const [vulnerabilityAffectedURL , setvulnerabilityAffectedURL] =  useState();
    const [vulnerabilityImg1, setvulnerabilityImg1] = useState();
    const [vulnerabilityList, setVulnerabilityList] = useState();


    const [checkListName, setcheckListName] = useState();
    const [companyLogo, setcompanyLogo] = useState();
    // const [empSize, setempSize] = useState();
    const [country, setcountry] = useState();
    const [checkListYear, setcheckListYear] = useState();
    const [companyPhone, setcompanyPhone] = useState();
    const [companyWebsite, setcompanyWebsite] = useState();
    const [city, setcity] = useState();
    const [state, setstate] = useState();
    const [pincode, setpincode] = useState();
    const [otherDetails, setotherDetails] = useState();

    const [checkListVersion, setcheckListVersion] = useState("");
    const [checkList_Type, setcheckList_Type] = useState("");
    const [checkLIstStatus, setcheckLIstStatus] = useState("");
    const [companyStatus, setcompanyStatus] = useState();
    const [checkListDbId, setcheckListDbId] = useState("");

    const [status_vulnerability_status, setstatus_vulnerability_status] = useState("");
    const [selectedOptions_vulnerability_status, setselectedOptions_vulnerability_status] = useState(null);



    function year_list() {

        var yearList = new Date().getFullYear(); // 2020
        var year_arr = []
        let i = 0;
        while (i < 20) {
            year_arr[i] = { label: yearList, value: yearList }
            yearList = yearList - 1;
            i++;
        }
        // console.log(year_arr); // 2019
        return year_arr;
    }

    const optionGroup_checklIst_version = [
        { label: "v7.3 ", value: "v7.3 " },
        { label: "v3.2", value: "v3.2" },
    ];
    const optionGroup_checkListStatus = [
        { label: "Enabled", value: "Enabled" },
        { label: "Disabled", value: "Disabled" },
    ];
    const optionGroup_CheckList_Type = [
        { label: "Web Application Audit", value: "Web Application Audit" },
        { label: "Mobile Application Audit", value: "Mobile Application Audit" },
        { label: "API Audit", value: "API Audit" },
        { label: "Network Audit", value: "Network Audit" }
    ];

    // form validatioon checkuo end ------------->>>>>>>>>>>>>>>>>>>>>>>>>>

    // function to handle change in status dropdown
    //  function handleSelectOptions_empSize(selectedOptions_empSize) {
    //     setselectedOptions_empSize(selectedOptions_empSize);
    // }

    // function to handle change in status dropdown
    function handleSelectOptions_severity(selectedOptions_severity) {
        // setselectedOptions_severity(selectedOptions_severity);
    }
    // function to handle change in status dropdown
    function handleSelectOptions_vulnerability_status(selectedOptions_vulnerability_status) {
        setselectedOptions_vulnerability_status(selectedOptions_vulnerability_status);
    }


    async function addVulnerabilities() {
        var formData = new FormData();
        formData.append("checkListName", checkListName);
        // formData.append("status_severity", status_severity);
        formData.append("checkListVersion", checkListVersion);
        formData.append("checkListYear", checkListYear);
        formData.append("checkLIstStatus", checkLIstStatus);
        formData.append("checkLIstType", checkList_Type);
        formData.append("auditorID", userCheck);
        formData.append("userCheck", localStorage.getItem("AuditorID"))
        formData.append("orgID", localStorage.getItem("orgID"))
        // formData.append("status_vulnerability_status", status_vulnerability_status);
        // formData.append("vulnerabilityImg1", vulnerabilityImg1[0], "vulnerability_status_img.png");
        console.log(checkListName, checkListVersion, checkListYear, checkLIstStatus)


        try {
            // const response = await fetch('http://localhost:1338/add-auditee', {
            //     method : "POST",
            //     headers : {
            //         "content-Type" : "application/json"
            //     },
            //     body:formData
            // })
            await axios.post(`${process.env.REACT_APP_DEFAULTPATH}add-auditor-checklist`, formData, {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                }
            }).
                then(() => {
                    getCustomers();
                    // tog_standard4
                    tog_standard_add_auditee()
                    tog_standard_add_checklist()
                    // window.location.reload();
                });

        } catch (error) {
            console.log(error)
        }
    }


    const [selectedOptions, setselectedOptions] = useState(null)
    // const animatedComponents = makeAnimated()
    // const orgID = localStorage.getItem("orgID");
    const token = localStorage.getItem("token");

    const optionGroup = [
        { label: "Enable", value: "Enable" },
        { label: "Disabled", value: "Disabled" },
    ]
    // Columns of DataTable
    const columns = [
        {
            dataField: "ChecklistDatabaseID",
            text: "CheckList Database ID",
            sort: true,
            formatter: (cellContent, productData) => (
                <>
                    {(() => {
                        if (productData.ChecklistDatabaseID === null) {
                            return <>-</>;
                        } else {
                            return <>
                                <Badge className="me-2 bg-primary p-1 fs-6">
                                    {productData.ChecklistDatabaseID}
                                </Badge>
                            </>;
                        }
                    })()}
                </>
            ),
        },
        {
            dataField: "ChecklistDatabaseName",
            text: "CheckList Database Name",
            sort: true,
            formatter: (cellContent, productData) => (
                <>
                    {(() => {
                        if (productData.ChecklistDatabaseName === null) {
                            return <>-</>;
                        } else {
                            return <>{productData.ChecklistDatabaseName}</>;
                        }
                    })()}
                </>
            ),
        },
        {
            dataField: "ChecklistDatabaseType",
            text: "CheckList Type",
            sort: true,
            formatter: (cellContent, productData) => (
                <>
                    {(() => {
                        if (productData.ChecklistDatabaseType === null) {
                            return <>-</>;
                        } else {
                            return <>{productData.ChecklistDatabaseType}</>;
                        }
                    })()}
                </>
            ),
        },
        {
            dataField: "ChecklistDatabaseYear",
            text: "CheckList Year",
            sort: true,
            formatter: (cellContent, productData) => (
                <>
                    {(() => {
                        if (productData.ChecklistDatabaseYear === null) {
                            return <>-</>;
                        } else {
                            return <>{productData.ChecklistDatabaseYear}</>;
                        }
                    })()}
                </>
            ),
        },
        // {
        //     dataField: "Value",
        //     text: "Users Count",
        //     sort: true,
        //     formatter: (cellContent, productData) => (
        //         <>
        //             {(() => {
        //                 if (productData.UserCount === null) {
        //                     return <>-</>;
        //                 } else {
        //                     return <>
        //                         <td>  <Badge pill className="me-2 bg-danger ">{productData.UserCount.length}</Badge></td></>;
        //                 }
        //             })()}
        //         </>
        //     ),
        // },
        {
            dataField: "Status",
            text: "Status",
            sort: true,
            formatter: (cellContent, productData) => (
                <>
                    {(() => {
                        if (productData.Status === null) {
                            return <>-</>;
                        } else if (productData.Status === "Enabled") {
                            return <> <Badge pill className="badge-soft-success ms-1 fs-6 ">{productData.Status}</Badge></>;
                        } else if (productData.Status === "Disabled") {
                            return <> <Badge pill className="badge-soft-danger ms-1  fs-6">{productData.Status}</Badge></>;
                        }
                    })()}
                </>
            ),
        },
        {
            dataField: "Action",
            text: "Action",
            sort: true,
            formatter: (cellContent, productData) => (

                <>
                    {/* {console.log("without cliecekd", productData.Domains)} */}
                    {
                        (() => {
                            if (role === "Admin") {
                                return (
                                    <>
                                        <Button
                                            color="warning"
                                            className="btn-sm me-2"
                                            // disabledd={role === "Admin" ? false : true}
                                            onClick={() => {
                                                // console.log("cliecekd", [productData]);
                                                tog_standard4();
                                                // setmodalPopupData(productData.UserCount)
                                                setcheckListDbId(productData.ChecklistDatabaseID)
                                                setcheckLIstStatus(productData.Status)
                                                setcheckList_Type(productData.ChecklistDatabaseType)
                                                setcheckListYear(productData.ChecklistDatabaseYear)
                                                setcheckListName(productData.ChecklistDatabaseName)
                                                setcheckListVersion(productData.ChecklistDatabaseVersion)
                                                // setOrganizaionID(productData.OrganizationID)
                                                // setUserCount(productData.UserCount.length)
                                                // setOrgName(productData.OrganizationName)
                                            }}
                                        >
                                            Edit
                                        </Button>
                                        <Button
                                            color="danger"
                                            className="btn-sm me-2"
                                            // disabled={role === "Admin" ? false : true}
                                            onClick={() => {
                                                setcheckListDbId(productData.ChecklistDatabaseID)
                                                updatePersonalDetails_sweetAlert(productData.ChecklistDatabaseID)
                                                // console.log("cliecekd", [productData]);
                                                // tog_standard4();
                                                // // setmodalPopupData(productData.UserCount)
                                                // setOrganizaionID(productData.OrganizationID)
                                                // setUserCount(productData.UserCount.length)
                                                // setOrgName(productData.OrganizationName)
                                            }}
                                        >

                                            Delete

                                        </Button>
                                    </>
                                )
                            }
                        })()
                    }



                    {/* {console.log("without cliecekd", productData.Domains)} */}
                    <Link to={`/checklist/template/${productData.ChecklistDatabaseID}`} className="text-white">
                        <Button
                            className="btn-sm me-2"
                            color="primary"
                        // onClick={() => {
                        //     // console.log("cliecekd", [productData]);
                        //     tog_standard4();
                        //     setmodalPopupData(productData.UserCount)
                        // }}
                        >

                            CheckList

                        </Button>
                    </Link>

                    {/* <Link to={`/checklist/report/${productData.ChecklistDatabaseID}`} className="text-white">
                        <Button
                            className="btn-sm me-2"
                            color="primary"
                        // onClick={() => {
                        //     // console.log("cliecekd", [productData]);
                        //     tog_standard4();
                        //     setmodalPopupData(productData.UserCount)
                        // }}
                        >

                            Report

                        </Button>
                    </Link>


                    <Link to={`/checklist/certificate/${productData.ChecklistDatabaseID}`} className="text-white">
                        <Button
                            className="btn-sm me-2"
                            color="primary"
                        // onClick={() => {
                        //     // console.log("cliecekd", [productData]);
                        //     tog_standard4();
                        //     setmodalPopupData(productData.UserCount)
                        // }}
                        >

                            Certificate

                        </Button>
                    </Link> */}
                </>
            ),
        },
    ];
    // Sorting of Data Table
    const defaultSorted = [
        {
            dataField: "id",
            order: "asc",
        },
    ];

    // Page Options in Data Table 
    const pageOptions = {
        sizePerPage: 10,
        totalSize: productData.length, // replace later with size(customers),
        custom: true,
    };
    const { SearchBar } = Search; // Search Bar in Data TAble

    var mainData = [];
    var name_regx = /^[A-Za-z ]{3,20}$/;

    useEffect(async () => {

        getUser();
        getCustomers();
    }, []);


    function tog_standard4() {
        setmodal_standard4(!modal_standard4);
        // toggle for view more button 
    }
    function tog_standard_add_auditee() {
        setmodal_add_auditee(!modal_add_auditee);
        // toggle for view more button 
    }

    function tog_standard_add_checklist() {
        setmodal_add_checklist(!modal_add_checklist);
        // toggle for view more button 
    }

    const [role, setRole] = useState()
    // to get userLSit 
    async function getUser(event) {
        // event.preventDefault();
        // console.log("token passes " , userCheck)
        const response = await fetch(`${process.env.REACT_APP_DEFAULTPATH}user-profile`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            },
            body: JSON.stringify({
                userCheck: localStorage.getItem("AuditorID"),
                orgID: localStorage.getItem("orgID")
            }),
        });
        const data = await response.json();
        if (data) {
            const newdata = data.user;
            console.log("data retrived", newdata);
            setRole(newdata.Role)

            // setphone(newdata.Phone);
            // setjobTitle(newdata.JobTitle);
            // console.log("main profile", newdata.FirstName);
        } else {
            alert("data retreival error");
        }
    }

    // ApI to hit Task Data 
    async function getCustomers(event) {
        const response = await fetch(
            `${process.env.REACT_APP_DEFAULTPATH}get-auditor-checkList`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                },
                body: JSON.stringify({
                    userCheck: localStorage.getItem("AuditorID"),
                    orgID: localStorage.getItem("orgID")
                }),
            }
        );
        const data = await response.json();
        if (data) {
            console.log("response", data);
            // mainData = await data.data[0].data.entries;
            mainData = await data;
            setcheckListPreloader(false)
            setProductData(mainData.data);

            setOrgNum(mainData.data.length)
            // console.log(mainData)
            var ACtiveCounter = 0;
            var InactiveCounter = 0
            for (var i = 0; i < mainData.data.length; i++) {
                if (mainData.data[i].Status === "Active") {
                    ACtiveCounter += 1;
                }
                else {
                    InactiveCounter += 1;
                }
            }
            setActiveOrg(ACtiveCounter);
            setInActiveOrg(InactiveCounter)
            // console.log("tempData", TempData)
            // setProductData(TempData);
            // setIpCardPrice(TempData[1].Value.length)
            // setdomainCard(TempData[0].Value.length)
            // setSubdomainCardPrice(TempData[2].Value.length)
        } else {
            setcheckListPreloader(false)
            // alert("data retreival error");
        }
    }

    // function to send data to backend 
    async function updateRow(event) {
        const response = await fetch(
            `${process.env.REACT_APP_DEFAULTPATH}/auditee-list`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    OrgID: OrganizaionID,
                    OrgName,
                    status
                }),
            }
        );
        const data = await response.json();
        if (data.status) {
        }
        else {
            setemailError("Email already exist")
        }

    }


    // Swwet alert or modal to confirm detials  should modify for perosnal Details 
    function updatePersonalDetails_sweetAlert(id) {
        console.log("selected GRoup", id)
        swal({
            title: "Are you sure?",
            text: "Once done, you will update your personal details!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    if (deleteCheckListDb(id)) {
                        swal("Poof! Your perosnal details has been updated!", {
                            icon: "success",
                            buttons: true
                        }).then((reload) => {
                            if (reload) {
                                getCustomers()
                                // window.location.reload(false);
                            }
                        })
                    }
                    else {
                        swal("Reload operation has been aborted!");
                    }
                } else {
                    swal("Operation has been aborted!");
                }
            });
    }

    async function editCheckListDb(id) {

        var formData = new FormData();
        formData.append("checkListDbId", checkListDbId);
        formData.append("checkListName", checkListName);
        // formData.append("status_severity", status_severity);
        formData.append("checkListVersion", checkListVersion);
        formData.append("checkListYear", checkListYear);
        formData.append("checkLIstStatus", checkLIstStatus);
        formData.append("checkLIstType", checkList_Type);
        formData.append("auditorID", userCheck)
        formData.append("userCheck", localStorage.getItem("AuditorID"))
        formData.append("orgID", localStorage.getItem("orgID"))
        // formData.append("status_vulnerability_status", status_vulnerability_status);
        // formData.append("vulnerabilityImg1", vulnerabilityImg1[0], "vulnerability_status_img.png");
        console.log(checkListName, checkListVersion, checkListYear, checkLIstStatus)


        try {
            // const response = await fetch('http://localhost:1338/add-auditee', {
            //     method : "POST",
            //     headers : {
            //         "content-Type" : "application/json"
            //     },
            //     body:formData
            // })
            await axios.post(`${process.env.REACT_APP_DEFAULTPATH}edit-auditor-checklist`, formData, {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                }
            }).
                then(() => {
                    getCustomers()
                    tog_standard4()
                    // window.location.reload();
                });

        } catch (error) {
            console.log(error)
        }
    }

    async function deleteCheckListDb(id) {

        console.log("inside edit", id)
        const response = await fetch(
            `${process.env.REACT_APP_DEFAULTPATH}delete-auditor-checklist`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                },
                body: JSON.stringify({
                    checkListDbId: id,
                    userCheck: localStorage.getItem("AuditorID"),
                    orgID: localStorage.getItem("orgID")
                }),
            }
        );
        const data = await response.json();
        if (data.status) {
        }
        else {
            setemailError("Email already exist")
        }
    }
    // functions to validate regex OrgName
    function checkOrgNameValidation(OrgName_val) {
        if (OrgName_val === " ") {
            setOrgNameError("enter first name")
            setdetailBtn(true)
        }
        else if (name_regx.test(OrgName_val)) {
            setOrgNameError("")
            setdetailBtn(false)
        }
        else if (OrgName_val.length < 3) {
            setOrgNameError(" must contain 3 characters")
            setdetailBtn(true)
        }
        else {
            setOrgNameError(`Organization doesn't contain "0-9  , & @ "`)
            setdetailBtn(true)
        }
    }

    // function to handle change in status dropdown
    function handleSelectOptions(selectedOptions) {
        setselectedOptions(selectedOptions)
        setdetailBtn(false)
    }

    return (
        <React.Fragment>
            {checkListPreloader ?
                <div className="transparent-loader">
                    <Spinner type="grow" className="ms-2" color="primary" /> </div> : <></>}
            <div className="userProfile credentialBreachProfile">
                <MetaTags>
                    <title>CheckList | AuditSense</title>
                </MetaTags>
                <Row>
                    <Breadcrumbs
                        title="Dashboard"
                        breadcrumbItem="CheckList Database List"
                        breadcrumbFeature="CheckList"
                    />
                </Row>


                {(() => {
                    if (role === "Admin") {
                        return (
                            <div className="text-end">
                                <Button className="btn-success" onClick={() => {
                                    // console.log("asdas")
                                    tog_standard_add_auditee()
                                }}><i className="bx bx-buildings"></i> &nbsp; Add Checklist DB</Button>
                            </div>
                        )
                    }
                })()}



                <Row>

                    {/* EDIT MODAL  */}
                    <Modal
                        isOpen={modal_standard4}
                        toggle={() => {
                            tog_standard4();
                        }}
                    >
                        <div className="modal-header">
                            <h5 className="modal-title mt-0" id="myModalLabel">
                                {checkList_Type === "Web Application Audit" ? "Edit Web Application Audit Checklist Database" : checkList_Type === "Mobile Application Audit" ? "Edit Mobile Application Audit Checklist Database" : checkList_Type === "API Audit" ? "Edit API Audit Checklist Database" : checkList_Type === "Network Audit" ? "Edit Network Audit Checklist Database" : ""}
                            </h5>
                            <button
                                type="button"
                                onClick={() => {
                                    setmodal_standard4(false);
                                }}
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div className="modal-body">

                            <div className="d-flex flex-column h-100">

                                <div className='webApplication'>

                                    <Form >

                                        <Row className='my-2'>

                                            <Col md={12}>
                                                <div className="mb-3">
                                                    {/* <Label className="form-label" htmlFor="country">CheckList Status : </Label> */}
                                                    <Input type="hidden" value={checkListDbId} placeholder={checkListDbId} disabled={true} />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className='my-2'>

                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="country">CheckList Status : </Label>
                                                    <Select
                                                        // value={checkLIstStatus}
                                                        placeholder={checkLIstStatus}
                                                        onChange={(e) => {
                                                            // console.log(e.value)
                                                            setcheckLIstStatus(e.value);
                                                            // handleSelectOptions_empSize(e.value);
                                                            // console.log(selectedOptions_empSize)
                                                        }}
                                                        options={optionGroup_checkListStatus}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="employeeSize">CheckList Version :  </Label>
                                                    <Select
                                                        // value={empSize}
                                                        placeholder={checkListVersion}
                                                        onChange={(e) => {
                                                            // console.log(e.value)
                                                            setcheckListVersion(e.value);
                                                            // handleSelectOptions_empSize(e.value);
                                                            // console.log(selectedOptions_empSize)
                                                        }}
                                                        options={optionGroup_checklIst_version}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row className='my-2'>

                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="companyEmail">CheckList Year : </Label>
                                                    <Input type="text" className="form-control" id="companyEmail"
                                                        placeholder={checkListYear}
                                                        onChange={(e) => {
                                                            // console.log(e.target.value);
                                                            setcheckListYear(e.target.value);
                                                        }} />
                                                </div>
                                            </Col>

                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="companyName">CheckList Name: </Label>
                                                    <Input type="text" className="form-control" id="companyName"
                                                        placeholder={checkListName}
                                                        onChange={(e) => {
                                                            // console.log(e.target.value);
                                                            setcheckListName(e.target.value);
                                                        }} />
                                                </div>
                                            </Col>


                                        </Row>

                                    </Form>
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                onClick={() => {
                                    tog_standard4();
                                    setmodal_standard4(false);
                                }}
                                className="btn btn-warning "
                                data-dismiss="modal"
                            >
                                Close
                            </button>
                            <button
                                type="button"
                                onClick={() => {
                                    // updatePersonalDetails_sweetAlert()
                                    editCheckListDb();
                                }}
                                // disabled={detailBtn}
                                className="btn btn-warning "
                                data-dismiss="modal"
                            >
                                Save
                            </button>
                        </div>
                    </Modal>

                    <Row className="content">
                        <Col
                            lg={4}
                            md={5}
                            className="col-xxl-9 userData credentialData"
                        >
                            <div className="auth-full-page-content d-flex p-sm-5 p-4">
                                <div className="w-100">
                                    <div className="d-flex flex-column">
                                        <div className="  text-center">
                                            <Link
                                                to="/dashboard"
                                                className="d-block auth-logo"
                                            >
                                            </Link>
                                        </div>
                                        <div className="auth-content my-auto">

                                            <div className="mainTable">
                                                <CardBody>
                                                    <PaginationProvider
                                                        pagination={paginationFactory(
                                                            pageOptions
                                                        )}
                                                    >
                                                        {({
                                                            paginationProps,
                                                            paginationTableProps,
                                                        }) => (
                                                            <ToolkitProvider
                                                                keyField="id"
                                                                columns={columns}
                                                                data={productData}
                                                                search
                                                            >
                                                                {(toolkitProps) => (
                                                                    <React.Fragment>
                                                                        <Row className="mb-2">
                                                                            <Col>
                                                                                <div className="search-box me-2 mb-2 d-flex justify-content-between">
                                                                                    <div className="d-inline">
                                                                                        <SizePerPageDropdownStandalone
                                                                                            {...paginationProps}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="position-relative">
                                                                                        <SearchBar
                                                                                            {...toolkitProps.searchProps}
                                                                                        />
                                                                                        <i className="bx bx-search-alt search-icon" />
                                                                                    </div>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>

                                                                        <Row>
                                                                            <Col xl="12">
                                                                                <div className="table-responsive">
                                                                                    <BootstrapTable
                                                                                        keyField={"id"}
                                                                                        responsive
                                                                                        bordered={false}
                                                                                        striped={false}
                                                                                        defaultSorted={
                                                                                            defaultSorted
                                                                                        }
                                                                                        classes={
                                                                                            "table align-middle table-nowrap"
                                                                                        }
                                                                                        headerWrapperClasses={
                                                                                            "thead-light"
                                                                                        }
                                                                                        {...toolkitProps.baseProps}
                                                                                        {...paginationTableProps}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                        </Row>

                                                                        <Row className="align-items-md-center mt-30">
                                                                            <Col className="pagination pagination-rounded justify-content-end mb-2">
                                                                                <PaginationListStandalone
                                                                                    {...paginationProps}
                                                                                />
                                                                            </Col>
                                                                        </Row>

                                                                    </React.Fragment>
                                                                )}
                                                            </ToolkitProvider>
                                                        )}
                                                    </PaginationProvider>
                                                </CardBody>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Row>


                <Row>
                    {/* select checklist type  */}
                    <Modal
                        isOpen={modal_add_auditee}
                        toggle={() => {
                            tog_standard_add_auditee();
                        }}
                    >
                        <div className="modal-header">
                            <h5 className="modal-title mt-0" id="myModalLabel">
                                Select Checklist Type
                            </h5>
                            <button
                                type="button"
                                onClick={() => {
                                    setmodal_add_auditee(false);
                                }}
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        {/* checklist creation modal  */}
                        <Modal
                            isOpen={modal_add_checklist}
                            toggle={() => {
                                tog_standard_add_checklist();
                            }}
                        >
                            <div className="modal-header">
                                <h5 className="modal-title mt-0" id="myModalLabel">
                                    {checkList_Type === "Web Application Audit" ? "Add Web Application Audit Checklist Database" : checkList_Type === "Mobile Application Audit" ? "Add Mobile Application Audit Checklist Database" : checkList_Type === "API Audit" ? "Add API Audit Checklist Database" : checkList_Type === "Network Audit" ? "Add Network Audit Checklist Database" : ""}
                                </h5>
                                <button
                                    type="button"
                                    onClick={() => {
                                        setmodal_add_auditee(false);
                                    }}
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            {(() => {
                                if (checkList_Type === "Web Application Audit") {
                                    return (
                                        <div className="modal-body">

                                            <div className="d-flex flex-column h-100">

                                                <div className='webApplication'>
                                                    <div>
                                                        <Form >

                                                            <Row className='my-2'>

                                                                <Col md={6}>
                                                                    <div className="mb-3">
                                                                        <Label className="form-label" htmlFor="companyName">CheckList Name: </Label>
                                                                        <Input type="text" className="form-control" id="companyName"
                                                                            onChange={(e) => {
                                                                                // console.log(e.target.value);
                                                                                setcheckListName(e.target.value);
                                                                            }} />
                                                                    </div>
                                                                </Col>

                                                                <Col md={6}>
                                                                    <div className="mb-3">
                                                                        <Label className="form-label" htmlFor="companyEmail">CheckList Year : </Label>
                                                                        <Select
                                                                            // value={statusCountry}
                                                                            onChange={(e) => {
                                                                                // console.log(e.value)
                                                                                setcheckListYear(e.value);
                                                                                // handleSelectOptions_empSize(e.value);
                                                                                // console.log(selectedOptions_empSize)
                                                                            }}
                                                                            options={year_list()}
                                                                            classNamePrefix="select2-selection"
                                                                        />
                                                                        {/* <Input type="text" className="form-control" id="companyEmail"
                                                                            placeholder="2022"
                                                                            onChange={(e) => {
                                                                                // console.log(e.target.value);
                                                                                setcheckListYear(e.target.value);
                                                                            }} /> */}
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            <Row className='my-2'>


                                                                <Col md={6}>
                                                                    <div className="mb-3">
                                                                        <Label className="form-label" htmlFor="employeeSize">CheckList Version :  </Label>
                                                                        {/* <Select
                                                                                            // value={empSize}
                                                                                            onChange={(e) => {
                                                                                                // console.log(e.value)
                                                                                                setcheckListVersion(e.value);
                                                                                                // handleSelectOptions_empSize(e.value);
                                                                                                // console.log(selectedOptions_empSize)
                                                                                            }}
                                                                                            options={optionGroup_checklIst_version}
                                                                                            classNamePrefix="select2-selection"
                                                                                        /> */}
                                                                        <Input type="text" className="form-control" id="companyEmail"
                                                                            // placeholder="2022"
                                                                            onChange={(e) => {
                                                                                // console.log(e.target.value);
                                                                                setcheckListVersion(e.target.value);
                                                                            }} />

                                                                    </div>
                                                                </Col>


                                                                <Col md={6}>
                                                                    <div className="mb-3">
                                                                        <Label className="form-label" htmlFor="country">CheckList Status : </Label>
                                                                        <Select
                                                                            // value={statusCountry}
                                                                            onChange={(e) => {
                                                                                // console.log(e.value)
                                                                                setcheckLIstStatus(e.value);
                                                                                // handleSelectOptions_empSize(e.value);
                                                                                // console.log(selectedOptions_empSize)
                                                                            }}
                                                                            options={optionGroup_checkListStatus}
                                                                            classNamePrefix="select2-selection"
                                                                        />
                                                                    </div>
                                                                </Col>


                                                            </Row>

                                                        </Form>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>)
                                }
                                if (checkList_Type === "Mobile Application Audit") {
                                    return (
                                        <div className="modal-body">

                                            <div className="d-flex flex-column h-100">

                                                <div className='mobileApplication'>
                                                    <div>
                                                        <Form >

                                                            <Row className='my-2'>

                                                                <Col md={6}>
                                                                    <div className="mb-3">
                                                                        <Label className="form-label" htmlFor="companyName">CheckList Name: </Label>
                                                                        <Input type="text" className="form-control" id="companyName"
                                                                            onChange={(e) => {
                                                                                // console.log(e.target.value);
                                                                                setcheckListName(e.target.value);
                                                                            }} />
                                                                    </div>
                                                                </Col>

                                                                <Col md={6}>
                                                                    <div className="mb-3">
                                                                        <Label className="form-label" htmlFor="companyEmail">CheckList Year : </Label>
                                                                        <Select
                                                                            // value={statusCountry}
                                                                            onChange={(e) => {
                                                                                // console.log(e.value)
                                                                                setcheckListYear(e.value);
                                                                                // handleSelectOptions_empSize(e.value);
                                                                                // console.log(selectedOptions_empSize)
                                                                            }}
                                                                            options={year_list()}
                                                                            classNamePrefix="select2-selection"
                                                                        />
                                                                        {/* <Input type="text" className="form-control" id="companyEmail"
                                                                            placeholder="2022"
                                                                            onChange={(e) => {
                                                                                // console.log(e.target.value);
                                                                                setcheckListYear(e.target.value);
                                                                            }} /> */}
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            <Row className='my-2'>


                                                                <Col md={6}>
                                                                    <div className="mb-3">
                                                                        <Label className="form-label" htmlFor="employeeSize">CheckList Version :  </Label>
                                                                        {/* <Select
                                                                                            // value={empSize}
                                                                                            onChange={(e) => {
                                                                                                // console.log(e.value)
                                                                                                setcheckListVersion(e.value);
                                                                                                // handleSelectOptions_empSize(e.value);
                                                                                                // console.log(selectedOptions_empSize)
                                                                                            }}
                                                                                            options={optionGroup_checklIst_version}
                                                                                            classNamePrefix="select2-selection"
                                                                                        /> */}
                                                                        <Input type="text" className="form-control" id="companyEmail"
                                                                            // placeholder="2022"
                                                                            onChange={(e) => {
                                                                                // console.log(e.target.value);
                                                                                setcheckListVersion(e.target.value);
                                                                            }} />

                                                                    </div>
                                                                </Col>


                                                                <Col md={6}>
                                                                    <div className="mb-3">
                                                                        <Label className="form-label" htmlFor="country">CheckList Status : </Label>
                                                                        <Select
                                                                            // value={statusCountry}
                                                                            onChange={(e) => {
                                                                                // console.log(e.value)
                                                                                setcheckLIstStatus(e.value);
                                                                                // handleSelectOptions_empSize(e.value);
                                                                                // console.log(selectedOptions_empSize)
                                                                            }}
                                                                            options={optionGroup_checkListStatus}
                                                                            classNamePrefix="select2-selection"
                                                                        />
                                                                    </div>
                                                                </Col>


                                                            </Row>

                                                        </Form>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>)
                                }
                                if (checkList_Type === "API Audit") {
                                    return (
                                        <div className="modal-body">

                                            <div className="d-flex flex-column h-100">

                                                <div className='apiWebApplication'>
                                                    <div>
                                                        <Form >

                                                            <Row className='my-2'>

                                                                <Col md={6}>
                                                                    <div className="mb-3">
                                                                        <Label className="form-label" htmlFor="companyName">CheckList Name: </Label>
                                                                        <Input type="text" className="form-control" id="companyName"
                                                                            onChange={(e) => {
                                                                                // console.log(e.target.value);
                                                                                setcheckListName(e.target.value);
                                                                            }} />
                                                                    </div>
                                                                </Col>

                                                                <Col md={6}>
                                                                    <div className="mb-3">
                                                                        <Label className="form-label" htmlFor="companyEmail">CheckList Year : </Label>
                                                                        <Select
                                                                            // value={statusCountry}
                                                                            onChange={(e) => {
                                                                                // console.log(e.value)
                                                                                setcheckListYear(e.value);
                                                                                // handleSelectOptions_empSize(e.value);
                                                                                // console.log(selectedOptions_empSize)
                                                                            }}
                                                                            options={year_list()}
                                                                            classNamePrefix="select2-selection"
                                                                        />
                                                                        {/* <Input type="text" className="form-control" id="companyEmail"
                                                                            placeholder="2022"
                                                                            onChange={(e) => {
                                                                                // console.log(e.target.value);
                                                                                setcheckListYear(e.target.value);
                                                                            }} /> */}
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            <Row className='my-2'>


                                                                <Col md={6}>
                                                                    <div className="mb-3">
                                                                        <Label className="form-label" htmlFor="employeeSize">CheckList Version :  </Label>
                                                                        {/* <Select
                                                                                            // value={empSize}
                                                                                            onChange={(e) => {
                                                                                                // console.log(e.value)
                                                                                                setcheckListVersion(e.value);
                                                                                                // handleSelectOptions_empSize(e.value);
                                                                                                // console.log(selectedOptions_empSize)
                                                                                            }}
                                                                                            options={optionGroup_checklIst_version}
                                                                                            classNamePrefix="select2-selection"
                                                                                        /> */}
                                                                        <Input type="text" className="form-control" id="companyEmail"
                                                                            // placeholder="2022"
                                                                            onChange={(e) => {
                                                                                // console.log(e.target.value);
                                                                                setcheckListVersion(e.target.value);
                                                                            }} />

                                                                    </div>
                                                                </Col>


                                                                <Col md={6}>
                                                                    <div className="mb-3">
                                                                        <Label className="form-label" htmlFor="country">CheckList Status : </Label>
                                                                        <Select
                                                                            // value={statusCountry}
                                                                            onChange={(e) => {
                                                                                // console.log(e.value)
                                                                                setcheckLIstStatus(e.value);
                                                                                // handleSelectOptions_empSize(e.value);
                                                                                // console.log(selectedOptions_empSize)
                                                                            }}
                                                                            options={optionGroup_checkListStatus}
                                                                            classNamePrefix="select2-selection"
                                                                        />
                                                                    </div>
                                                                </Col>


                                                            </Row>

                                                        </Form>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>)
                                }
                                if (checkList_Type === "Network Audit") {
                                    return (
                                        <div className="modal-body">

                                            <div className="d-flex flex-column h-100">

                                                <div className='networkAudit'>
                                                    <div>
                                                        <Form >

                                                            <Row className='my-2'>

                                                                <Col md={6}>
                                                                    <div className="mb-3">
                                                                        <Label className="form-label" htmlFor="companyName">CheckList Name: </Label>
                                                                        <Input type="text" className="form-control" id="companyName"
                                                                            onChange={(e) => {
                                                                                // console.log(e.target.value);
                                                                                setcheckListName(e.target.value);
                                                                            }} />
                                                                    </div>
                                                                </Col>

                                                                <Col md={6}>
                                                                    <div className="mb-3">
                                                                        <Label className="form-label" htmlFor="companyEmail">CheckList Year : </Label>
                                                                        <Select
                                                                            // value={statusCountry}
                                                                            onChange={(e) => {
                                                                                // console.log(e.value)
                                                                                setcheckListYear(e.value);
                                                                                // handleSelectOptions_empSize(e.value);
                                                                                // console.log(selectedOptions_empSize)
                                                                            }}
                                                                            options={year_list()}
                                                                            classNamePrefix="select2-selection"
                                                                        />
                                                                        {/* <Input type="text" className="form-control" id="companyEmail"
                                                                            placeholder="2022"
                                                                            onChange={(e) => {
                                                                                // console.log(e.target.value);
                                                                                setcheckListYear(e.target.value);
                                                                            }} /> */}
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            <Row className='my-2'>


                                                                <Col md={6}>
                                                                    <div className="mb-3">
                                                                        <Label className="form-label" htmlFor="employeeSize">CheckList Version :  </Label>
                                                                        {/* <Select
                                                                                            // value={empSize}
                                                                                            onChange={(e) => {
                                                                                                // console.log(e.value)
                                                                                                setcheckListVersion(e.value);
                                                                                                // handleSelectOptions_empSize(e.value);
                                                                                                // console.log(selectedOptions_empSize)
                                                                                            }}
                                                                                            options={optionGroup_checklIst_version}
                                                                                            classNamePrefix="select2-selection"
                                                                                        /> */}
                                                                        <Input type="text" className="form-control" id="companyEmail"
                                                                            // placeholder="2022"
                                                                            onChange={(e) => {
                                                                                // console.log(e.target.value);
                                                                                setcheckListVersion(e.target.value);
                                                                            }} />

                                                                    </div>
                                                                </Col>


                                                                <Col md={6}>
                                                                    <div className="mb-3">
                                                                        <Label className="form-label" htmlFor="country">CheckList Status : </Label>
                                                                        <Select
                                                                            // value={statusCountry}
                                                                            onChange={(e) => {
                                                                                // console.log(e.value)
                                                                                setcheckLIstStatus(e.value);
                                                                                // handleSelectOptions_empSize(e.value);
                                                                                // console.log(selectedOptions_empSize)
                                                                            }}
                                                                            options={optionGroup_checkListStatus}
                                                                            classNamePrefix="select2-selection"
                                                                        />
                                                                    </div>
                                                                </Col>


                                                            </Row>

                                                        </Form>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>)
                                }
                            })()}
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    onClick={() => {
                                        tog_standard_add_auditee();
                                        setmodal_add_auditee(false);
                                        setmodal_add_checklist(false);
                                    }}
                                    className="btn btn-secondary "
                                    data-dismiss="modal"
                                >
                                    Close
                                </button>
                                <button
                                    type="button"
                                    onClick={() => {
                                        console.log("cllick ")
                                        // updatePersonalDetails_sweetAlert()
                                        addVulnerabilities()
                                    }}
                                    className="btn btn-primary "
                                // data-dismiss="modal"
                                >
                                    Submit
                                </button>
                            </div>
                        </Modal>
                        <div className="modal-body">

                            <div className="d-flex flex-column h-100">

                                <div className='webApplication'>
                                    <Form >

                                        <Row className='my-2'>

                                            <Col md={4} className="d-flex justify-content-between align-middle ">
                                                <div className="mb-2 mt-3  ">
                                                    <Label className="form-label" htmlFor="country">CheckList Type : </Label>
                                                </div>
                                            </Col>

                                            <Col md={8}>
                                                <div className="mb-3 mt-2 d-flex justify-content-between">
                                                    <Select
                                                        className="w-100"
                                                        // value={statusCountry}
                                                        onChange={(e) => {
                                                            // console.log(e.value)
                                                            setcheckList_Type(e.value);
                                                            // handleSelectOptions_empSize(e.value);
                                                            // console.log(selectedOptions_empSize)
                                                        }}
                                                        options={optionGroup_CheckList_Type}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </div>
                                            </Col>
                                            {/* <Col md={6}>
        <div className="mb-3">
            <Label className="form-label" htmlFor="employeeSize">CheckList Type :  </Label>
            <Select
                // value={empSize}
                onChange={(e) => {
                    // console.log(e.value)
                    setcheckListType(e.value);
                    // handleSelectOptions_empSize(e.value);
                    // console.log(selectedOptions_empSize)
                }}
                options={optionGroup_checklIstType}
                classNamePrefix="select2-selection"
            />
        </div>
    </Col> */}
                                        </Row>

                                        {/* <Row className='my-2'>

    <Col md={6}>
        <div className="mb-3">
            <Label className="form-label" htmlFor="companyEmail">CheckList Year : </Label>
            <Input type="text" className="form-control" id="companyEmail"
                onChange={(e) => {
                    // console.log(e.target.value);
                    setcheckListYear(e.target.value);
                }} />
        </div>
    </Col>

    <Col md={6}>
        <div className="mb-3">
            <Label className="form-label" htmlFor="companyName">CheckList Name: </Label>
            <Input type="text" className="form-control" id="companyName"
                onChange={(e) => {
                    // console.log(e.target.value);
                    setcheckListName(e.target.value);
                }} />
        </div>
    </Col>


</Row> */}

                                    </Form>
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                onClick={() => {
                                    tog_standard_add_auditee();
                                    setmodal_add_auditee(false);
                                }}
                                className="btn btn-secondary "
                                data-dismiss="modal"
                            >
                                Close
                            </button>
                            <button
                                type="button"
                                onClick={() => {
                                    tog_standard_add_checklist();
                                    // updatePersonalDetails_sweetAlert()
                                    // addVulnerabilities()
                                }}
                                className="btn btn-primary "
                            // data-dismiss="modal"
                            >
                                Next
                            </button>
                        </div>
                    </Modal>
                </Row>
            </div>
        </React.Fragment>
    );
};

export default CheckList;
