import React, { useState, useEffect } from "react";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import CountUp from "react-countup";


import FeatherIcon from "feather-icons-react";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// import "react-table/react-table.css";
import {
    Badge,
    CardBody,
    Col,
    Modal,
    Row,
    Card,
    Input,
    Button,
    Form, Label, CardHeader,
    CardTitle,
    CardSubtitle,
} from "reactstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import Select from "react-select"
import { AvForm, AvField } from "availity-reactstrap-validation";

import MetaTags from "react-meta-tags";
import swal from 'sweetalert';
import axios from "axios"

//import images
import Breadcrumbs from "../../../components/Common/Breadcrumb";


import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
const CheckListCertificate = (props) => {

    // const location = useLocation();
    // const history = useHistory()
    // console.log("location", history.location);
    const [productData, setProductData] = useState([]);
    const [modal_standard4, setmodal_standard4] = useState(false); // View More button Modal of ExposedCredentials


    const [modal_add_auditee, setmodal_add_auditee] = useState(false); // View More button Modal of ExposedCredentials

    const [orgNum, setOrgNum] = useState(0);
    const [activeOrg, setActiveOrg] = useState(0);
    const [InActiveOrg, setInActiveOrg] = useState(0);


    // values stored of form
    const [OrgName, setOrgName] = useState("");
    const [status, setstatus] = useState("");

    // validation state error 
    const [emailError, setemailError] = useState("");
    const [OrgNameError, setOrgNameError] = useState("");
    const [OrganizaionID, setOrganizaionID] = useState("");
    const [UserCount, setUserCount] = useState("");
    const [formOrganizationID, setformOrganizationID] = useState(""); // used to send userID of row to backend
    const [detailBtn, setdetailBtn] = useState(true); // to disable the button on inital


    // function to get uniques ID
    function uniqueIdGenerator() {
        var seq = (Math.floor(Math.random() * 100) + 100).toString().substring(1);
        const id = Date.now() + seq;
        return id;
    }
    const userCheck = localStorage.getItem("AuditorID");
    const checkListID = props.match.params.organizationId // to store userId from path 

    const [reportID, setReportID] = useState(localStorage.getItem("reportId"));
    const [findvulnerability, setfindvulnerability] = useState();
    const [rating, setrating] = useState()
    const [vulnerabilityDescription, setvulnerabilityDescription] = useState();
    const [vulnerabilityImpact, setvulnerabilityImpact] = useState();
    const [vulnerabilityRemediation, setvulnerabilityRemediation] = useState();
    const [vulnerabilityAffectedURL, setvulnerabilityAffectedURL] = useState();
    // const [vulnerabilityAffectedURL , setvulnerabilityAffectedURL] =  useState();
    const [vulnerabilityImg1, setvulnerabilityImg1] = useState();
    const [vulnerabilityList, setVulnerabilityList] = useState();



    const [TemplateName, setTemplateName] = useState();
    // const [status, setstatus] = useState();
    // const [empSize, setempSize] = useState();
    const [cerificateStatus, setcerificateStatus] = useState();
    const [description, setdescription] = useState();
    const [impact, setimpact] = useState();
    const [remediation, setremediation] = useState();
    const [reference, setreference] = useState();
    const [state, setstate] = useState();
    const [pincode, setpincode] = useState();
    const [otherDetails, setotherDetails] = useState();

    const [status_empSize, setstatus_empSize] = useState("");
    const [statusCountry, setstatusCountry] = useState("");
    const [companyStatus, setcompanyStatus] = useState();


    const [status_vulnerability_status, setstatus_vulnerability_status] = useState("");
    const [selectedOptions_vulnerability_status, setselectedOptions_vulnerability_status] = useState(null);


    const optionGroup_status = [
        { label: "Enable", value: "Enable" },
        { label: "Disable", value: "Disable" },
        { label: "Pending", value: "Pending" },
    ];

    const optionGroup_country = [
        { label: "India", value: "India" },
        { label: "USA", value: "USA" },
        { label: "Medium", value: "Medium" },
        { label: "Low", value: "Low" },
        { label: "Informational", value: "Informational" }
    ];

    const optionGroup_checklIstType = [
        { label: "Technical Audit ", value: "Technical Audit " },
        { label: "Casual", value: "Inactive" },
    ];
    const optionGroup_checkListStatus = [
        { label: "Active", value: "Active" },
        { label: "Inactive", value: "Inactive" },
    ];
    const optionGroup_CompanyStatus = [
        { label: "Medium", value: "Medium" },
        { label: "Low", value: "Low" },
        { label: "Informational", value: "Informational" }
    ];

    // form validatioon checkuo end ------------->>>>>>>>>>>>>>>>>>>>>>>>>>

    // function to handle change in status dropdown
    //  function handleSelectOptions_empSize(selectedOptions_empSize) {
    //     setselectedOptions_empSize(selectedOptions_empSize);
    // }

    // function to handle change in status dropdown
    function handleSelectOptions_severity(selectedOptions_severity) {
        // setselectedOptions_severity(selectedOptions_severity);
    }
    // function to handle change in status dropdown
    function handleSelectOptions_vulnerability_status(selectedOptions_vulnerability_status) {
        setselectedOptions_vulnerability_status(selectedOptions_vulnerability_status);
    }


    async function addVulnerabilities() {
        var formData = new FormData();
        formData.append("TemplateName", TemplateName);
        // formData.append("status_severity", status_severity);
        formData.append("cerificateStatus", cerificateStatus);
        formData.append("checkListID", checkListID);
        // formData.append("description", description);
        // formData.append("description", description);

        formData.append("auditorID", userCheck)
        formData.append("userCheck", localStorage.getItem("AuditorID"))
        formData.append("orgID", localStorage.getItem("orgID"))
        // formData.append("status_vulnerability_status", status_vulnerability_status);
        // formData.append("vulnerabilityImg1", vulnerabilityImg1[0], "vulnerability_status_img.png");
        // console.log(checkListName,checkListType , checkListYear , checkLIstStatus)

        try {
            // const response = await fetch('http://localhost:1338/add-auditee', {
            //     method : "POST",
            //     headers : {
            //         "content-Type" : "application/json"
            //     },
            //     body:formData
            // })
            await axios.post(`${process.env.REACT_APP_DEFAULTPATH}add-auditor-checklist-certificate-template`, formData, {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                }
            }).
                then(() => {
                    window.location.reload();
                });

        } catch (error) {
            console.log(error)
        }
    }







    const [selectedOptions, setselectedOptions] = useState(null)
    // const animatedComponents = makeAnimated()
    // const orgID = localStorage.getItem("orgID");
    const token = localStorage.getItem("token");

    const optionGroup = [
        { label: "Active", value: "Active" },
        { label: "InActive", value: "InActive" },
    ]
    // Columns of DataTable
    const columns = [
        {
            dataField: "CertificateTemplateID",
            text: "CertificateTemplateID ",
            sort: true,
            formatter: (cellContent, productData) => (
                <>
                    {(() => {
                        if (productData.CertificateTemplateID === null) {
                            return <>-</>;
                        } else {
                            return <>  <Badge className="me-2 bg-primary p-1 fs-6">
                                {productData.CertificateTemplateID}
                            </Badge></>;
                        }
                    })()}
                </>
            ),
        },
        // {
        //     dataField: "Severity",
        //     text: "Severity ",
        //     sort: true,
        //     formatter: (cellContent, productData) => (
        //         <>
        //             {(() => {
        //                 if (productData.Severity === null) {
        //                     return <>-</>;
        //                 } else {
        //                     return <>{productData.Severity}</>;
        //                 }
        //             })()}
        //         </>
        //     ),
        // },
        {
            dataField: "TemplateName",
            text: "TemplateName ",
            sort: true,
            formatter: (cellContent, productData) => (
                <>
                    {(() => {
                        if (productData.TemplateName === null) {
                            return <>-</>;
                        } else {
                            return <>{productData.TemplateName}</>;
                        }
                    })()}
                </>
            ),
        },
        // {
        //     dataField: "Value",
        //     text: "Users Count",
        //     sort: true,
        //     formatter: (cellContent, productData) => (
        //         <>
        //             {(() => {
        //                 if (productData.UserCount === null) {
        //                     return <>-</>;
        //                 } else {
        //                     return <>
        //                         <td>  <Badge pill className="me-2 bg-danger ">{productData.UserCount.length}</Badge></td></>;
        //                 }
        //             })()}
        //         </>
        //     ),
        // },
        {
            dataField: "Status",
            text: "Status",
            sort: true,
            formatter: (cellContent, productData) => (
                <>
                    {(() => {
                        if (productData.Status === null) {
                            return <>-</>;
                        } else if (productData.Status === "Enable") {
                            return <> <Badge pill className="badge-soft-success ms-1 fs-6 ">{productData.Status}</Badge></>;
                        } else if (productData.Status === "Pending") {
                            return <> <Badge pill className="badge-soft-danger ms-1  fs-6">{productData.Status}</Badge></>;
                        }
                    })()}
                </>
            ),
        },
        {
            dataField: "View More",
            text: "View More",
            sort: true,
            formatter: (cellContent, productData) => (

                <>
                    <AvForm>
                        {/* {console.log("without cliecekd", productData.Domains)} */}
                        <Button
                            color="primary"
                            className="btn-sm"
                            disabled={role === "Admin" ? false : true}
                            onClick={() => {
                                // console.log("cliecekd", [productData]);
                                tog_standard4();
                                // setmodalPopupData(productData.UserCount)
                                // setOrganizaionID(productData.OrganizationID)
                                // setUserCount(productData.UserCount.length)
                                // setOrgName(productData.OrganizationName)
                            }}
                        >

                            View More

                        </Button>
                    </AvForm>
                </>
            ),
        },
    ];
    // Sorting of Data Table
    const defaultSorted = [
        {
            dataField: "id",
            order: "asc",
        },
    ];

    // Page Options in Data Table 
    const pageOptions = {
        sizePerPage: 10,
        totalSize: productData.length, // replace later with size(customers),
        custom: true,
    };
    const { SearchBar } = Search; // Search Bar in Data TAble

    var mainData = [];
    var name_regx = /^[A-Za-z ]{3,20}$/;

    useEffect(async () => {

        getUser();
        getCustomers();
    }, []);


    function tog_standard4() {
        setmodal_standard4(!modal_standard4);
        // toggle for view more button 
    }
    function tog_standard_add_auditee() {
        setmodal_add_auditee(!modal_add_auditee);
        // toggle for view more button 
    }

    const [role, setRole] = useState()
    // to get userLSit 
    async function getUser(event) {
        // event.preventDefault();
        // console.log("token passes " , userCheck)
        const response = await fetch(`${process.env.REACT_APP_DEFAULTPATH}user-profile`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            },
            body: JSON.stringify({
                userCheck: localStorage.getItem("AuditorID"),
                orgID: localStorage.getItem("orgID")
            }),
        });
        const data = await response.json();
        if (data) {
            const newdata = data.user;
            console.log("data retrived", newdata);
            setRole(newdata.Role)

            // setphone(newdata.Phone);
            // setjobTitle(newdata.JobTitle);
            // console.log("main profile", newdata.FirstName);
        } else {
            alert("data retreival error");
        }
    }

    // ApI to hit table DATa
    async function getCustomers(event) {
        const response = await fetch(
            `${process.env.REACT_APP_DEFAULTPATH}get-auditor-checkList-certificate-template`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                },
                body: JSON.stringify({
                    checkListID,
                    userCheck: localStorage.getItem("AuditorID"),
                    orgID: localStorage.getItem("orgID")
                })
            }
        );
        const data = await response.json();
        if (data) {
            console.log("response", data);
            // mainData = await data.data[0].data.entries;
            mainData = await data;
            setProductData(mainData.data);

            setOrgNum(mainData.data.length)
            // console.log(mainData)
            var ACtiveCounter = 0;
            var InactiveCounter = 0
            for (var i = 0; i < mainData.data.length; i++) {
                if (mainData.data[i].Status === "Active") {
                    ACtiveCounter += 1;
                }
                else {
                    InactiveCounter += 1;
                }
            }
            setActiveOrg(ACtiveCounter);
            setInActiveOrg(InactiveCounter)
            // console.log("tempData", TempData)
            // setProductData(TempData);
            // setIpCardPrice(TempData[1].Value.length)
            // setdomainCard(TempData[0].Value.length)
            // setSubdomainCardPrice(TempData[2].Value.length)
        } else {
            // alert("data retreival error");
        }
    }

    // function to send data to backend 
    async function updateRow(event) {
        const response = await fetch(
            `${process.env.REACT_APP_DEFAULTPATH}/auditee-list`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    OrgID: OrganizaionID,
                    OrgName,
                    status
                }),
            }
        );
        const data = await response.json();
        if (data.status) {
        }
        else {
            setemailError("Email already exist")
        }

    }


    // Swwet alert or modal to confirm detials  should modify for perosnal Details 
    function updatePersonalDetails_sweetAlert() {
        // console.log("selected GRoup", status)
        swal({
            title: "Are you sure?",
            text: "Once done, you will update your personal details!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    if (updateRow()) {
                        swal("Poof! Your perosnal details has been updated!", {
                            icon: "success",
                            buttons: true
                        }).then((reload) => {
                            if (reload) {
                                window.location.reload(false);
                            }
                        })
                    }
                    else {
                        swal("Reload operation has been aborted!");
                    }
                } else {
                    swal("Operation has been aborted!");
                }
            });
    }

    // functions to validate regex OrgName
    function checkOrgNameValidation(OrgName_val) {
        if (OrgName_val === " ") {
            setOrgNameError("enter first name")
            setdetailBtn(true)
        }
        else if (name_regx.test(OrgName_val)) {
            setOrgNameError("")
            setdetailBtn(false)
        }
        else if (OrgName_val.length < 3) {
            setOrgNameError(" must contain 3 characters")
            setdetailBtn(true)
        }
        else {
            setOrgNameError(`Organization doesn't contain "0-9  , & @ "`)
            setdetailBtn(true)
        }
    }

    // function to handle change in status dropdown
    function handleSelectOptions(selectedOptions) {
        setselectedOptions(selectedOptions)
        setdetailBtn(false)
    }

    return (
        <React.Fragment>
            <div className="userProfile credentialBreachProfile">
                <MetaTags>
                    <title>Auditee | AuditSense</title>
                </MetaTags>
                <Row>
                    <Breadcrumbs
                        title="Dashboard"
                        breadcrumbItem="Report Template"
                        breadcrumbFeature="Report"
                    />
                </Row>

                {(() => {
                    if (role === "Admin") {
                        return (
                            <div className="text-end">
                                <Button className="btn-success" onClick={() => {
                                    // console.log("asdas")
                                    tog_standard_add_auditee()
                                }}><i className="bx bx-data"></i> &nbsp; Add Certificate</Button>
                            </div>
                        )
                    }
                })()}

                {/* <Row>
                    <Col xl={12}>
                        <Card>
                            <CardBody>
                                <Row>

                                    <Col xl={2} md={6} className="" >
                                        <Card className="card-h-100 bg-glassmorphismCard" style={{ borderRadius: `14px` }}>
                                            <CardBody className="glassmorphismCard" style={{ background: `` }}>
                                                <Row className="align-items-center">
                                                    <Col xs={12}>
                                                        <span className="text-muted mb-3 lh-1 d-block textWhiteCard" style={{ fontSize: "21px", fontWeight: "800" }}>
                                                            Organizations <br />
                                                            <FeatherIcon icon="briefcase" className="mt-3" />
                                                        </span>
                                                        <h3 className=" text-white text-center" style={{ fontSize: "50px", marginTop: "20px" }}>
                                                            <span className="counter-value">
                                                                <CountUp
                                                                    start={0}
                                                                    end={orgNum}
                                                                    duration={1}
                                                                />
                                                            </span>
                                                        </h3>
                                                    </Col>
                                                </Row>
                                                <div className="d-flex justify-content-between">

                                                    <div className="text-nowrap d-flex flex-column">
                                                        <span className="ms-1 text-muted font-size-17 textWhiteCard" >

                                                            <Badge className="me-2 bg-success">
                                                                Active
                                                            </Badge>

                                                        </span>
                                                        <span
                                                            className={
                                                                "badge badge-soft font-size-15"
                                                            }
                                                        >
                                                            {activeOrg}
                                                        </span>
                                                    </div>
                                                    <div className="text-nowrap d-flex flex-column">
                                                        <span className="ms-1 text-muted font-size-17 textWhiteCard" >
                                                            <Badge className="me-2 bg-danger">
                                                                InActive
                                                            </Badge>
                                                        </span>
                                                        <span
                                                            className={
                                                                "badge badge-soft font-size-15"
                                                            }
                                                        >
                                                            {InActiveOrg}
                                                        </span>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row> */}


                <Row>

                    <Modal
                        isOpen={modal_standard4}
                        toggle={() => {
                            tog_standard4();
                        }}
                    >
                        <div className="modal-header">
                            <h5 className="modal-title mt-0" id="myModalLabel">
                                Domain Details
                            </h5>
                            <button
                                type="button"
                                onClick={() => {
                                    setmodal_standard4(false);
                                }}
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">

                            <div className="d-flex flex-column h-100">
                                <div className="auth-content my-auto">
                                    <AvForm
                                        className="needs-validation custom-form mt-4 pt-2"
                                    // onValidSubmit={registerUser}
                                    >

                                        <div className="contact">
                                            <div className="mb-3 contactEmail">
                                                <label htmlFor="OrganizaionID">OrganizaionID</label>
                                                <Input
                                                    name="OrganizaionID"
                                                    label="OrganizaionID"
                                                    className="form-control"
                                                    placeholder="Enter OrganizaionID"
                                                    type="OrganizaionID"
                                                    value={OrganizaionID}
                                                    required
                                                    disabled={true}
                                                    id="validationTooltip01"
                                                // onChange={(event) => {
                                                //     onChangeValidationEmail("email", event.target.value);
                                                // }}
                                                />
                                                {/* <span className="validate-span">{emailError}</span> */}
                                            </div>
                                            <div className="mb-3 contactEmail">
                                                <label htmlFor="UserCount">UserCount</label>
                                                <Input
                                                    name="UserCount"
                                                    label="UserCount"
                                                    className="form-control"
                                                    placeholder="Enter UserCount"
                                                    type="UserCount"
                                                    value={UserCount}
                                                    required
                                                    disabled={true}
                                                    id="validationTooltip01"
                                                // onChange={(event) => {
                                                //     onChangeValidationEmail("email", event.target.value);
                                                // }}
                                                />
                                                {/* <span className="validate-span">{emailError}</span> */}
                                            </div>
                                        </div>

                                        <div className="mb-3 jobName">
                                            <div className="mb-3">
                                                <label htmlFor="choices-single-default" className="form-label font-size-14 ">Status</label>
                                                <Select
                                                    value={selectedOptions}
                                                    onChange={(e) => {
                                                        // console.log(e.value)
                                                        setstatus(e.value)
                                                        handleSelectOptions()
                                                    }}
                                                    options={optionGroup}
                                                    classNamePrefix="select2-selection"
                                                />
                                            </div>
                                        </div>

                                        <div className="mb-3 jobName">
                                            <label htmlFor="OrgName">Job Title</label>
                                            <Input
                                                name="OrgName"
                                                label="OrgName"
                                                className="form-control"
                                                type="name"
                                                value={OrgName}
                                                required
                                                placeholder="Enter Job Title : Software Developer , CTO ...."

                                                id="validationTooltip01"
                                                onChange={(e) => {
                                                    setOrgName(e.target.value)
                                                    checkOrgNameValidation(e.target.value)
                                                }}
                                            />
                                            <span className="validate-span">{OrgNameError}</span>
                                        </div>

                                        {/* <div className="mb-3 orgName">
                                                <label htmlFor="organization">
                                                    Organization Name
                                                </label>
                                                <Input
                                                    name="organization"
                                                    label="organization"
                                                    className="form-control"
                                                    type="name"
                                                    value={organization}
                                                    required
                                                    placeholder="Enter Organization "

                                                    id="validationTooltip01"
                                                    onChange={(event) => {
                                                        onChangeValidationOrg("org", event.target.value);
                                                    }}
                                                    valid={validation["org"] === true}
                                                    invalid={
                                                        validation["org"] !== true &&
                                                        validation["org"] !== null
                                                    }
                                                />
                                                <span className="validate-span">{OrgError}</span>
                                            </div> */}
                                        {/* <div className="mb-3">
                                                <label htmlFor="pass">Password</label>
                                                <Input
                                                    name="password"
                                                    label="Password"
                                                    className="form-control"
                                                    type="password"
                                                    required
                                                    placeholder="Enter Password"
                                                    id="validationTooltip01"
                                                    onChange={(event) => {
                                                        onChangeValidationPassword("pass", event.target.value);
                                                    }}
                                                    valid={validation["pass"] === true}
                                                    invalid={
                                                        validation["pass"] !== true &&
                                                        validation["pass"] !== null
                                                    }
                                                />
                                                <span className="validate-span">{passError}</span>
                                            </div> */}
                                        {/* <div className="mb-4">
                                                <Input
                                                    id="check"
                                                    name="check"
                                                    label="check"
                                                    type="checkbox"
                                                    onChange={(event) => {
                                                        checkBtnStatus(event.target.value);
                                                    }}
                                                    required
                                                    placeholder="Enter Password"
                                                />
                                                <label htmlFor="check">
                                                    <p className="mb-0">
                                                        I confirm that I have read and that I agree to the {" "}
                                                        <Link to="#" className="text-primary">
                                                            Terms of Service.
                                                        </Link>
                                                    </p>
                                                </label>
                                            </div> */}
                                        {/* <div className="mb-3 w-100 text-end">
                                                <button
                                                    className="btn btn-primary w-50 waves-effect waves-light "
                                                    type="submit"
                                                    disabled={registerBtnStatus}
                                                >
                                                    SaveDetails
                                                    <FeatherIcon icon="arrow-right" size={18} className="ms-2" />
                                                </button>
                                            </div> */}
                                    </AvForm>
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                onClick={() => {
                                    tog_standard4();
                                    setmodal_standard4(false);
                                }}
                                className="btn btn-warning "
                                data-dismiss="modal"
                            >
                                Close
                            </button>
                            <button
                                type="button"
                                onClick={() => {
                                    updatePersonalDetails_sweetAlert()
                                }}
                                disabled={detailBtn}
                                className="btn btn-warning "
                                data-dismiss="modal"
                            >
                                Save
                            </button>
                        </div>
                    </Modal>
                    <Row className="content">
                        <Col
                            lg={4}
                            md={5}
                            className="col-xxl-9 userData credentialData"
                        >
                            <div className="auth-full-page-content d-flex p-sm-5 p-4">
                                <div className="w-100">
                                    <div className="d-flex flex-column">
                                        <div className="  text-center">
                                            <Link
                                                to="/dashboard"
                                                className="d-block auth-logo"
                                            >
                                            </Link>
                                        </div>
                                        <div className="auth-content my-auto">

                                            <div className="mainTable">
                                                <CardBody>
                                                    <PaginationProvider
                                                        pagination={paginationFactory(
                                                            pageOptions
                                                        )}
                                                    >
                                                        {({
                                                            paginationProps,
                                                            paginationTableProps,
                                                        }) => (
                                                            <ToolkitProvider
                                                                keyField="id"
                                                                columns={columns}
                                                                data={productData}
                                                                search
                                                            >
                                                                {(toolkitProps) => (
                                                                    <React.Fragment>
                                                                        <Row className="mb-2">
                                                                            <Col>
                                                                                <div className="search-box me-2 mb-2 d-flex justify-content-between">
                                                                                    <div className="d-inline">
                                                                                        <SizePerPageDropdownStandalone
                                                                                            {...paginationProps}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="position-relative">
                                                                                        <SearchBar
                                                                                            {...toolkitProps.searchProps}
                                                                                        />
                                                                                        <i className="bx bx-search-alt search-icon" />
                                                                                    </div>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>

                                                                        <Row>
                                                                            <Col xl="12">
                                                                                <div className="table-responsive">
                                                                                    <BootstrapTable
                                                                                        keyField={"id"}
                                                                                        responsive
                                                                                        bordered={false}
                                                                                        striped={false}
                                                                                        defaultSorted={
                                                                                            defaultSorted
                                                                                        }
                                                                                        classes={
                                                                                            "table align-middle table-nowrap"
                                                                                        }
                                                                                        headerWrapperClasses={
                                                                                            "thead-light"
                                                                                        }
                                                                                        {...toolkitProps.baseProps}
                                                                                        {...paginationTableProps}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                        </Row>

                                                                        <Row className="align-items-md-center mt-30">
                                                                            <Col className="pagination pagination-rounded justify-content-end mb-2">
                                                                                <PaginationListStandalone
                                                                                    {...paginationProps}
                                                                                />
                                                                            </Col>
                                                                        </Row>

                                                                    </React.Fragment>
                                                                )}
                                                            </ToolkitProvider>
                                                        )}
                                                    </PaginationProvider>
                                                </CardBody>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Row>


                <Row>

                    <Modal
                        isOpen={modal_add_auditee}
                        toggle={() => {
                            tog_standard_add_auditee();
                        }}
                    >
                        <div className="modal-header">
                            <h5 className="modal-title mt-0" id="myModalLabel">
                                Add Certificate Template
                            </h5>
                            <button
                                type="button"
                                onClick={() => {
                                    setmodal_add_auditee(false);
                                }}
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">

                            <div className="d-flex flex-column h-100">

                                <div className='webApplication'>
                                    <Row>
                                        <Col lg={12}>

                                            <Row>
                                                <Col lg={12}>
                                                    <div>
                                                        {/* <h5 className="font-size-14 mb-4"><i className="mdi mdi-arrow-right text-primary me-1"></i> Form groups</h5> */}
                                                        <Form >


                                                            <Row className='my-2'>
                                                                <Col md={6}>
                                                                    <div className="mb-3">
                                                                        <Label className="form-label" htmlFor="companyLogo">TemplateName  : </Label>
                                                                        <Input type="text" className="form-control" id="companyLogo"
                                                                            onChange={(e) => {
                                                                                // console.log(e.target.value);
                                                                                setTemplateName(e.target.value);
                                                                            }} />
                                                                    </div>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <div className="mb-3">
                                                                        <Label className="form-label" htmlFor="employeeSize">Status  :  </Label>
                                                                        <Select
                                                                            // value={empSize}
                                                                            onChange={(e) => {
                                                                                // console.log(e.value)
                                                                                setcerificateStatus(e.value);
                                                                                // handleSelectOptions_empSize(e.value);
                                                                                // console.log(selectedOptions_empSize)
                                                                            }}
                                                                            options={optionGroup_status}
                                                                            classNamePrefix="select2-selection"
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Form>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                onClick={() => {
                                    tog_standard_add_auditee();
                                    setmodal_add_auditee(false);
                                }}
                                className="btn btn-warning "
                                data-dismiss="modal"
                            >
                                Close
                            </button>
                            <button
                                type="button"
                                onClick={() => {
                                    // updatePersonalDetails_sweetAlert()
                                    addVulnerabilities()
                                }}
                                className="btn btn-warning "
                            // data-dismiss="modal"
                            >
                                Save
                            </button>
                        </div>
                    </Modal>
                </Row>
            </div>
        </React.Fragment>
    );
};

export default CheckListCertificate;
