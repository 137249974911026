import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardTitle, Container, CardHeader, Table, Badge, Button, Spinner, InputGroup } from "reactstrap"
import MetaTags from 'react-meta-tags';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

const Dashboard2 = () => {

  const token = localStorage.getItem("token");
  useEffect(async () => {
    // const response = await fetch(`${process.env.REACT_APP_DEFAULTPATH}jwtauth`, {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify({
    //     token
    //   }),
    // })
    // const data = await response.json()
    // if (!data.status) {
    //   window.location.href = "/login";
    // }
  }, [])


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> Dashboard | AuditSense</title>
        </MetaTags>
        <Container fluid={true}>
          {/* Render Breadcrumb */}

          <Breadcrumbs
            title="Dashboard"
            breadcrumbItem="Dashboard"
            breadcrumbFeature=" "
          />


        </Container>
      </div>
    </React.Fragment>
  )
}

export default Dashboard2
