import React, { useState, useEffect } from "react";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import CountUp from "react-countup";


import FeatherIcon from "feather-icons-react";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// import "react-table/react-table.css";
import {
    Badge,
    CardBody,
    Col,
    Modal,
    Row,
    Form,
    Spinner
    ,
    Label,
    Input,
    Card,
    Button
} from "reactstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";

import MetaTags from "react-meta-tags";

//import images
import Breadcrumbs from "../../../components/Common/Breadcrumb";

import swal from 'sweetalert';
import Select from "react-select"
import axios from "axios"

const AddUsers = (props) => {

    // function to get uniques ID
    function uniqueIdGenerator() {
        var seq = (Math.floor(Math.random() * 100) + 100).toString().substring(1);
        const id = Date.now() + seq;
        return id;
    }


    const organizationId = localStorage.getItem("orgID") // to store userId from path 
    const AuditorID = localStorage.getItem("AuditorID")
    const [AuditorIDForDeleteUser, setAuditorIDForDeleteUser] = useState();

    const [productData, setProductData] = useState([]);
    const [modal_standard4, setmodal_standard4] = useState(false); // View More button Modal of ExposedCredentials

    // fto show chart data 
    const [orgNum, setOrgNum] = useState(0);
    const [activeOrg, setActiveOrg] = useState(0);
    const [InActiveOrg, setInActiveOrg] = useState(0);

    // values stored of form
    const [fname, setfname] = useState("");
    const [lname, setlname] = useState("");
    // const [jobTitle, setjobTitle] = useState("");
    const [email, setemail] = useState("");
    const [phone, setphone] = useState("");

    // change status 
    const [status, setstatus] = useState("");
    const [selectedOptions, setselectedOptions] = useState(null)

    // validation state error 
    const [fnameError, setfnameError] = useState("");
    const [lnameError, setlnameError] = useState("");
    const [emailError, setemailError] = useState("");
    const [passwordError, setpasswordError] = useState("");
    const [phoneError, setphoneError] = useState("");
    const [jobTiteError, setjobTiteError] = useState("");
    const [roleError, setroleError] = useState("");
    const [genderError, setgenderError] = useState("");
    const [statusError, setstatusError] = useState("");
    const [formUserID, setformUserID] = useState(""); // used to send userID of row to backend
    const [detailBtn, setdetailBtn] = useState(true); // to disable the button on inital


    // const orgID = localStorage.getItem("orgID");
    const token = localStorage.getItem("token");


    const [companyName, setcompanyName] = useState();
    const [companyLogo, setcompanyLogo] = useState();
    // const [empSize, setempSize] = useState();
    const [firstName, setfirstName] = useState("");
    const [lastName, setlastName] = useState("");
    const [empEmail, setempEmail] = useState("");
    const [jobTitle, setjobTitle] = useState("");
    const [empType, setempType] = useState("");
    const [role, setrole] = useState("");
    const [empPhone, setempPhone] = useState("");
    const [gender, setgender] = useState("");
    const [empStatus, setempStatus] = useState("");
    const [empPassword, setempPassword] = useState("");
    // const [gender, setgender] = useState();
    const [LoginRole, setLoginRole] = useState();

    const [status_empSize, setstatus_empSize] = useState("");
    const [statusCountry, setstatusCountry] = useState("");
    const [companyStatus, setcompanyStatus] = useState();


    const [status_vulnerability_status, setstatus_vulnerability_status] = useState("");
    const [selectedOptions_vulnerability_status, setselectedOptions_vulnerability_status] = useState(null);
    const [usersPreloader, setusersPreloader] = useState(true)

    const [modal_add_auditee, setmodal_add_auditee] = useState(false); // View More button Modal of ExposedCredentials
    const [modal_view_user, setmodal_view_user] = useState(false); // View More button Modal of ExposedCredentials
    const [jobType, setjobType] = useState("")


    const optionGroup_empType = [
        { label: "Internal", value: "Internal" },
        { label: "External", value: "External" },
    ];


    const optionGroup_role_project_manager = [
        { label: "Auditor", value: "Auditor" },
        { label: "Team Leader", value: "Team Leader" },
    ];


    const optionGroup_role_team_leader = [
        { label: "Auditor", value: "Auditor" },
    ];



    const optionGroup_role_admin = [
        { label: "Project Manager", value: "Project Manager" },
        { label: "Team Leader", value: "Team Leader" },
        { label: "Auditor", value: "Auditor" },
        { label: "Sales", value: "Sales" },
        { label: "Finance", value: "Finance" },
        { label: "Marketing", value: "Marketing" },
        { label: "Support", value: "Support" },
    ];
    const optionGroup_gender = [
        { label: "Male", value: "Male" },
        { label: "Female", value: "user" },
    ];
    const optionGroup_empStatus = [
        { label: "Enabled", value: "Enabled" },
        { label: "Disabled", value: "Disabled" },
    ];
    const optionGroup_jobTitle = [
        { label: "Associate InfoSec Consultant", value: "Associate InfoSec Consultant" },
        { label: "InfoSec Consultant", value: "InfoSec Consultant" },
        { label: "Senior InfoSec Consultant", value: "Senior InfoSec Consultant" },
        { label: "Principal InfoSec Consultant", value: "Principal InfoSec Consultant" },
        { label: "Sales Manager", value: "Sales Manager" },
        { label: "Marketing Manager", value: "Marketing Manager" },
        { label: "Support Manager", value: "Support Manager" },
        { label: "Finance Manager", value: "Finance Manager" },
    ];

    function tog_standard_add_auditee() {
        setmodal_add_auditee(!modal_add_auditee);
        // toggle for view more button 
    }


    function tog_standard_view_user() {
        setmodal_view_user(!modal_view_user);
        // toggle for view more button 
    }

    async function disable_user(auditorID, status) {
        const response = await fetch(`${process.env.REACT_APP_DEFAULTPATH}change-status-auditor-user`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            },
            body: JSON.stringify({
                auditorID, loginUser,
                status,
                userCheck: localStorage.getItem("AuditorID"),
                orgID: localStorage.getItem("orgID")
            }),
        })
        const data = await response.json()
        if (data.status) {
            return true;
        }
        else {
            return false;
        }
    }
    function disable_alert(auditorID, status) {
        swal({
            title: "Are you sure?",
            text: "Once done, you will update your personal details!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    if (disable_user(auditorID, status)) {
                        swal("Poof! Your perosnal details has been updated!", {
                            icon: "success",
                            buttons: true
                        }).then((reload) => {
                            if (reload) {
                                // window.location.reload(false);
                                getuserlist()
                            }
                        })
                    }
                    else {
                        swal("Reload operation has been aborted!");
                    }
                } else {
                    swal("Operation has been aborted!");
                }
            });
    }

    const optionGroup = [
        { label: "Active", value: "Active" },
        { label: "InActive", value: "InActive" },
    ]
    // Columns of DataTable
    const columns = [
        {
            dataField: "AuditorID",
            text: "User ID",
            sort: true,
            formatter: (cellContent, productData) => (
                <>
                    {(() => {
                        if (productData.AuditorID === null) {
                            return <>-</>;
                        } else {
                            return <>
                                <Badge className="me-2 bg-primary p-1 fs-6">
                                    {productData.AuditorID}
                                </Badge></>;
                        }
                    })()}
                </>
            ),
        },
        {
            dataField: "Email",
            text: "Email",
            sort: true,
            formatter: (cellContent, productData) => (
                <>
                    {(() => {
                        if (productData.Email === null) {
                            return <>-</>;
                        } else {
                            return <>{productData.Email}</>;
                        }
                    })()}
                </>
            ),
        },
        {
            dataField: "FirstName",
            text: "First Name",
            sort: true,
            formatter: (cellContent, productData) => (
                <>
                    {(() => {
                        if (productData.FirstName === null) {
                            return <>-</>;
                        } else {
                            return <>{productData.FirstName}</>;
                        }
                    })()}
                </>
            ),
        },
        {
            dataField: "LastName",
            text: "Last Name",
            sort: true,
            formatter: (cellContent, productData) => (
                <>
                    {(() => {
                        if (productData.LastName === null) {
                            return <>-</>;
                        } else {
                            return <>{productData.LastName}</>;
                        }
                    })()}
                </>
            ),
        },
        {
            dataField: "Role",
            text: "Role",
            sort: true,
            formatter: (cellContent, productData) => (
                <>
                    {(() => {
                        if (productData.Role === null) {
                            return <>-</>;
                        } else {
                            return <><Badge pill className="badge-soft-danger ms-1 fs-6  ">{productData.Role}</Badge></>;
                        }
                    })()}
                </>
            ),
        },
        {
            dataField: "Mobile",
            text: "Phone",
            sort: true,
            formatter: (cellContent, productData) => (
                <>
                    {(() => {
                        if (productData.Mobile === null) {
                            return <>-</>;
                        } else {
                            return <>{productData.Mobile}</>;
                        }
                    })()}
                </>
            ),
        },
        {
            dataField: "Designation",
            text: "Job Designation",
            sort: true,
            formatter: (cellContent, productData) => (
                <>
                    {(() => {
                        if (productData.Designation === null) {
                            return <>-</>;
                        } else {
                            return <>{productData.Designation}</>;
                        }
                    })()}
                </>
            ),
        },
        {
            dataField: "Status",
            text: "Status",
            sort: true,
            formatter: (cellContent, productData) => (
                <>
                    {(() => {
                        if (productData.Status === null) {
                            return <>-</>;
                        } else if (productData.Status === "Enabled") {
                            return <> <Badge pill className="badge-soft-success ms-1 fs-6  ">{productData.Status}</Badge></>;
                        } else if (productData.Status === "Disabled") {
                            return <> <Badge pill className="badge-soft-danger ms-1  fs-6 ">{productData.Status}</Badge></>;
                        }
                    })()}
                </>
            ),
        },
        {
            dataField: "Action",
            text: "Action",
            sort: true,
            formatter: (cellContent, productData) => (

                <>
                    {/* {console.log("without cliecekd", productData.Domains)} */}



                    {(() => {
                        console.log("userrole", Userrole)
                        if (Userrole === "Admin") {
                            return (
                                <>
                                    <Button
                                        className="btn-sm me-2"
                                        color="warning"
                                        onClick={() => {
                                            console.log("cliecekd", productData);
                                            setfirstName(productData.FirstName)
                                            setlastName(productData.LastName)
                                            // setemail(productData.Email)
                                            setempPhone(productData.Mobile)
                                            setempStatus(productData.Status)
                                            setrole(productData.Role)
                                            setjobTitle(productData.JobTitle)
                                            setformUserID(productData.AuditorID)

                                            setgender(productData.Gender)
                                            tog_standard4();
                                        }}
                                    >
                                        Edit
                                    </Button>
                                    {/* <Button
                                        className="btn-sm me-2"
                                        color="danger"
                                        // disabled={Testrole === "Admin" ? false : true}

                                        onClick={() => {
                                            // console.log("cliecekd", productData.AuditorID);
                                            // const mainId = productData.AuditorID
                                            // setAuditorIDForDeleteUser(mainId);
                                            // swal({
                                            //     title: "Are you sure?",
                                            //     text: "Once deleted, you will not be able to recover.",
                                            //     icon: "warning",
                                            //     buttons: true,
                                            //     dangerMode: true,
                                            // })
                                            //     .then((willDelete) => {
                                            //         if (willDelete) {
                                            //             // console.log(AuditorIDForDeleteUser)
                                            //             deleteUser(productData.AuditorID);
                                            //             swal("Poof! Your  file has been deleted!", {
                                            //                 icon: "success",
                                            //             })
                                            //             .then((willDelete)=>{
                                            //                 if(willDelete){
                                            //                     // window.location.reload()
                                            //                     getuserlist()
                                            //                 }
                                            //             })
                                            //         } else {
                                            //             swal("Your  file is safe!");
                                            //         }
                                            //     });


                                            // setfname(productData.FirstName)
                                            // setlname(productData.LastName)
                                            // setemail(productData.Email)
                                            // setphone(productData.Phone)
                                            // setjobTitle(productData.JobTitle)
                                            // setformUserID(productData.UserID)
                                            // tog_standard4();
                                        }}
                                    >

                                        Delete

                                    </Button> */}


                                    {(() => {
                                        if (productData.Status === "Enabled") {
                                            return <Button
                                                color="danger"
                                                className="btn-sm me-2"
                                                // disabled={role === "Admin" ? false : true}
                                                onClick={() => {
                                                    disable_alert(productData.AuditorID, productData.Status)
                                                    // tog_standard4();
                                                    // // setmodalPopupData(productData.UserCount)
                                                    // setOrganizaionID(productData.OrganizationID)
                                                    // setUserCount(productData.UserCount.length)
                                                    // setOrgName(productData.OrganizationName)
                                                }}
                                            >
                                                Disabled
                                            </Button>
                                        } else {
                                            return <Button
                                                color="success"
                                                className="btn-sm me-2"
                                                // disabled={role === "Admin" ? false : true}
                                                onClick={() => {
                                                    disable_alert(productData.AuditorID, productData.Status)
                                                    // tog_standard4();
                                                    // // setmodalPopupData(productData.UserCount)
                                                    // setOrganizaionID(productData.OrganizationID)
                                                    // setUserCount(productData.UserCount.length)
                                                    // setOrgName(productData.OrganizationName)
                                                }}
                                            >
                                                Enabled
                                            </Button>
                                        }
                                    })()}
                                </>
                            )
                        }
                    })()}

                    <Button
                        color="info"
                        className="btn-sm me-2"
                        // disabled={role === "Admin" ? false : true}
                        onClick={() => {
                            // tog_standard4();
                            console.log("this is me ", productData)
                            setfirstName(productData.FirstName)
                            setlastName(productData.LastName)
                            setempEmail(productData.Email)
                            setjobType(productData.Designation)
                            setrole(productData.Role)
                            setstatus(productData.Status)
                            tog_standard_view_user()

                            // setmodalPopupData(productData.UserCount)
                            // setOrganizaionID(productData.OrganizationID)
                            // setUserCount(productData.UserCount.length)
                            // setOrgName(productData.OrganizationName)
                        }}
                    >
                        View
                    </Button>





                    {/* {console.log("without cliecekd", productData.AuditorID)} */}

                </>
            ),
        },

        // {
        //     dataField: "More",
        //     text: "View Details",
        //     sort: true,
        //     formatter: (cellContent, productData) => (

        //         <>
        //             <AvForm>
        //                 {/* {console.log("without cliecekd", productData.Domains)} */}
        //                 <Button
        //                     color="primary"
        //                     onClick={() => {
        //                         // console.log("cliecekd", [productData]);
        //                         tog_standard4();
        //                         setmodalPopupData(productData.UserCount)
        //                     }}
        //                 >
        //                     View More
        //                 </Button>
        //             </AvForm>
        //         </>
        //     ),
        // },
    ];
    // Sorting of Data Table
    const defaultSorted = [
        {
            dataField: "id",
            order: "asc",
        },
    ];

    // Page Options in Data Table 
    const pageOptions = {
        sizePerPage: 10,
        totalSize: productData.length, // replace later with size(customers),
        custom: true,
    };
    const { SearchBar } = Search; // Search Bar in Data TAble

    var mainData = [];
    var phone_regx = /^\d{9,20}$/;
    let name_regx = /^[A-Za-z ]{3,20}$/;

    var email_regx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,15})+$/;
    var pass_regx = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/;


    //functions -----------------------------> 
    useEffect(async () => {

        await getUser();
        getuserlist();
    }, []);




    async function addVulnerabilities() {
        var formData = new FormData();
        // formData.append("companyName", companyName);
        // formData.append("companyLogo", companyLogo[0] , "companyLogo.png");
        // // formData.append("status_severity", status_severity);
        // formData.append("empSize", status_empSize);
        // formData.append("companyEmail", companyEmail);
        // formData.append("companyPhone", companyPhone);
        // formData.append("companyWebsite", companyWebsite);
        // formData.append("city", city);
        // formData.append("state", state);
        // formData.append("pincode", pincode);
        // formData.append("country", statusCountry);
        // formData.append("companyStatus", companyStatus);
        // formData.append("otherDetails", otherDetails);
        // formData.append("status_vulnerability_status", status_vulnerability_status);
        // formData.append("vulnerabilityImg1", vulnerabilityImg1[0], "vulnerability_status_img.png");
        // console.log(firstName, lastName, empEmail, phone, jobTitle, empType, role, gender, empPhone, empStatus, organizationId, AuditorID, empPassword)

        const AuditeeID = uniqueIdGenerator()
        try {
            await fetch(`${process.env.REACT_APP_DEFAULTPATH}add-auditor-user`, {
                method: "POST",
                headers: {
                    "content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                },
                body: JSON.stringify({
                    firstName, lastName, empEmail, jobTitle, role, gender, empPhone, empStatus, organizationId, empPassword, loginUser,
                    userCheck: localStorage.getItem("AuditorID"),
                    orgID: localStorage.getItem("orgID")
                })
            })
                .then((res) => res.json())
                .then((data) => {
                    if (data.status) {
                        // console.log("then", data)
                        // window.location.reload()
                        setfirstName("")
                        setlastName("")
                        setempEmail("")
                        setjobTitle("")
                        setrole("")
                        setempPhone("")
                        setgender("")
                        setempStatus("")
                        setempPassword("")
                        getuserlist()
                    }
                    else {
                        email_exist_error()
                    }
                })

        } catch (error) {
            console.log("error", error)
        }
    }



    async function editUserDetails() {
        // formData.append("companyName", companyName);
        // formData.append("companyLogo", companyLogo[0] , "companyLogo.png");
        // // formData.append("status_severity", status_severity);
        // formData.append("empSize", status_empSize);
        // formData.append("companyEmail", companyEmail);
        // formData.append("companyPhone", companyPhone);
        // formData.append("companyWebsite", companyWebsite);
        // formData.append("city", city);
        // formData.append("state", state);
        // formData.append("pincode", pincode);
        // formData.append("country", statusCountry);
        // formData.append("companyStatus", companyStatus);
        // formData.append("otherDetails", otherDetails);
        // formData.append("status_vulnerability_status", status_vulnerability_status);
        // formData.append("vulnerabilityImg1", vulnerabilityImg1[0], "vulnerability_status_img.png");
        // console.log(firstName, lastName, empEmail, phone, jobTitle, empType, role, gender, empPhone, empStatus, organizationId, AuditorID, empPassword)

        const AuditeeID = uniqueIdGenerator()
        try {
            await fetch(`${process.env.REACT_APP_DEFAULTPATH}edit-auditor-user`, {
                method: "POST",
                headers: {
                    "content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                },
                body: JSON.stringify({
                    firstName, lastName, jobTitle, role, gender, empPhone, empStatus, loginUser, formUserID,
                    userCheck: localStorage.getItem("AuditorID"),
                    orgID: localStorage.getItem("orgID")
                })
            }).
                then(() => {
                    // window.location.reload()
                    getuserlist()
                });

        } catch (error) {
            console.log(error)
        }
    }
    async function deleteUser(ID) {
        await fetch(`${process.env.REACT_APP_DEFAULTPATH}delete-auditor-user`, {
            method: "POST",
            headers: {
                "content-Type": "application/json"
            },
            body: JSON.stringify({
                auditorID: ID
            })
        }).then(() => {
            getuserlist()
        })
        // const data = await response.json();
        // console.log("responsed", data)
    }

    function tog_standard4() {
        setmodal_standard4(!modal_standard4);
        // toggle for view more button 
    }

    const userCheck = localStorage.getItem("AuditorID");
    const loginUser = localStorage.getItem("AuditorID");
    const [Userrole, setUserRole] = useState()
    // to get userLSit 
    async function getUser(event) {
        // event.preventDefault();
        // console.log("token passes " , userCheck)
        await axios.post(`${process.env.REACT_APP_DEFAULTPATH}check-login-role`, {
            userCheck: localStorage.getItem("AuditorID"),
            orgID: localStorage.getItem("orgID")
        }, {
            credentials: 'same-origin',
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            },
        }).then(
            (res) => {
                if (res.data.loggedIn) {
                    console.log("succeddeded", res.data.user.Role)
                    setUserRole(res.data.user.Role);
                    // setUserRole(newdata.Role)
                    // window.location.href = "/auditor/dashboard";
                }
                else {
                    console.log("not logged in ")
                }
            }
        )

        // const response = await fetch(`${process.env.REACT_APP_DEFAULTPATH}user-profile`, {
        //     method: "POST",
        //     headers: {
        //         "Content-Type": "application/json",
        //     },
        //     body: JSON.stringify({
        //         userCheck,
        //     }),
        // });
        // const data = await response.json();
        // if (data) {
        //     const newdata = await data.user;
        //     console.log("data retrived", newdata);
        //     setUserRole(newdata.Role)

        //     // setphone(newdata.Phone);
        //     // setjobTitle(newdata.JobTitle);
        //     // console.log("main profile", newdata.FirstName);
        // } else {
        //     alert("data retreival error");
        // }
    }
    const Testrole = localStorage.getItem("role")
    // function to load data on Tables
    async function getuserlist(event) {
        const response = await fetch(
            `${process.env.REACT_APP_DEFAULTPATH}get-auditor-userlist`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                },
                body: JSON.stringify({
                    organizationId,
                    AuditorID,
                    Testrole,
                    userCheck: localStorage.getItem("AuditorID"),
                    orgID: localStorage.getItem("orgID")
                }),
            }
        );
        const data = await response.json();
        if (data) {
            console.log("response", data);
            // mainData = await data.data[0].data.entries;
            mainData = await data;
            setusersPreloader(false)
            setProductData(mainData.data);
            setOrgNum(mainData.data.length)
            // console.log("maindata", mainData)
            var ACtiveCounter = 0;
            var InactiveCounter = 0
            for (var i = 0; i < mainData.data.length; i++) {
                if (mainData.data[i].Status === "Active") {
                    ACtiveCounter += 1;
                }
                else {
                    InactiveCounter += 1;
                }
            }
            setActiveOrg(ACtiveCounter);
            setInActiveOrg(InactiveCounter)
        } else {
            setusersPreloader(false)
            // alert("data retreival error");
        }
    }

    // function to send data to backend 
    async function updateRow(event) {
        const response = await fetch(
            `${process.env.REACT_APP_DEFAULTPATH}updateuser`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    userID: formUserID,
                    fname: fname.charAt(0).toUpperCase() + fname.slice(1),
                    lname: lname.charAt(0).toUpperCase() + lname.slice(1),
                    jobTitle,
                    phone,
                    status
                }),
            }
        );
        const data = await response.json();
        if (data.status) {
        }
        else {
            setemailError("Email already exist")
        }

    }

    // Swwet alert or modal to confirm detials  should modify for perosnal Details 
    function updatePersonalDetails_sweetAlert() {
        swal({
            title: "Are you sure?",
            text: "Once done, you will update your personal details!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    if (updateRow()) {
                        swal("Poof! Your perosnal details has been updated!", {
                            icon: "success",
                            buttons: true
                        }).then((reload) => {
                            if (reload) {
                                // window.location.reload(false);
                                getuserlist()
                            }
                        })
                    }
                    else {
                        swal("Reload operation has been aborted!");
                    }
                } else {
                    swal("Operation has been aborted!");
                }
            });
    }

    // Onchange functions ------------------------------------->>>>>>>>
    // functions to validate regex firstName
    function checkFnameValidation(fname_val) {
        if (fname_val === " ") {
            setfnameError("enter first name")
            setdetailBtn(true)
        }
        else if (name_regx.test(fname_val)) {
            setfnameError("")
            setdetailBtn(false)
        }
        else if (fname_val.length < 3) {
            setfnameError("name must contain 3 characters")
            setdetailBtn(true)
        }
        else {
            setfnameError(`name doesn't contain "0-9  , & @ "`)
            setdetailBtn(true)
        }
    }

    // functions to validate regex LastName
    function checklnameValidation(lname_val) {
        if (lname_val === " ") {
            setlnameError("enter first name")
            setdetailBtn(true)
        }
        else if (name_regx.test(lname_val)) {
            setlnameError("")
            setdetailBtn(false)
        }
        else if (lname_val.length < 3) {
            setlnameError("name must contain 3 characters")
            setdetailBtn(true)
        }
        else {
            setlnameError(`name doesn't contain "0-9  , & @ "`)
            setdetailBtn(true)
        }
    }
    // functions to validate regex email
    function checkEmailValidation(email_val) {
        if (email_val === " ") {
            setemailError("enter email");
        } else if (email_regx.test(email_val)) {
            setemailError("");
        } else if (email_val.length < 3) {
            setemailError("invalid email");
        } else {
            setemailError(`invalid email`);
        }
    }

    // functions to validate regex password
    function checkPasswordValidation(password_val) {
        if (password_val === " ") {
            setpasswordError("enter password");
        } else if (pass_regx.test(password_val)) {
            setpasswordError("");
        } else if (password_val.length < 3) {
            setpasswordError(" Invalid Passwors");
        } else {
            setpasswordError(`Invalid Password must contain "0-9  , & @ "`);
        }
    }
    // functions to validate regex Phone
    function checkphoneValidation(phone_val) {
        // console.log("phone val", phone_val)
        if (phone_val === " ") {
            setphoneError("enter first name")
            setdetailBtn(true)
        }
        else if (phone_regx.test(phone_val)) {
            setphoneError("")
            setdetailBtn(false)
        }
        else if (phone_val.length < 9) {
            setphoneError("minimum length 8 digit")
            setdetailBtn(true)
        }
        else {
            setphoneError("Invalid credentials - doesn't contain 'a-z , A-Z , @'")
            setdetailBtn(true)
        }
    }

    // functions to validate regex Jobtitle
    function checkjobTitleValidation(jobTitle_val) {
        if (jobTitle_val === " ") {
            setjobTiteError("enter first name")
            setdetailBtn(true)
        }
        else if (name_regx.test(jobTitle_val)) {
            setjobTiteError("")
            setdetailBtn(false)
        }
        else if (jobTitle_val.length < 3) {
            setjobTiteError(" must contain 3 characters")
            setdetailBtn(true)
        }
        else {
            setjobTiteError(`job title doesn't contain "0-9  , & @ "`)
            setdetailBtn(true)
        }
    }

    // form validatioon checkuo end ------------->


    // function to handle change in status dropdown
    function handleSelectOptions(selectedOptions) {
        setselectedOptions(selectedOptions)
        setdetailBtn(false)
    }
    // function checkFnameValidation(fname_val) {
    //     if (fname_val === " ") {
    //       setfnameError("enter first name");
    //     } else if (name_regx.test(fname_val)) {
    //       setfnameError("");
    //     } else if (fname_val.length < 3) {
    //       setfnameError("name must contain 3 characters");
    //     } else {
    //       setfnameError(`name doesn't contain "0-9  , & @ "`);
    //     }
    //   }

    // email exist error
    function email_exist_error() {
        swal({
            title: "Email already Exists",
            text: "Check email and try again",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
    }

    // Swwet alert or modal to confirm detials  should modify for perosnal Details 
    function save_user_alert() {
        swal({
            title: "Entered Wrong details",
            text: "Check each box and remove error",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
    }
    return (
        <React.Fragment>
            <div className="userProfile credentialBreachProfile">
                <MetaTags>
                    <title>Customers | AuditSense</title>
                </MetaTags>
                <Row>
                    <Breadcrumbs
                        title="Dashboard"
                        breadcrumbItem="Auditor User List"
                        breadcrumbFeature="Users"
                    />
                </Row>


                {(() => {
                    console.log("userrole", Userrole)
                    if (Userrole === "Admin") {
                        return (
                            <div className="text-end d-flex justify-content-end">
                                <Button className="btn-success d-flex align-items-center" onClick={() => {
                                    // console.log("asdas")
                                    setemail("")
                                    setempPassword("")
                                    tog_standard_add_auditee()
                                }}><i className="bx bx-user-plus fs-5"></i> &nbsp; Add Users</Button>
                            </div>
                        )
                    }
                })()}


                <Row>
                    {/* Edit Modal containing form  */}
                    <Modal
                        isOpen={modal_standard4}
                        toggle={() => {
                            tog_standard4();
                        }}
                        className="modal-xl"
                    >
                        <div className="modal-header">
                            <h5 className="modal-title mt-0" id="myModalLabel">
                                Edit User Details
                            </h5>
                            <button
                                type="button"
                                onClick={() => {
                                    setmodal_standard4(false);
                                }}
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">

                            <div className="d-flex flex-column h-100">

                                <div className='webApplication'>
                                    <Row>
                                        <Col lg={12}>

                                            <Row>
                                                <Col lg={12}>
                                                    <div>
                                                        {/* <h5 className="font-size-14 mb-4"><i className="mdi mdi-arrow-right text-primary me-1"></i> Form groups</h5> */}
                                                        <Form >

                                                            <Row className='my-2'>
                                                                <Col md={6}>
                                                                    <div className="mb-3">
                                                                        <Label className="form-label" htmlFor="firstName">First Name: </Label>
                                                                        <Input type="text" className="form-control" id="firstName"
                                                                            value={firstName}
                                                                            onChange={(e) => {
                                                                                // console.log(e.target.value);
                                                                                setfirstName(e.target.value);
                                                                                checkFnameValidation(e.target.value)
                                                                            }} />
                                                                        <span className="validate-span text-danger">
                                                                            {fnameError}
                                                                        </span>

                                                                    </div>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <div className="mb-3">
                                                                        <Label className="form-label" htmlFor="lastName">Last Name: </Label>
                                                                        <Input type="text" className="form-control" id="lastName"
                                                                            value={lastName}
                                                                            onChange={(e) => {
                                                                                // console.log(e.target.value);
                                                                                setlastName(e.target.value);
                                                                                checklnameValidation(e.target.value)
                                                                            }} />
                                                                        <span className="validate-span text-danger">
                                                                            {lnameError}
                                                                        </span>
                                                                    </div>
                                                                </Col>

                                                            </Row>


                                                            <Row className='my-2'>

                                                                <Col md={6}>
                                                                    <div className="mb-3">
                                                                        <Label className="form-label" htmlFor="jobType">Job Designation: </Label>
                                                                        <Select
                                                                            // value={empSize}
                                                                            placeholder={jobTitle}
                                                                            onChange={(e) => {
                                                                                // console.log(e.value)
                                                                                setjobTitle(e.value);
                                                                                // console.log(Userrole)
                                                                                // handleSelectOptions_empSize(e.value);
                                                                                // console.log(selectedOptions_empSize)
                                                                            }}
                                                                            options={optionGroup_jobTitle}
                                                                            classNamePrefix="select2-selection"
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                {/* <Col md={4}>
                                                                    <div className="mb-3">
                                                                        <Label className="form-label" htmlFor="role">Employee Type :  </Label>
                                                                        <Select
                                                                            // value={empSize}
                                                                            onChange={(e) => {
                                                                                // console.log(e.value)
                                                                                setempType(e.value);
                                                                                // handleSelectOptions_empSize(e.value);
                                                                                // console.log(selectedOptions_empSize)
                                                                            }}
                                                                            options={optionGroup_empType}
                                                                            classNamePrefix="select2-selection"
                                                                        />
                                                                    </div>
                                                                </Col> */}

                                                                <Col md={6}>
                                                                    <div className="mb-3">
                                                                        <Label className="form-label" htmlFor="role">Job Role :  </Label>
                                                                        <Select
                                                                            // value={empSize}
                                                                            placeholder={role}
                                                                            onChange={(e) => {
                                                                                // console.log(e.value)
                                                                                setrole(e.value);
                                                                                // console.log(Userrole)
                                                                                // handleSelectOptions_empSize(e.value);
                                                                                // console.log(selectedOptions_empSize)
                                                                            }}
                                                                            options={Userrole === "Admin" ? optionGroup_role_admin : Userrole === "Project Manager" ? optionGroup_role_project_manager : Userrole === "Team Leader" ? optionGroup_role_team_leader : optionGroup}
                                                                            classNamePrefix="select2-selection"
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row className='my-2'>

                                                                <Col md={4}>
                                                                    <div className="mb-3">
                                                                        <Label className="form-label" htmlFor="phone">Phone : </Label>
                                                                        <Input type="phone"
                                                                            value={empPhone}
                                                                            className="form-control" id="phone"
                                                                            onChange={(e) => {
                                                                                // console.log(e.target.value);
                                                                                setempPhone(e.target.value);
                                                                                checkphoneValidation(e.target.value)
                                                                            }} />
                                                                        <span className="validate-span text-danger">
                                                                            {phoneError}
                                                                        </span>
                                                                    </div>
                                                                </Col>
                                                                <Col md={4}>
                                                                    <div className="mb-3">
                                                                        <Label className="form-label" htmlFor="gender">Gender : </Label>
                                                                        <Select
                                                                            // value={empSize}
                                                                            placeholder={gender}
                                                                            onChange={(e) => {
                                                                                // console.log(e.value)
                                                                                setgender(e.value);
                                                                                // handleSelectOptions_empSize(e.value);
                                                                                // console.log(selectedOptions_empSize)
                                                                            }}
                                                                            options={optionGroup_gender}
                                                                            classNamePrefix="select2-selection"
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                <Col md={4}>
                                                                    <div className="mb-3">
                                                                        <Label className="form-label" htmlFor="empStatus">Status : </Label>
                                                                        <Select
                                                                            // value={empSize}
                                                                            placeholder={empStatus}
                                                                            onChange={(e) => {
                                                                                // console.log(e.value)
                                                                                setempStatus(e.value);
                                                                                // handleSelectOptions_empSize(e.value);
                                                                                // console.log(selectedOptions_empSize)
                                                                            }}
                                                                            options={optionGroup_empStatus}
                                                                            classNamePrefix="select2-selection"
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            <Row className='my-2'>


                                                            </Row>

                                                        </Form>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                onClick={() => {
                                    tog_standard4();
                                    setmodal_standard4(false);
                                }}
                                className="btn btn-secondary "
                                data-dismiss="modal"
                            >
                                Close
                            </button>
                            <button
                                type="button"
                                onClick={() => {
                                    // updatePersonalDetails_sweetAlert()
                                    if (firstName != "" && lastName != "" && empPhone != "" && fnameError === "" && lnameError === "" && phoneError === "") {

                                        editUserDetails()
                                        tog_standard4()

                                    }
                                    else {
                                        if (firstName === "") {
                                            setfnameError("first name cannot be empty")
                                        }
                                        if (lastName === "") {
                                            setlnameError("first name cannot be empty")
                                        }
                                        if (empPhone === "") {
                                            setphoneError("phone cannot be empty")
                                        }
                                        // setfnameError("name must contain 3 characters")
                                        save_user_alert()
                                    }
                                }}
                                // disabled={detailBtn}
                                className="btn btn-primary "
                                data-dismiss="modal"
                            >
                                Save
                            </button>
                        </div>
                    </Modal>

                    {/* Data Table  */}
                    {usersPreloader ?
                        <div className="transparent-loader">
                            <Spinner type="grow" className="ms-2" color="primary" /> </div> : <></>}
                    <Row className="content">
                        <Col
                            lg={4}
                            md={5}
                            className="col-xxl-9 userData credentialData"
                        >
                            <div className="auth-full-page-content d-flex p-sm-5 p-4">
                                <div className="w-100">
                                    <div className="d-flex flex-column">
                                        <div className="  text-center">
                                            <Link
                                                to="/dashboard"
                                                className="d-block auth-logo"
                                            >
                                            </Link>
                                        </div>
                                        <div className="auth-content my-auto">

                                            <div className="mainTable">
                                                <CardBody>
                                                    <PaginationProvider
                                                        pagination={paginationFactory(
                                                            pageOptions
                                                        )}
                                                    >
                                                        {({
                                                            paginationProps,
                                                            paginationTableProps,
                                                        }) => (
                                                            <ToolkitProvider
                                                                keyField="id"
                                                                columns={columns}
                                                                data={productData}
                                                                search
                                                            >
                                                                {(toolkitProps) => (
                                                                    <React.Fragment>
                                                                        <Row className="mb-2">
                                                                            <Col>
                                                                                <div className="search-box me-2 mb-2 d-flex justify-content-between">
                                                                                    <div className="d-inline">
                                                                                        <SizePerPageDropdownStandalone
                                                                                            {...paginationProps}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="position-relative">
                                                                                        <SearchBar
                                                                                            {...toolkitProps.searchProps}
                                                                                        />
                                                                                        <i className="bx bx-search-alt search-icon" />
                                                                                    </div>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>

                                                                        <Row>
                                                                            <Col xl="12">
                                                                                <div className="table-responsive">
                                                                                    <BootstrapTable
                                                                                        keyField={"id"}
                                                                                        responsive
                                                                                        bordered={false}
                                                                                        striped={false}
                                                                                        defaultSorted={
                                                                                            defaultSorted
                                                                                        }
                                                                                        classes={
                                                                                            "table align-middle table-nowrap"
                                                                                        }
                                                                                        headerWrapperClasses={
                                                                                            "thead-light"
                                                                                        }
                                                                                        {...toolkitProps.baseProps}
                                                                                        {...paginationTableProps}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                        </Row>

                                                                        <Row className="align-items-md-center mt-30">
                                                                            <Col className="pagination pagination-rounded justify-content-end mb-2">
                                                                                <PaginationListStandalone
                                                                                    {...paginationProps}
                                                                                />
                                                                            </Col>
                                                                        </Row>

                                                                    </React.Fragment>
                                                                )}
                                                            </ToolkitProvider>
                                                        )}
                                                    </PaginationProvider>
                                                </CardBody>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Row>


                {/* modal to view  user  */}
                <Modal
                    isOpen={modal_view_user}
                    toggle={() => {
                        tog_standard_view_user();
                    }}
                    className="modal-lg"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myModalLabel">
                            View User
                        </h5>
                        <button
                            type="button"
                            onClick={() => {
                                setmodal_view_user(false);
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div className="modal-body">

                        <div className="d-flex flex-column h-100">

                            <div className='webApplication'>
                                <Form >
                                    <Row className='my-2'>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="employeeSize">FirstName *  </Label>
                                                <div className="text-secondary">{firstName}</div>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="employeeSize">LastName *  </Label>
                                                <div className="text-secondary">{lastName}</div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className='my-2'>

                                        <Col md={6}>
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="employeeSize">Email *  </Label>
                                                <div className="text-secondary">{empEmail}</div>
                                            </div>
                                        </Col>

                                        <Col md={6}>
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="employeeSize"> JobType *  </Label>
                                                <div className="text-secondary">{jobType}</div>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className='my-2'>

                                        <Col md={4}>
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="companyLogo">Status * </Label>
                                                <div>  {(() => {

                                                    if (empStatus === null) {
                                                        return <>-</>;
                                                    } else if (empStatus === "Enable") {
                                                        return <> <Badge pill className="badge-soft-success ms-1  fs-6">{empStatus}</Badge></>;
                                                    } else if (empStatus === "Disable") {
                                                        return <> <Badge pill className="badge-soft-danger ms-1  fs-6">{empStatus}</Badge>

                                                        </>;
                                                    } else if (empStatus === "pending") {
                                                        return <> <Badge pill className="badge-soft-warning ms-1  fs-6">{empStatus}</Badge>

                                                        </>;
                                                    }
                                                })()}</div>
                                            </div>
                                        </Col>

                                        <Col md={6}>
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="employeeSize"> Role *  </Label>
                                                <div className="text-secondary">{role}</div>
                                            </div>
                                        </Col>
                                    </Row>


                                    {/* <Row className='my-3'>

                                        <Col md={4}>
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="employeeSize">Phone *  </Label>
                                                <div className="text-secondary">{empPhone}</div>
                                            </div>
                                        </Col>
                                        <Col md={4}>
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="employeeSize">Gender *  </Label>
                                                <div className="text-secondary">{gender}</div>
                                            </div>
                                        </Col>

                                       
                                    </Row> */}



                                </Form>
                            </div>
                        </div>

                    </div>

                    <div className="modal-footer">
                        <button
                            type="button"
                            onClick={() => {
                                tog_standard_view_user();
                                setmodal_view_user(false);
                            }}
                            className="btn btn-warning "
                            data-dismiss="modal"
                        >
                            Close
                        </button>
                    </div>
                </Modal>


                <Row>

                    {/* Add user modal  */}
                    <Modal
                        isOpen={modal_add_auditee}
                        toggle={() => {
                            tog_standard_add_auditee();
                        }}
                        className="modal-xl"
                    >
                        <div className="modal-header">
                            <h5 className="modal-title mt-0" id="myModalLabel">
                                Add Users
                            </h5>
                            <button
                                type="button"
                                onClick={() => {
                                    setmodal_add_auditee(false);
                                }}
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">

                            <Form >

                                <Row className='my-2'>
                                    <Col md={6}>
                                        <div className="mb-3">
                                            <Label className="form-label" htmlFor="firstName">First Name: </Label>
                                            <Input type="text" className="form-control" id="firstName"
                                                onChange={(e) => {
                                                    // console.log(e.target.value);
                                                    setfirstName(e.target.value);
                                                    checkFnameValidation(e.target.value);
                                                }} />
                                            <span className="validate-span text-danger">
                                                {fnameError}
                                            </span>
                                        </div>

                                    </Col>
                                    <Col md={6}>
                                        <div className="mb-3">
                                            <Label className="form-label" htmlFor="lastName">Last Name: </Label>
                                            <Input type="text" className="form-control" id="lastName"
                                                onChange={(e) => {
                                                    // console.log(e.target.value);
                                                    setlastName(e.target.value);
                                                    checklnameValidation(e.target.value);
                                                }} />
                                            <span className="validate-span text-danger">
                                                {lnameError}
                                            </span>
                                        </div>
                                    </Col>

                                </Row>

                                <Row className='my-2'>

                                    <Col md={6}>
                                        <div className="mb-3">
                                            <Label className="form-label" htmlFor="email"> Email : </Label>
                                            <Input type="text" className="form-control" id="email"
                                                onChange={(e) => {
                                                    // console.log(e.target.value);
                                                    setempEmail(e.target.value);
                                                    checkEmailValidation(e.target.value);
                                                }} />
                                            <span className="validate-span text-danger">
                                                {emailError}
                                            </span>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="mb-3">
                                            <Label className="form-label" htmlFor="email"> Password : </Label>
                                            <Input type="password" className="form-control" id="email"
                                                onChange={(e) => {
                                                    // console.log(e.target.value);
                                                    setempPassword(e.target.value);
                                                    checkPasswordValidation(e.target.value);
                                                }} />
                                            <span className="validate-span text-danger">
                                                {passwordError}
                                            </span>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className='my-2'>

                                    <Col md={6}>
                                        <div className="mb-3">
                                            <Label className="form-label" htmlFor="jobType">Job Designation: </Label>
                                            <Select
                                                // value={empSize}
                                                onChange={(e) => {
                                                    // console.log(e.value)
                                                    setjobTitle(e.value);
                                                    setjobTiteError("")
                                                    // console.log(Userrole)
                                                    // handleSelectOptions_empSize(e.value);
                                                    // console.log(selectedOptions_empSize)
                                                }}
                                                options={optionGroup_jobTitle}
                                                classNamePrefix="select2-selection"
                                            /><span className="validate-span text-danger">
                                                {jobTiteError}
                                            </span>
                                        </div>
                                    </Col>
                                    {/* <Col md={4}>
        <div className="mb-3">
            <Label className="form-label" htmlFor="role">Employee Type :  </Label>
            <Select
                // value={empSize}
                onChange={(e) => {
                    // console.log(e.value)
                    setempType(e.value);
                    // handleSelectOptions_empSize(e.value);
                    // console.log(selectedOptions_empSize)
                }}
                options={optionGroup_empType}
                classNamePrefix="select2-selection"
            />
        </div>
    </Col> */}

                                    <Col md={6}>
                                        <div className="mb-3">
                                            <Label className="form-label" htmlFor="role">Job Role:  </Label>
                                            <Select
                                                // value={empSize}
                                                onChange={(e) => {
                                                    // console.log(e.value)
                                                    setrole(e.value);
                                                    setroleError("")
                                                    // console.log(Userrole)
                                                    // handleSelectOptions_empSize(e.value);
                                                    // console.log(selectedOptions_empSize)
                                                }}
                                                options={optionGroup_role_admin}
                                                classNamePrefix="select2-selection"
                                            /><span className="validate-span text-danger">
                                                {roleError}
                                            </span>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='my-2'>

                                    <Col md={4}>
                                        <div className="mb-3">
                                            <Label className="form-label" htmlFor="phone">Phone : </Label>
                                            <Input type="phone" className="form-control" id="phone"
                                                onChange={(e) => {
                                                    // console.log(e.target.value);
                                                    setempPhone(e.target.value);
                                                    checkphoneValidation(e.target.value)
                                                }} />
                                            <span className="validate-span text-danger">
                                                {phoneError}
                                            </span>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className="mb-3">
                                            <Label className="form-label" htmlFor="gender">Gender : </Label>
                                            <Select
                                                // value={empSize}
                                                onChange={(e) => {
                                                    // console.log(e.value)
                                                    setgender(e.value);
                                                    setgenderError("")
                                                    // handleSelectOptions_empSize(e.value);
                                                    // console.log(selectedOptions_empSize)
                                                }}
                                                options={optionGroup_gender}
                                                classNamePrefix="select2-selection"
                                            /><span className="validate-span text-danger">
                                                {genderError}
                                            </span>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className="mb-3">
                                            <Label className="form-label" htmlFor="empStatus">Status : </Label>
                                            <Select
                                                // value={empSize}
                                                onChange={(e) => {
                                                    // console.log(e.value)
                                                    setempStatus(e.value);
                                                    setstatusError("")
                                                    // handleSelectOptions_empSize(e.value);
                                                    // console.log(selectedOptions_empSize)
                                                }}
                                                options={optionGroup_empStatus}
                                                classNamePrefix="select2-selection"
                                            /><span className="validate-span text-danger">
                                                {statusError}
                                            </span>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className='my-2'>


                                </Row>

                            </Form>

                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                onClick={() => {
                                    tog_standard_add_auditee();
                                    setmodal_add_auditee(false);
                                }}
                                className="btn btn-secondary "
                                data-dismiss="modal"
                            >
                                Close
                            </button>
                            <button
                                type="button"
                                onClick={() => {
                                    // updatePersonalDetails_sweetAlert()
                                    if (firstName != "" && lastName != "" && empEmail != "" && empPassword != "" && jobTitle != "" && role != "" && empPhone != "" && gender != "" && empStatus != "" && fnameError === "" && lnameError === "" && emailError === "" && phoneError === "" && passwordError === "" && jobTiteError === "" && genderError === "" && statusError === "" && roleError === "") {

                                        addVulnerabilities()
                                        tog_standard_add_auditee();

                                    }
                                    else {

                                        // console.log(firstName, lastName, empEmail, phone, jobTitle, empType, role, gender, empPhone, empStatus, organizationId, AuditorID, empPassword)

                                        // console.log(fnameError, lnameError, emailError, passwordError, jobTiteError, roleError, phoneError, genderError, statusError)
                                        if (firstName === "") {
                                            setfnameError("first name cannot be empty")
                                        }
                                        if (lastName === "") {
                                            setlnameError("first name cannot be empty")
                                        }
                                        if (empEmail === "") {
                                            setempEmail("phone cannot be empty")
                                        }
                                        if (empPhone === "") {
                                            setphoneError("phone cannot be empty")
                                        }
                                        if (jobTitle === "") {
                                            setjobTiteError("please select job Title")
                                        }
                                        if (empStatus === "") {
                                            setstatusError("please select status")
                                        }
                                        if (empPassword === "") {
                                            setempPassword("please select status")
                                        }
                                        if (role === "") {
                                            setroleError("please select role")
                                        }
                                        if (gender === "") {
                                            setgenderError("please select gender")
                                        }
                                        // setfnameError("name must contain 3 characters")
                                        save_user_alert()
                                    }
                                }}
                                className="btn btn-primary "
                            // data-dismiss="modal"
                            >
                                Save
                            </button>
                        </div>
                    </Modal>
                </Row>
            </div>
        </React.Fragment>
    );
};

export default AddUsers;
