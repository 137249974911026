import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useEffect, useState } from "react";

//Import Icons
import FeatherIcon from "feather-icons-react";

import { Row, Col, Alert, Container, Card } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link, useHistory } from "react-router-dom";

// availity-reactstrap-validation
// import logo from "../../assets/images/logo/logoSVG.svg";
import { AvForm, AvField } from "availity-reactstrap-validation";

//Social Media Imports
import { GoogleLogin } from "react-google-login";
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

// actions
import { loginUser, socialLogin } from "../../store/actions";

// import images
// import logo from "../../assets/images/logo-sm.svg";
import logo from "../../assets/images/logo/logo-001-01.png";

//Import config
import { facebook, google } from "../../config";
import CarouselPage from "../AuthenticationInner/CarouselPage";
import { set } from "lodash";
import Axios from "axios";

const Login = (props) => {
    const dispatch = useDispatch();

    const { error } = useSelector((state) => ({
        error: state.Login.error,
    }));

    // handleValidSubmit
    const handleValidSubmit = (event, values) => {
        dispatch(loginUser(values, props.history));
    };

    const signIn = (res, type) => {
        if (type === "google" && res) {
            const postData = {
                name: res.profileObj.name,
                email: res.profileObj.email,
                token: res.tokenObj.access_token,
                idToken: res.tokenId,
            };
            dispatch(socialLogin(postData, props.history, type));
        } else if (type === "facebook" && res) {
            const postData = {
                name: res.name,
                email: res.email,
                token: res.accessToken,
                idToken: res.tokenId,
            };
            dispatch(socialLogin(postData, props.history, type));
        }
    };

    //handleGoogleLoginResponse
    const googleResponse = (response) => {
        signIn(response, "google");
    };

    //handleTwitterLoginResponse
    // const twitterResponse = e => {}

    //handleFacebookLoginResponse
    const facebookResponse = (response) => {
        signIn(response, "facebook");
    };

    const [email, setemail] = useState("");
    const [password, setpassword] = useState("");
    const [invalidStatus, setinvalidStatus] = useState(false);

    const [loginBtnStatus, setloginBtnStatus] = useState(true)
    const [loginErrorMessage, setloginErrorMessage] = useState("")

    let history = useHistory();
    function handleClick() {
        history.push("/login");
    }

    const token = localStorage.getItem("token");
    useEffect(() => {
    }, [])
    async function loginUser(event) {
        // event.preventDefault();
        // console.log("email-passs",email,password)
        // console.log(`${process.env.REACT_APP_DEFAULTPATH}`);
        Axios.defaults.withCredentials = true; // important point 
        Axios.post(`${process.env.REACT_APP_DEFAULTPATH}login`, {
            email,
            password,
        }, {
            credentials: 'same-origin'
        })
            .then(
                (res) => {
                    if (res.data.status) {
                        // setRole(res.data);
                        console.log("data of login", res.data);
                        localStorage.setItem("token", res.data.token);
                        localStorage.setItem("AuditorID", res.data.AuditorID);
                        localStorage.setItem("orgID", res.data.orgID);
                        localStorage.setItem("role", res.data.Role);
                        localStorage.setItem("authUser", false)
                        window.location.reload()
                        // alert("Login successfull ");
                        // window.location.href = "/dashboard";
                        // console.log("user success", res.data)
                    }
                    else {
                        console.log(res)
                        setloginErrorMessage(res.data.message)
                        setinvalidStatus(true);
                    }
                }
            )
        // const response = await fetch(`${process.env.REACT_APP_DEFAULTPATH}login`, {
        //     method: "POST",
        //     headers: {
        //         "Content-Type": "application/json",
        //     },
        //     body: JSON.stringify({
        //         email,
        //         password,
        //     }),
        // });
        // const data = await response.json();
        // if (data.status === "ok") {
        //     console.log("data of login", data);
        //     localStorage.setItem("token", data.token);
        //     localStorage.setItem("AuditorID", data.AuditorID);
        //     localStorage.setItem("orgID", data.orgID);
        //     localStorage.setItem("role", data.Role);
        //     localStorage.setItem("authUser", false)
        //     // alert("Login successfull ");
        //     window.location.href = "/auditor/dashboard";
        // } else {
        //     setinvalidStatus(true);
        //     // alert("please Check ur email pass combo ");
        //     // window.location.href = "/login";
        // }
        // console.log("data",data);
        // console.log("name",name);
    }
    return (
        <React.Fragment>
            <MetaTags>
                <title>Auditor Login | AuditSense</title>
            </MetaTags>



            <div className="auth-page d-flex align-center" style={{ background: "", padding: "50px 0", minHeight: "100vh" }}>
                <Container fluid className="  justify-content-center">
                    <Row className="login_card_row">
                        <Col lg={3} md={6} className="d-flex justify-content-center flex-column ">

                            <div className="mb-5 mb-md-5 text-center auditSense_logo mx-auto">
                                {/* <Link to="" className="d-block auth-logo"> */}
                                <img src={logo} alt="" height={10} width={50} />
                                {/* </Link> */}
                            </div>

                            <Card className="login_card">

                                <div className=" d-flex p-sm-5 p-4 ">


                                    <div className="d-flex flex-column w-100">
                                        <div className="auth-content my-auto">


                                            <div className="text-center">
                                                <h5 className="mb-0">Welcome Auditor</h5>
                                                <p className="text-muted mt-2">
                                                Sign In to Your Account
                                                </p>
                                            </div>
                                            {(() => {
                                                if (invalidStatus) {
                                                    return (
                                                        <Alert
                                                            color="danger"
                                                            className="alert-label-icon label-arrow"
                                                        >
                                                            <i className="mdi mdi-block-helper label-icon"></i>
                                                            <strong>Error</strong> - {loginErrorMessage}
                                                        </Alert>
                                                    );
                                                } else {
                                                    return null;
                                                }
                                            })()}
                                            <AvForm
                                                className="custom-form mt-4 pt-2"
                                                onValidSubmit={loginUser}
                                            >
                                                {error ? <Alert color="danger">{error}</Alert> : null}
                                                <div className="mb-3">
                                                    <AvField
                                                        name="email"
                                                        label="Email"
                                                        value={email}
                                                        className="form-control"
                                                        placeholder="Enter email"
                                                        type="email"
                                                        required
                                                        onChange={(e) => {
                                                            setinvalidStatus(false);
                                                            setemail(e.target.value)
                                                        }}
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <div className="d-flex align-items-start">
                                                        <div className="flex-grow-1">
                                                            <label className="form-label">Password</label>
                                                        </div>
                                                        <div className="flex-shrink-0">
                                                            {/* <div className="">
                                                                <Link
                                                                    to="/forgot-password"
                                                                    className="text-muted"
                                                                >
                                                                    Forgot password?
                                                                </Link>
                                                            </div> */}
                                                        </div>
                                                    </div>

                                                    <div className="mb-3">
                                                        <AvField
                                                            name="password"
                                                            value={password}
                                                            type="password"
                                                            className="form-control"
                                                            required
                                                            placeholder="Enter Password"
                                                            onChange={(e) => {

                                                                setinvalidStatus(false);
                                                                if (email != " " && password != " ") {
                                                                    setloginBtnStatus(false)
                                                                }
                                                                setpassword(e.target.value)
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mb-4">
                                                    <div className="text-primary" style={{}}>
                                                        <Link
                                                            to="/forgot-password"
                                                            className="text-primary"
                                                        >
                                                            Forgot password?
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="mb-3">
                                                    <button
                                                        className="btn btn-primary w-100 waves-effect waves-light"
                                                        type="submit"
                                                        disabled={loginBtnStatus}
                                                    >
                                                        Login
                                                        <FeatherIcon icon="arrow-right" size={18} className="ms-2" />
                                                    </button>
                                                </div>
                                            </AvForm>


                                        </div>
                                    </div>
                                </div>
                            </Card>

                            {/* <div className="mt-4 mt-md-3 text-center">
                                <p className="text-white mb-0 ">
                                    Don't have an account yet {" "}?
                                    <Link
                                        to="/register"
                                        className=" fw-semibold text-white"
                                    >
                                        {" "}
                                        Register{" "}
                                    </Link>{" "}
                                </p>
                            </div> */}
                        </Col>

                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withRouter(Login);

Login.propTypes = {
    history: PropTypes.object,
};
