import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"

import { Switch, BrowserRouter as Router, Route, Redirect } from "react-router-dom"
import { connect } from "react-redux"

// Import Routes all
import { userRoutes, authRoutes, adminRoutes, OtherRoutes, projectManagerRoles, teamLeader, auditor, salesRoutes, financeRoutes, supportRoutes, marketingRoutes } from "./routes/allRoutes"

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

// Import scss
import "./assets/scss/theme.scss"
import "./assets/scss/preloader.scss"

import Axios from 'axios'

const App = props => {

  const token = localStorage.getItem("token");
  const [role, setrole] = useState()
  useEffect( () => {
    // await checkJWTauth();
     checkLoginStatus();
    // const response = await fetch(`${process.env.REACT_APP_DEFAULTPATH}jwtauth`, {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify({
    //     token
    //   }),
    // })
    // const data = await response.json()
    // if (data.status === false) {
    //   // console.log("responsed ", data)
    // }
    // else {
    //   // setstate(true)
    //   // console.log("responsed ", data)
    // }
  }, [])

  // async function checkJWTauth() {
  //   const response = await fetch(`${process.env.REACT_APP_DEFAULTPATH}jwtauth`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({
  //       token
  //     }),
  //   })
  //   const data = await response.json()
  //   if (!data.status) {
  //     window.location.href = "/login";
  //     setrole("test");
  //   }
  // }

  Axios.defaults.withCredentials = true; // important point 
  async function checkLoginStatus() {
    await Axios.post(`${process.env.REACT_APP_DEFAULTPATH}check-login-role`,{
      userCheck: localStorage.getItem("AuditorID"),
      orgID: localStorage.getItem("orgID")
    }, {
      headers : {
        "Authorization": `Bearer ${localStorage.getItem("token")}`
      },
      credentials: 'same-origin'
    }).then(
      (res) => {
        if (res.data.loggedIn) {
          console.log("succeddeded", res.data.user.Role)
          setrole(res.data.user.Role);
          // window.location.href = "/auditor/dashboard";
        }
        else {
          console.log("not logged in ")
        }
      }
    )
  }

  function getLayout() {
    let layoutCls = VerticalLayout
    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout
        break
      default:
        layoutCls = VerticalLayout
        break
    }
    return layoutCls
  }

  const Layout = getLayout()
  return (
    <React.Fragment>


      <Router>
        <Switch>
          {
            (() => {
              if (role === "Admin") {
                return (
                  adminRoutes.map((route, idx) => (
                    <Authmiddleware
                      path={route.path}
                      layout={Layout}
                      component={route.component}
                      key={idx}
                      isAuthProtected={false}
                      exact
                    />
                  ))
                )
              }
              if (role === "Project Manager") {
                return (
                  projectManagerRoles.map((route, idx) => (
                    <Authmiddleware
                      path={route.path}
                      layout={Layout}
                      component={route.component}
                      key={idx}
                      isAuthProtected={false}
                      exact
                    />
                  ))
                )
              }
              if (role === "Team Leader") {
                return (
                  teamLeader.map((route, idx) => (
                    <Authmiddleware
                      path={route.path}
                      layout={Layout}
                      component={route.component}
                      key={idx}
                      isAuthProtected={false}
                      exact
                    />
                  ))
                )
              }
              if (role === "Auditor") {
                return (
                  auditor.map((route, idx) => (
                    <Authmiddleware
                      path={route.path}
                      layout={Layout}
                      component={route.component}
                      key={idx}
                      isAuthProtected={false}
                      exact
                    />
                  ))
                )
              }
              if (role === "Sales") {
                return (
                  salesRoutes.map((route, idx) => (
                    <Authmiddleware
                      path={route.path}
                      layout={Layout}
                      component={route.component}
                      key={idx}
                      isAuthProtected={false}
                      exact
                    />
                  ))
                )
              }
              if (role === "Marketing") {
                return (
                  marketingRoutes.map((route, idx) => (
                    <Authmiddleware
                      path={route.path}
                      layout={Layout}
                      component={route.component}
                      key={idx}
                      isAuthProtected={false}
                      exact
                    />
                  ))
                )
              }
              if (role === "Support") {
                return (
                  supportRoutes.map((route, idx) => (
                    <Authmiddleware
                      path={route.path}
                      layout={Layout}
                      component={route.component}
                      key={idx}
                      isAuthProtected={false}
                      exact
                    />
                  ))
                )
              }
              if (role === "Finance") {
                return (
                  financeRoutes.map((route, idx) => (
                    <Authmiddleware
                      path={route.path}
                      layout={Layout}
                      component={route.component}
                      key={idx}
                      isAuthProtected={false}
                      exact
                    />
                  ))
                )
              }
              else {
                return (
                  OtherRoutes.map((route, idx) => (
                    <Authmiddleware
                      path={route.path}
                      layout={NonAuthLayout}
                      component={route.component}
                      key={idx}
                      isAuthProtected={false}
                      exact
                    />
                  ))
                )

              }

            })()
          }
          {/* {authRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              exact
            />
          ))}

          {userRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={Layout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              exact
            />
          ))} */}
        </Switch>
      </Router>

    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)